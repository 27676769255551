/* eslint-disable react/prop-types */
import React from 'react';
import { Input } from 'antd';

function SearchFilter(props) {
  const {
    setSelectedKeys, selectedKeys, confirm, clearFilters, inputRef, handleSearch,
    handleReset, dataIndex,
  } = props;
  return (
    <div className="p-1">
      <Input
        ref={inputRef}
        placeholder={`Buscar por ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm)}
        className="mb-2 d-block"
      />
      <div className="d-flex justify-content-between">
        <button
          type="button"
          onClick={() => handleSearch(selectedKeys, confirm)}
          className="bg-color-black color-white py-1 px-3 align-self-end pointer border-black "
        >
          <i className="fa fa-search text-1 mr-2" />
          Buscar
        </button>
        <button
          type="button"
          onClick={() => clearFilters && handleReset(clearFilters)}
          className="bg-white color-black py-1 px-3 align-self-end pointer border-gray "
        >
          Redefinir
        </button>
      </div>
    </div>
  );
}

export default SearchFilter;
