/* eslint-disable react/self-closing-comp */
import React from 'react';

function Header() {
  return (
    <>
      <div className="container-fluid shadow-one p-md-5 p-2 footer-w">
        <div className="row">
          <div className="col-md-12 col-sm-12 text-2 text-center">
            {`BR5 Turbo - Copyrigth ${new Date().getFullYear()} - Todos os direitos reservados.`}
            {/* <b> CNPJ: 44.652.354/0001-96</b> */}
          </div>
          {/* <div className="col-md-5  col-sm-12">
            <p className="title-3">Endereço:</p>
            <p className="text-2">
              Av. João Gomes Sobrinho, 299,
              Lixeira, Cuiabá - MT |
              sac@enexsaude.com.br |
              Seg a sex: 08 ás 18:00
            </p>
          </div> */}
        </div>
      </div>
      {/* <div className="container-fluid shadow-one mt-5 p-md-5 p-2">
        <p className="text-center">
          {`Copyrigth ${new Date().getFullYear()} - Todos os direitos reservados -`}
          <b> CNPJ: 44.652.354/0001-96</b>
        </p>
        <div className="row">
          <div className="col-md-8 col-sm-12 text-1">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the leap into
            electronic.
          </div>
          <div className="col-md-3 offset-md-1 col-sm-12">
            <p className="title-3">Endereço:</p>
            <p className="text-2">
              Av. João Gomes Sobrinho, 299,
              <br />
              Lixeira, Cuiabá - MT
              <br />
              sac@enexsaude.com.br
              <br />
              Seg a sex: 08 ás 18:00
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Header;
