import actionsTypes from '../actions/actionsTypes';
import helpers from './helpers';

const INITIAL_STATE = {
  allTopSeguros: {},
  allExpertise: {},
  allBr5: {},
  allBr5Licencas: {},
  dataShare: {},
  // item: {},
  // current: '',
  // my: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.QUOTATION_TOPSEGUROS_LIST:
      return helpers.mapAll(action.payload, state, INITIAL_STATE, 'allTopSeguros');

    case actionsTypes.QUOTATION_EXPERTISE_LIST:
      return helpers.mapAll(action.payload, state, INITIAL_STATE, 'allExpertise');

    case actionsTypes.QUOTATION_BR5_LIST:
      return helpers.mapAll(action.payload, state, INITIAL_STATE, 'allBr5');

    case actionsTypes.QUOTATION_BR5_LICENCAS_LIST:
      return helpers.mapAll(action.payload, state, INITIAL_STATE, 'allBr5Licencas');

    case actionsTypes.QUOTATION_DATA_SHARE:
      return { ...state, dataShare: action.payload };

    default:
      return state;
  }
};

export default reducer;
