/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Spin,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import emailjs from 'emailjs-com';

import actionModal from '../../../actions/actionModal';
import actionErrors from '../../../actions/actionErrors';
import actionPlan from '../../../actions/actionPlan';

function ModalSendEmailRequestWithdraw() {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.reducerModal.modalSendEmailRequestWithdraw);

  const [loading, setLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [message, setMessage] = useState('');

  const populate = async () => {
    try {
      setLoading(true);
      await dispatch(actionPlan.getAll());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  };

  useEffect(() => {
    populate();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setPdfFile(file);
  };

  const handleMessageChange = (event) => {
    const { value } = event.target;
    setMessage(value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const serviceID = 'service_pxhg8xe';
      const templateID = 'template_s93o9rl';
      const userID = 'fCWPkKlq5lN39rhmM';
      const formData = new FormData();
      formData.append('file', pdfFile);
      const emailParams = {
        from_name: 'BR5 Turbo', // Nome do remetente
        to_name: 'Financeiro', // Nome do destinatário
        message,
        content: formData,
      };

      await emailjs.send(serviceID, templateID, emailParams, userID);

      setLoading(false);
      console.log('Email enviado com sucesso!');
      dispatch(actionModal.modalSendEmailRequestWithdraw(false));
    } catch (error) {
      setLoading(false);
      console.log('Erro ao enviar o email:', error);
    }
  };

  return (
    <Modal
      width={window.innerWidth > 960 ? 600 : 'auto'}
      open={modal}
      footer={null}
      onCancel={() => dispatch(actionModal.modalSendEmailRequestWithdraw(false))}
    >
      <Spin spinning={loading}>
        <div className="d-flex flex-column p-md-4 p-0">
          <h5 className="mb-4">Carregue sua nota fiscal para solicitar o saque!</h5>
          <input className="mb-4" type="file" onChange={handleFileChange} />
          <textarea className="mb-4 form-control" id="exampleFormControlTextarea1" rows="3" value={message} onChange={handleMessageChange} />
          <button type="button" style={{ cursor: 'pointer' }} className="btn btn-primary" onClick={handleSubmit}>Enviar</button>
        </div>
      </Spin>
    </Modal>
  );
}

export default ModalSendEmailRequestWithdraw;
