const config = {};

const production = true;
const baseProduction = 'https://api.br5beneficios.com.br';
const baseDev = 'http://localhost:3000';

// config.base = config.production ? config.baseProduction : config.baseDev;
config.base = production ? baseProduction : baseDev;
config.production = production;
config.urlIndicationCheckout = 'https://vendas.br5beneficios.com.br/checkout/full';
config.urlIndicationSite = 'https://vendas.br5beneficios.com.br/sale';
config.checkoutAPI = 'https://checkoutapi.br5turbo.com.br';

config.identifiersProfile = [12, 13, 14];
config.icons = {
  pdf: 'images/icons/pdf.png',
  jpg: 'images/icons/jpg.png',
};

config.typesSellers = {
  pdv: 'Loja',
  vendedorPdv: 'Vendedor',
  bigDealer: 'Empresário',
};

config.empresas = {
  br5: {
    nomeFantasia: 'BR5 Benefícios',
    messageWhatsapp1: 'Assine BR5 Benefícios e garanta sua proteção!',
  },
  br5Licencas: {
    baseUrlIndication: 'https://checkout.br5turbo.com.br',
    // baseUrlIndication: 'http://localhost:3001',
    nomeFantasia: 'BR5 Licenças',
    messageWhatsapp1: 'Acelere sua Renda!! BR5 Turbo!',
  },
  topSeguros: {
    baseUrlIndication: 'https://vendas.br5turbo.com.br',
    // baseUrlIndication: 'http://localhost:3002',
    nomeFantasia: 'Top Seguros',
    messageWhatsapp1: 'Olá! Cote seu seguro na Top Seguros. *Obrigatório envio da apólice ou orçamento de menor custo com todas informações de coberturas e perfil',
    messageWhatsapp2: 'Olá! Simule seu seguro na Top Seguros e tenha o orçamento agora!',
  },
  expertise: {
    nomeFantasia: 'Expertise',
    razaoSocial: 'Expertise Soluções financeiras',
    baseUrlIndication: 'https://vendas.br5turbo.com.br',
    messageWhatsapp1: 'Olá! Faca sua simulação financeira  agora na Expertise!',
    // baseUrlIndication: 'http://localhost:3002',
  },
};

config.pagination = {
  pageSize: 5,
};

module.exports = config;
