/* eslint-disable react/prop-types */
import React from 'react';

function FilterIcon(props) {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      fill="none"
      viewBox="0 0 21 22"
    >
      <path
        fill={color || 'rgba(0, 0, 0, 0.25)'}
        d="M8.49 15.016c0 .207.166.375.373.375h3.075a.373.373 0 00.372-.375v-2.297H8.49v2.297zM14.714 7H6.086a.375.375 0 00-.322.563l2.593 4.406h4.088l2.593-4.406A.376.376 0 0014.714 7z"
      />
    </svg>
  );
}

export default FilterIcon;
