import actionsTypes from './actionsTypes';
import api from '../services/api';
import checkoutAPI from '../services/checkoutApi';

const actions = {
  updatePassword: (id, payload) => ({
    type: actionsTypes.UPDATE_PASSWORD,
    payload: api.put(`/api/v1/usuario/senha/${id}`, payload),
  }),
  updateUser: (id, payload) => ({
    type: actionsTypes.UPDATE_USER,
    payload: api.put(`api/v1/vendedor/br5-infos/update/${id}`, payload),
  }),
  getAddressByZipCode: (zipCode) => ({
    type: actionsTypes.GET_ADDRESS_BY_ZIP_CODE,
    payload: checkoutAPI.get(`/api/address/zipcode/${zipCode}`),
  }),

};

export default actions;
