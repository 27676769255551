import actionsTypes from './actionsTypes';
import api from '../services/api';

const actions = {
  getAll: (params) => ({
    type: actionsTypes.PLAN_GET,
    payload: api.get('/api/v1/plano/lista', { params }),
  }),
};

export default actions;
