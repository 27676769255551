import React from 'react';
import { Provider, useDispatch } from 'react-redux';
import { Router } from 'react-router-dom';

import history from './helpers/history';
import actionsUserLogged from './actions/actionUserLogged';

import { getDataUser, isAuthenticated } from './services/auth';
import Footer from './components/layout/Footer';
import Sidebar from './components/layout/Sidebar';
import Login from './components/login/Login';
import store from './store';
import Routes from './routes';
import ModalValidation from './components/validator/ModalValidation';

const LoggedContainer = () => {
  const dispatch = useDispatch();

  const userData = getDataUser();

  dispatch(actionsUserLogged.set(userData));
  return (
    <div className="page-wrapper chiller-theme toggled">
      <Sidebar />
      <main className="page-content">
        <div className="container-fluid">
          <Routes />
          <Footer />
        </div>
      </main>
      <ModalValidation />
    </div>
  );
};

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>{isAuthenticated() ? <LoggedContainer /> : <Login />}</Router>
    </Provider>
  );
}

export default App;
