import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {
  item: {},
  address: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.UPDATE_PASSWORD:
      return { ...state, item: action.payload };

    case actionsTypes.UPDATE_USER:
      return { ...state, item: action.payload };

    case actionsTypes.GET_ADDRESS_BY_ZIP_CODE:
      return { ...state, address: action.payload };

    default:
      return state;
  }
};

export default reducer;
