import actionsTypes from './actionsTypes';

const actions = {
  errorCreateWorker: (data) => ({
    type: actionsTypes.ERROR_WORKER_CREATE,
    payload: data,
  }),
};

export default actions;
