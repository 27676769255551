import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {
  loadingPlanGetAll: false,
  loadingWorkerGetAll: false,
  loadingWorkerCreate: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.PLAN_GET_LOADING:
      return { ...state, loadingPlanGetAll: action.payload };

    case actionsTypes.WORKER_GET_LOADING:
      return { ...state, loadingWorkerGetAll: action.payload };

    case actionsTypes.WORKER_CREATE_LOADING:
      return { ...state, loadingWorkerCreate: action.payload };

    default:
      return state;
  }
};

export default reducer;
