import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {
  all: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.PRODUCTS_AND_TRAINING_GET_ALL:
      return { ...state, all: action.payload };

    default:
      return state;
  }
};

export default reducer;
