/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { useDispatch } from 'react-redux';

import actionSellers from '../../../actions/actionSellers';

function CheckboxFilter(props) {
  const {
    selectedKeys, confirm, setSelectedKeys, clearFilters, filterOptions,
    filterName, setLoading,
  } = props;

  const [checkedList, setCheckedList] = useState(selectedKeys);
  const dispatch = useDispatch();

  const handleCheckboxChange = (checkedValues) => {
    setSelectedKeys(checkedValues);
    setCheckedList(checkedValues);
  };

  const handleReset = async () => {
    setLoading(true);
    clearFilters();
    setCheckedList([]);
    await dispatch(actionSellers.getAll());
    setLoading(false);
  };

  const handleApplyFilter = async () => {
    setLoading(true);
    confirm();
    await dispatch(actionSellers.getAll({ [filterName]: selectedKeys }));
    setLoading(false);
  };

  return (

    <div className="p-2">
      <Checkbox.Group
        options={filterOptions}
        value={checkedList}
        onChange={handleCheckboxChange}
        className="d-flex flex-column "
      />

      <div className="d-flex justify-content-between mt-2">
        <button
          type="button"
          onClick={() => clearFilters && handleReset(clearFilters)}
          className="bg-white color-black px-1 align-self-end pointer border-0 "
        >
          Redefinir
        </button>
        <button
          type="button"
          onClick={handleApplyFilter}
          className="bg-color-black color-white px-1 align-self-end pointer border-black "
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default CheckboxFilter;
