import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {
  all: {},
  items: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.GET_COMISSIONS:
      return { ...state, all: action.payload.data, items: action.payload.data.items };

    case actionsTypes.GET_COMISSIONS_SPREADSHEET:
      return { ...state };

    default:
      return state;
  }
};

export default reducer;
