import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, message } from 'antd';

import actionSellers from '../../../actions/actionSellers';

import helpers from '../../../helpers/all';
import format from '../../../helpers/format';

import { typesSellers } from '../../../config';

function LicenseInformationIndex() {
  const [isLoading, setIsLoading] = useState(true);

  const currentId = useSelector((state) => state.reducerSellers.current);
  const seller = useSelector((state) => state.reducerSellers.item);
  const dispatch = useDispatch();

  const getSellerData = async () => {
    if (currentId) {
      try {
        setIsLoading(true);
        await dispatch(actionSellers.getById(currentId));
        setIsLoading(false);
      } catch {
        message.error('Houve um problema ao obter os dados do vendedor');
      }
    }
  };
  useEffect(getSellerData, [currentId]);

  const name = helpers.formatName(seller?.nome_razao_social);
  const birthDate = seller?.data_nascimento ? format.formatDate(seller?.data_nascimento) : '--';
  const zipCode = helpers.formatZipcode(seller?.cep);
  const licenseType = typesSellers[seller.tipo_vendedor_licenca];
  const sellerParent = seller?.vendedor_pai ? helpers.formatName(seller?.vendedor_pai) : 'Sem vendedor pai';
  const phone = seller?.telefone && helpers.formatPhone(seller?.telefone[0]);

  return (
    <Spin spinning={isLoading}>
      <div className="bg-white mb-5 pt-5 page-container">
        <Link to="/licencas" className="text-4 py-5 color-black mb-1">
          <i className="fa fa-thin fa-chevron-left mr-3 text-5" />
          Voltar
        </Link>

        <div className="d-flex justify-content-between align-items-center mt-5 mb-4">
          <h1 className="text-4 mb-0">Informações licença</h1>
          <Link className="text-4 color-black" to="/licenca/editar">
            Editar
            <i className=" fa fa-solid fa-pen text-2 ml-2" />
          </Link>
        </div>

        <p className="bg-color-8 p-1 text-bold">Vendedor</p>
        <div className="row">
          <div className=" col">
            <p className="text-bold w-100 mb-2">Nome</p>
            <p className="mb-3">{name}</p>
          </div>
          <div className="col">
            <p className="text-bold w-100 mb-2">Data de nascimento</p>
            <p className="mb-3">{birthDate}</p>
          </div>
        </div>
        <div className="row">
          <div className=" col">
            <p className="text-bold w-100 mb-2">CNPJ/CPF</p>
            <p className="mb-3">{seller?.cpf_cnpj}</p>
          </div>
          <div className="col">
            <p className="text-bold w-100 mb-2">Razão social</p>
            <p className="mb-3">{name}</p>
          </div>
        </div>

        <p className="bg-color-8 p-1 text-bold">Endereço</p>
        <div className="row">
          <div className=" col">
            <p className="text-bold w-100 mb-2">CEP</p>
            <p className="mb-3">{zipCode}</p>
          </div>
          <div className="col">
            <p className="text-bold w-100 mb-2">Rua</p>
            <p className="mb-3">{seller?.rua}</p>
          </div>
        </div>
        <div className="row">
          <div className=" col">
            <p className="text-bold w-100 mb-2">Número</p>
            <p className="mb-3">{seller?.numero || '--'}</p>
          </div>
          <div className=" col">
            <p className="text-bold w-100 mb-2">Complemento</p>
            <p className="mb-3">{seller?.complemento || '--' }</p>
          </div>
          <div className="col">
            <p className="text-bold w-100 mb-2">Bairro</p>
            <p className="mb-3">{seller?.bairro}</p>
          </div>
        </div>
        <div className="row">
          <div className=" col">
            <p className="text-bold w-100 mb-2">Cidade</p>
            <p className="mb-3">{seller?.cidade || '--' }</p>
          </div>
          <div className="col">
            <p className="text-bold w-100 mb-2">Estado</p>
            <p className="mb-3">{seller?.estado || '--' }</p>
          </div>
        </div>

        <p className="bg-color-8 p-1 text-bold">Informações da licença</p>
        <div className="row">
          <div className=" col">
            <p className="text-bold w-100 mb-2">Tipo de licença</p>
            <p className="mb-3">{licenseType}</p>
          </div>
          <div className=" col">
            <p className="text-bold w-100 mb-2">Comissão Adesão</p>
            <p className="mb-3">
              {seller?.percentual_adesao}
              %
            </p>
          </div>
          <div className="col">
            <p className="text-bold w-100 mb-2">Gerar mensalidade na adesão</p>
            <p className="mb-3">------</p>
          </div>
        </div>

        <p className="bg-color-8 p-1 text-bold">Informações do vendedor</p>
        <div className="row">
          <div className=" col">
            <p className="text-bold w-100 mb-2">Tipo vendedor</p>
            <p className="mb-3">{licenseType}</p>
          </div>
          <div className=" col">
            <p className="text-bold w-100 mb-2">Vendedor pai</p>
            <p className="mb-3">{sellerParent}</p>
          </div>
        </div>
        <div className="row">
          <div className=" col">
            <p className="text-bold w-100 mb-2">Comissão adesão</p>
            <p className="mb-3">
              {seller?.percentual_adesao}
              %
            </p>
          </div>
          <div className=" col">
            <p className="text-bold w-100 mb-2">Comissão mensalidade - BR5 benefícios</p>
            <p className="mb-3">
              {seller?.percentual_mensalidade}
              %
            </p>
          </div>
        </div>

        <p className="bg-color-8 p-1 text-bold">Contato</p>
        <div className="row">
          <div className=" col">
            <p className="text-bold w-100 mb-2">Email</p>
            <p className="mb-3">{seller?.email}</p>
          </div>
          <div className=" col">
            <p className="text-bold w-100 mb-2">Telefone</p>
            <p className="mb-3">{phone}</p>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default LicenseInformationIndex;
