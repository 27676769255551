/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';

import actionProductsAndTraining from '../../../actions/actionProductsAndTraining';
import actionErrors from '../../../actions/actionErrors';

function Details() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const allItems = useSelector((state) => state.reducerProductsAndTraining.all);
  const [productSelect, setProductSelect] = useState(allItems[id - 1]);

  function getProductById(idParam) {
    const productPerId = allItems.find((item) => item.id === idParam);
    setProductSelect(productPerId);
  }

  useEffect(() => {
    try {
      setLoading(true);
      dispatch(actionProductsAndTraining.getAll());
      setLoading(false);
    } catch (e) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(e));
    }
  }, []);

  useEffect(() => {
    getProductById(id);
  }, [id, allItems]);

  return (
    <Spin spinning={loading}>
      <div className="shadow-one p-md-5 p-2 mb-3">
        <div className="w-100 d-flex flex-column justify-content-center">
          <div className="text-left mb-5">
            <Link to="/produtos-treinamentos" className="w-100 float-left">
              <i style={{ fontSize: '20px' }} className="fa fa-arrow-left mr-2 color-2" />
              <span className="title-2 color-2">Voltar</span>
            </Link>
          </div>
          <div style={{ height: '100px' }} className="text-center mb-4">
            <img alt="" loading="lazy" src={productSelect?.icon} className="size-icon-3 img-fluid" />
          </div>

          <img className="img-fluid container-banner" alt="" loading="lazy" src={productSelect?.banner} style={{ width: 'auto', height: '100%' }} />
          <div className="d-flex justify-content-center mt-4">
            <p
              className="btn btn-dark"
              type="button"
            >
              Produtos / Treinamentos
            </p>
          </div>
          <div className="mt-5">
            <div style={{ width: '100%' }}>
              {allItems.length > 0 && productSelect?.items.map((item) => (
                <div key={item.id} style={{ gap: '24px', width: '100%' }} className="container-video d-flex shadow p-4 mb-5">

                  <img alt="" src={item.image} className="img-fluid" loading="lazy" style={{ width: '100%', height: 'auto' }} />
                  <div className=" d-flex flex-column">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <div className="d-flex justify-content-end">
                      <a
                        href={item.file}
                        target="_blank"
                        className="btn btn-light border border-dark"
                        rel="noreferrer"

                      >
                        Acessar conteúdo
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default Details;
