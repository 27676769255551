import actionsTypes from './actionsTypes';
import api from '../services/api';

const actions = {
  getAllTopSeguros: (params) => ({
    type: actionsTypes.QUOTATION_TOPSEGUROS_LIST,
    payload: api.get('/api/v1/financeiro/minhas-cotacoes/topSeguros', { params }),
  }),
  getAllExpertise: (params) => ({
    type: actionsTypes.QUOTATION_EXPERTISE_LIST,
    payload: api.get('/api/v1/financeiro/minhas-cotacoes/expertise', { params }),
  }),
  getAllBr5: (params) => ({
    type: actionsTypes.QUOTATION_BR5_LIST,
    payload: api.get('/api/v1/cliente', { params }),
  }),
  getAllBr5Licencas: (params) => ({
    type: actionsTypes.QUOTATION_BR5_LICENCAS_LIST,
    payload: api.get('/api/v1/vendedor', { params }),
  }),
  dataShare: (objData) => ({
    type: actionsTypes.QUOTATION_DATA_SHARE,
    payload: objData,
  }),
};

export default actions;
