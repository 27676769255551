import { createStore, applyMiddleware, compose } from 'redux';

import ReduxPromise from 'redux-promise';

import reducers from './reducers';

// eslint-disable-next-line operator-linebreak
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(ReduxPromise));
const store = createStore(reducers, enhancer);

export default store;
