import React, { useState, useEffect } from 'react';
import {
  Modal,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import actionQuotation from '../../../actions/actionQuotation';
import actionModal from '../../../actions/actionModal';
import { empresas } from '../../../config';
// import { urlIndicationCheckout, urlIndicationSite } from '../../../config';

function ModalTopSegurosQuotation() {
  const dispatch = useDispatch();

  const company = 'topseguros';
  const { topSeguros } = empresas;
  const modal = useSelector((state) => state.reducerModal.modalTopSegurosQuotation);
  const all = useSelector((state) => state.reducerPlan.all);
  const userData = useSelector((state) => state.reducerUserLogged);
  const [urls] = useState({
    robocote: `${topSeguros.baseUrlIndication}/${userData.pessoa}/${company}/cotar-seguros/opened`,
    cotacao: `${topSeguros.baseUrlIndication}/${userData.pessoa}/${company}/cotar-seguros`,
  });

  useEffect(() => {
    //
  }, []);

  useEffect(() => {
    if (!all || !all.items || !all.items.length) {
      //
    }
    // setUrls({
    //   robocote: `${topSeguros.baseUrlIndication}/${userData.pessoa}/${company}/cotar-seguros`,
    //   cotacao: `${urlIndicationSite}/${userData.pessoa}?plan=${all.items[0].identificador}`,
    // });
  }, [all.items]);

  /**
   * Renovacoes
   * Dados do cliente
   * nome cliente
   * telefone cliente
   * email cliente - opcional
   * histórico
   * anexar apólice
   */

  return (
    <Modal
      width={window.innerWidth > 960 ? 800 : 'auto'}
      visible={modal}
      footer={null}
      onCancel={() => dispatch(actionModal.modalTopSegurosQuotation(false))}
    >
      <div className="p-md-4 p-0">
        <div className="col-md-12 col-sm-12">
          <div className="box-title text-left mb-4">
            <i className="mr-2 fa fa-plus color-top-1" />
            <span className="title-2 color-2">Top Seguros</span>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 mb-5 yr text-center">
              <span className="title-3 text-bold mb-4 d-block">Seguros Novos - Cotação imediata</span>
              <div className="btn-group pb-3" role="group" aria-label="Basic example">
                <a href={urls.robocote} target="_blank" className="btn btn-danger yr" rel="noreferrer">
                  <i className="fas fa-external-link-alt mr-2" />
                  Cotar Agora
                </a>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(actionQuotation.dataShare({
                      url: urls.robocote,
                      text: topSeguros.messageWhatsapp2,
                    }));
                    dispatch(actionModal.modalShareSocial(true));
                  }}
                >
                  <i className="fas fa-share mr-2" />
                  Compartilhar
                </button>
              </div>
            </div>
            <div className="col-md-6 col-12 mb-5 yr text-center">
              <span className="title-3 text-bold d-block">Renovações - Anexar apólice</span>
              <span className="d-block bg-success radius-1 mb-1 text-white text-1">Desconto de até 40% para Renovações</span>
              <div className="btn-group pb-3" role="group" aria-label="Basic example">
                <a href={urls.cotacao} target="_blank" className="btn btn-danger yr" rel="noreferrer">
                  <i className="fas fa-external-link-alt mr-2" />
                  Cotar Agora
                </a>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(actionQuotation.dataShare({
                      url: urls.cotacao,
                      text: topSeguros.messageWhatsapp1,
                    }));
                    dispatch(actionModal.modalShareSocial(true));
                  }}
                >
                  <i className="fas fa-share mr-2" />
                  Compartilhar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <h2 className="title-3 color-2 mb-3 d-block mt-1">O que você pode cotar na Top Seguros?</h2>
          <div className="row">

            <div className="col-md-12 col-xs-12 mb-3">
              <div className="card">
                <h5 className="card-header text-4 text-bold bg-color-top-1 text-white">Produtos:</h5>
                <div className="card-body">
                  <h5 className="card-title box-title text-3 text-bold">
                    Seguro Residencial
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Seguro Condomínio
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Seguro Frota Empresarial
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Seguro Obras e Reformas
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Seguro Garantia Contratual
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Seguro para Barcos, lancha e Jet ski
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Seguro Transporte de Carga
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Seguro Responsabilidade Civil Profissional
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Seguro Equipamentos Agricolas
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Seguro Bike
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Outros
                  </h5>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalTopSegurosQuotation;
