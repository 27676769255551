import React from 'react';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import WhatsappButton from '../../WhatsappButton';

function DashboardResale() {
  const userData = useSelector((state) => state.reducerUserLogged);
  return (
    <>
      <div className="shadow-one p-md-5 p-2 mb-3">
        <div className="col-md-12 col-sm-12">
          <div className="box-title text-left mb-3">
            <i className="mr-2 fa fa-tachometer-alt color-1" />
            <span className="title-2 color-2">Painel de Controle</span>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <span className="title-3 color-4 d-block mb-5">BR5 Licenças</span>
              <span className="text-2 color-4 d-block">Seja bem vindo ao seu painel de controle</span>
              <span className="text-2 color-4 d-block">{ userData.nome }</span>
            </div>
            <div className="col-md-4 col-sm-12">
              <span className="title-3 color-4 d-block">Suporte</span>
              <span className="text-2 color-4 d-block">suporte@br5beneficios.com.br</span>
              <span className="title-3 color-4 d-block">SAC - 0800 006 8402</span>
              <span className="text-2 color-4 d-block">
                Atendimento de segunda a sexta
                {' '}
                <br />
                {' '}
                1das 08:00 às 18:00
              </span>
            </div>
            <div className="col-md-3 col-sm-12">
              <WhatsappButton />
            </div>
            <div className="col-12 mt-3">
              <Carousel>
                <div>
                  <img src="images/banners/slide-1.jpeg" alt="" />
                </div>
                <div>
                  <img src="images/banners/slide-2.jpeg" alt="" />
                </div>
                <div>
                  <img src="images/banners/slide-3.jpeg" alt="" />
                </div>
                <div>
                  <img src="images/banners/slide-4.jpeg" alt="" />
                </div>
                <div>
                  <img src="images/banners/slide-5.jpeg" alt="" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default DashboardResale;
