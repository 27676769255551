import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import actionModal from '../../actions/actionModal';

function FormPersonalData() {
  const dispatch = useDispatch();

  const modalValidation = useSelector((state) => state.reducerModal.modalValidation);
  const errorsList = useSelector((state) => state.reducerError.createWorker);

  const handleCancel = () => {
    dispatch(actionModal.modalValidation(false));
  };

  const linkCheckout = <a href="https://checkout.br5turbo.com.br/"> aqui</a>;

  const handleError = (err) => {
    if (!err) return ['Sem erros para mostrar'];
    let result = [];
    if (err && err.list) {
      result = err.list;
    } else if (Array.isArray(err) && err.length) {
      result = err;
    } else if (err instanceof Error || err.message) {
      // result = [err];
      if (err.message === 'Por favor, assine o contrato antes de prosseguir') {
        result = ['Por favor, assine o contrato antes de prosseguir'];
      } else if (
        err.message === 'A senha deve conter pelo menos 6 dígitos, sendo 1 letra, 1 número e 1 caractere especial'
      ) {
        result = [
          'A senha deve conter pelo menos 6 dígitos, sendo 1 letra, 1 número e 1 caractere especial',
        ];
      } else if (err.message === 'Código incorreto, por favor tente novamente') {
        result = ['Código incorreto, por favor tente novamente'];
      } else if (err.message === 'Pessoa não encontrada') {
        const messageLink = (
          <span>
            O usuário não tem cadastro, para criar clique
            {linkCheckout}
          </span>
        );
        result = [messageLink];
      } else {
        // Caso seja uma outra mensagem de erro não prevista
        result = [err];
      }
      // eslint-disable-next-line no-console
      console.log('result', result);
    } else {
      result = [err];
    }

    return result;
  };

  return (
    <Modal
      width={window.innerWidth > 960 ? 670 : 'auto'}
      wrapClassName="container"
      title=""
      visible={modalValidation}
      onOk={handleCancel}
      onCancel={handleCancel}
    >
      <div className="text-center">
        <h3 className="title mb-5 mt-3">
          <i className="fas fa-exclamation-triangle mr-2 color-3" />
          Foram encontrados alguns erros:
        </h3>
        <div className="alert alert-info br-5 rounded mt-3">
          <span className="description">
            <i className="fas fa-info-circle mr-2" />
            Por favor, corrija os erros abaixo.
          </span>
        </div>
        {
          errorsList
          && handleError(errorsList).map((error) => (
            <p className="text-3">
              <i className="fas fa-info-circle mr-2 color-1" />
              <b>{error}</b>
            </p>
          ))
        }
      </div>
    </Modal>
  );
}

export default FormPersonalData;
