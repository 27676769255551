import React from 'react';
import { useSelector } from 'react-redux';
import dashboards from '../dashboards';
// import { identifiersProfile } from '../../config';

function Main() {
  //
  const userData = useSelector((state) => state.reducerUserLogged);

  // const profileResale = userData.identificadores
  //   .find((id) => identifiersProfile.includes(Number(id)));

  const Dashboard = dashboards.getDashboard(userData.identificador);

  return <Dashboard />;
}

export default Main;
