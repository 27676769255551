import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {
  modalWorkerEdit: false,
  modalWorkerCreate: false,
  modalValidation: false,
  modalPaymentInvoice: false,
  ModalConsultationCreate: false,
  modalConsultationDetails: false,
  modalProfileCreate: false,
  modalProfileEdit: false,
  modalFirstAccess: false,
  modalConcludeAttendance: false,
  modalSchedulingManage: false,
  modalBr5ChoosePlan: false,
  modalBr5LicencasChoosePlan: false,
  modalExpertiseQuotation: false,
  modalTopSegurosQuotation: false,
  modalTopSegurosListQuotations: false,
  modalExpertiseListQuotations: false,
  modalBr5ListQuotations: false,
  modalBr5LicencasListQuotations: false,
  modalRequestWithdraw: false,
  modalSendEmailRequestWithdraw: false,
  modalBr5UseProfile: false,
  modalShareSocial: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.MODAL_WORKER_EDIT:
      return { ...state, modalWorkerEdit: action.payload };

    case actionsTypes.MODAL_WORKER_CREATE:
      return { ...state, modalWorkerCreate: action.payload };

    case actionsTypes.MODAL_VALIDATION:
      return { ...state, modalValidation: action.payload };

    case actionsTypes.MODAL_PAYMENT_INVOICE:
      return { ...state, modalPaymentInvoice: action.payload };

    case actionsTypes.MODAL_CONSULTATION_CREATE:
      return { ...state, modalConsultationCreate: action.payload };

    case actionsTypes.MODAL_CONSULTATION_DETAILS:
      return { ...state, modalConsultationDetails: action.payload };

    case actionsTypes.MODAL_PROFILE_CREATE:
      return { ...state, modalProfileCreate: action.payload };

    case actionsTypes.MODAL_PROFILE_EDIT:
      return { ...state, modalProfileEdit: action.payload };

    case actionsTypes.MODAL_FIRST_ACCESS:
      return { ...state, modalFirstAccess: action.payload };

    case actionsTypes.MODAL_CONSULTATION_CONCLUDE_ATTENDANCE:
      return { ...state, modalConcludeAttendance: action.payload };

    case actionsTypes.MODAL_SCHEDULING_MANAGE:
      return { ...state, modalSchedulingManage: action.payload };

    case actionsTypes.MODAL_BR5_CHOOSE_PLAN:
      return { ...state, modalBr5ChoosePlan: action.payload };

    case actionsTypes.MODAL_SEND_EMAIL_REQUEST_WITHDRAW:
      return { ...state, modalSendEmailRequestWithdraw: action.payload };

    case actionsTypes.MODAL_BR5_LICENCAS_CHOOSE_PLAN:
      return { ...state, modalBr5LicencasChoosePlan: action.payload };

    case actionsTypes.MODAL_EXPERTISE_QUOTATION:
      return { ...state, modalExpertiseQuotation: action.payload };

    case actionsTypes.MODAL_EXPERTISE_LIST_QUOTATIONS:
      return { ...state, modalExpertiseListQuotations: action.payload };

    case actionsTypes.MODAL_TOPSEGUROS_QUOTATION:
      return { ...state, modalTopSegurosQuotation: action.payload };

    case actionsTypes.MODAL_TOPSEGUROS_LIST_QUOTATIONS:
      return { ...state, modalTopSegurosListQuotations: action.payload };

    case actionsTypes.MODAL_BR5_LIST_QUOTATIONS:
      return { ...state, modalBr5ListQuotations: action.payload };

    case actionsTypes.MODAL_BR5_LICENCAS_LIST_QUOTATIONS:
      return { ...state, modalBr5LicencasListQuotations: action.payload };

    case actionsTypes.MODAL_USER_PROFILE:
      return { ...state, modalBr5UseProfile: action.payload };

    case actionsTypes.MODAL_SHARE_SOCIAL:
      return { ...state, modalShareSocial: action.payload };

    default:
      return state;
  }
};

export default reducer;
