import { combineReducers } from 'redux';

import reducerUserLogged from './reducerUserLogged';
import reducerPlan from './reducerPlan';
import reducerLoading from './reducerLoading';
import reducerModal from './reducerModal';
import reducerError from './reducerError';
import reducerInvoice from './reducerInvoice';
import reducerCreditCard from './reducerCreditCard';
import reducerFirstAccess from './reducerFirstAccess';
import reducerDashboard from './reducerDashboard';
import reducerProductsAndTraining from './reducerProductsAndTraining';
import reducerMarketing from './reducerMarketing';
import reducerSellers from './reducerSellers';
import reducerFinancial from './reducerFinancial';
import reducerQuotation from './reducerQuotation';
import reducerWithdraw from './reducerWithdraw';
import reducerProfileSettings from './reducerProfileSettings';
import reducerComissions from './reducerComissions';

const reducers = combineReducers({
  reducerUserLogged,
  reducerLoading,
  reducerPlan,
  reducerModal,
  reducerError,
  reducerInvoice,
  reducerCreditCard,
  reducerFirstAccess,
  reducerDashboard,
  reducerProductsAndTraining,
  reducerMarketing,
  reducerSellers,
  reducerFinancial,
  reducerQuotation,
  reducerWithdraw,
  reducerProfileSettings,
  reducerComissions,
});

export default reducers;
