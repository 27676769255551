/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Table, Pagination, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import formatters from '../../helpers/format';
import helpers from '../../helpers/all';
import Form from './components/Form';
import actions from '../../actions/actionComission';

const columns = [
  {
    title: 'Nome',
    dataIndex: 'clienteNome',
    key: 'name',
    style: { backgroundColor: 'red', fontWeight: 'bold' },
    render: (value) => (
      <span>
        {helpers.formatName(value)}
      </span>
    ),
  },
  {
    title: 'Documento',
    dataIndex: 'clienteDoc',
    key: 'document',

  },
  {
    title: 'Plano',
    dataIndex: 'plano',
    key: 'plan',
    render: (value) => (
      <span>
        {helpers.formatName(value)}
      </span>
    ),
  },
  {
    title: 'Valor',
    dataIndex: 'valor',
    key: 'value',
    render: (value) => {
      const formattedValue = value / 100;
      return (
        <span>
          { `R$ ${formattedValue.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}
        </span>
      );
    },
  },
  {
    title: 'Data da competência',
    dataIndex: 'dataCompetencia',
    key: 'dueDate',
    render: (value) => (
      <span>
        {formatters.formatDate(value)}
      </span>
    ),
  },
  {
    title: 'Data da conciliação',
    dataIndex: 'dataConciliacao',
    key: 'paymentDate',
    render: (value) => (
      <span>
        {formatters.formatDate(value)}
      </span>
    ),
  },

];

function ComissionReportIndex() {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    categoriaEmpresa: null,
    inicio: '',
    fim: '',
    itemsPerPage: 10,
  });

  const dispatch = useDispatch();
  const data = useSelector((state) => state.reducerComissions);

  const onChangePage = async (pageClicked) => {
    setIsLoading(true);
    const inicio = helpers.formatToUniversalDate(form.inicio._d);
    const fim = helpers.formatToUniversalDate(form.fim._d);

    try {
      await dispatch(actions.getComissions({
        ...form, inicio, fim, page: pageClicked,
      }));

      if (!form.categoriaEmpresa) {
        delete form.categoriaEmpresa;
      }
    } catch (err) {
      message.error(err.message || 'Erro ao buscar dados!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadSpreadSheet = async () => {
    const inicio = helpers.formatToUniversalDate(form.inicio._d);
    const fim = helpers.formatToUniversalDate(form.fim._d);
    setIsLoading(true);

    try {
      const response = await dispatch(actions.getComissionsSpreadsheet({ inicio, fim }));

      const url = window.URL.createObjectURL(new Blob([response.payload.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `planilha-comissoes-${moment().format('DD-MM-YYYY-HH:mm:ss')}.xlsx`);
      document.body.appendChild(link);

      link.click();
    } catch (err) {
      message.error(err.message || 'Erro ao gerar planilha!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white md-5 mb-md-2 page-container min-h-80">
      <h1 className="title-settings py-4">Relatório de Comissão</h1>
      <Form form={form} setForm={setForm} setIsLoading={setIsLoading} />
      <div className="w-100 p-2 shadow-three mt-4">
        {!!data.items.length && (
          <button type="button" onClick={handleDownloadSpreadSheet} className="bg-white p-2 border-black color-black rounded mb-2">
            <i className="fa fa-file-download mr-2 text-4" />
            Baixar planilha
          </button>
        )}
        <Table
          dataSource={data.items}
          columns={columns}
          locale={{ emptyText: 'Nenhum dado encontrado' }}
          pagination={false}
          loading={isLoading}
          scroll={{ x: true }}
          size="middle"
        />
        <Pagination
          className="mt-2"
          defaultCurrent={1}
          defaultPageSize={10}
          total={data.all.pages}
          onChange={onChangePage}
          hideOnSinglePage
        />
      </div>
    </div>
  );
}

export default ComissionReportIndex;
