const actions = {

  /** ===========================
  * USER LOGGED
  =========================== */
  USER_DATA_SET: 'USER_DATA_SET',
  USER_DATA_GET: 'USER_DATA_GET',
  USER_PROFILE: 'USER_PROFILE',
  GET_USER_CONTRACTS: 'GET_USER_CONTRACTS',

  /** ===========================
  * PLAN
  =========================== */
  PLAN_GET: 'PLAN_GET',
  PLAN_GET_MY_SUBSCRIPTIONS: 'PLAN_GET_MY_SUBSCRIPTIONS',
  PLAN_GET_BY_ID: 'PLAN_GET_BY_ID',
  PLAN_GET_CURRENT: 'PLAN_GET_CURRENT',
  PLAN_SET_CURRENT: 'PLAN_SET_CURRENT',

  /** ===========================
  * CONSULTATION
  =========================== */
  CONSULTATION_GET: 'CONSULTATION_GET',
  CONSULTATION_LOADING_GET: 'CONSULTATION_LOADING_GET',
  CONSULTATION_GET_BY_ID: 'CONSULTATION_GET_BY_ID',
  CONSULTATION_GET_CURRENT: 'CONSULTATION_GET_CURRENT',
  CONSULTATION_CREATE: 'CONSULTATION_CREATE',
  CONSULTATION_SET_CURRENT: 'CONSULTATION_SET_CURRENT',
  CONSULTATION_AVAILABLE_TIMES: 'CONSULTATION_AVAILABLE_TIMES',
  CONSULTATION_RESET_AVAILABLE_TIMES: 'CONSULTATION_RESET_AVAILABLE_TIMES',
  CONSULTATION_CANCEL: 'CONSULTATION_CANCEL',
  CONSULTATION_CONCLUDE_ATTENDANCE: 'CONSULTATION_CONCLUDE_ATTENDANCE',
  CONSULTATION_SET_STEP: 'CONSULTATION_SET_STEP',
  CONSULTATION_GET_STEP: 'CONSULTATION_GET_STEP',
  CONSULTATION_FORM_CREATE: 'CONSULTATION_FORM_CREATE',
  CONSULTATION_FORM_FILTER: 'CONSULTATION_FORM_FILTER',
  CONSULTATION_FORM_CONCLUDE: 'CONSULTATION_FORM_CONCLUDE',
  CONSULTATION_EDITING: 'CONSULTATION_EDITING',
  CONSULTATION_EDIT: 'CONSULTATION_EDIT',

  /** ===========================
   * FIRST ACCESS
   =========================== */
  FIRST_ACCESS_SET_STEP: 'FIRST_ACCESS_SET_STEP',
  FIRST_ACCESS_GET_STEP: 'FIRST_ACCESS_GET_STEP',
  FIRST_ACCESS_FORM: 'FIRST_ACCESS_FORM',
  FIRST_ACCESS_STEP_1: 'FIRST_ACCESS_STEP_1',
  FIRST_ACCESS_STEP_2: 'FIRST_ACCESS_STEP_2',
  FIRST_ACCESS_STEP_3: 'FIRST_ACCESS_STEP_3',
  FIRST_ACCESS_STEP_4: 'FIRST_ACCESS_STEP_4',
  SET_CONTRACT_URL: 'SET_CONTRACT_URL',
  GET_CONTRACT_BY_ID: 'GET_CONTRACT_BY_ID',

  /** ===========================
  * INVOICE
  =========================== */
  INVOICE_GET: 'INVOICE_GET',
  INVOICE_GET_BY_ID: 'INVOICE_GET_BY_ID',
  INVOICE_GET_CURRENT: 'INVOICE_GET_CURRENT',
  INVOICE_SET_CURRENT: 'INVOICE_SET_CURRENT',
  INVOICE_PAYMENT_CREDIT_CARD: 'INVOICE_PAYMENT_CREDIT_CARD',

  /** ===========================
  * PROFILE
  =========================== */
  PROFILE_GET: 'PROFILE_GET',
  PROFILE_GET_BY_ID: 'PROFILE_GET_BY_ID',
  PROFILE_GET_CURRENT: 'PROFILE_GET_CURRENT',
  PROFILE_SET_CURRENT: 'PROFILE_SET_CURRENT',
  PROFILE_CREATE: 'PROFILE_CREATE',
  PROFILE_EDIT: 'PROFILE_EDIT',

  /** ===========================
  * PERMISSION
  =========================== */
  PERMISSION_GET: 'PERMISSION_GET',

  /** ===========================
  * CREDIT_CARD
  =========================== */
  CREDIT_CARD_GET: 'CREDIT_CARD_GET',
  CREDIT_CARD_CREATE: 'CREDIT_CARD_CREATE',
  CREDIT_CARD_CREATE_CUSTOMER: 'CREDIT_CARD_CREATE_CUSTOMER',
  CREDIT_CARD_SET_SELECTED: 'CREDIT_CARD_SET_SELECTED',
  CREDIT_CARD_GET_SELECTED: 'CREDIT_CARD_GET_SELECTED',

  /** ===========================
  * LOADING
  =========================== */
  PLAN_GET_LOADING: 'PLAN_GET_LOADING',
  WORKER_GET_LOADING: 'WORKER_GET_LOADING',
  WORKER_CREATE_LOADING: 'WORKER_CREATE_LOADING',

  /** ===========================
  * WORKER
  =========================== */
  WORKER_GET: 'WORKER_GET',
  WORKER_GET_BY_ID: 'WORKER_GET_BY_ID',
  WORKER_REMOVE: 'WORKER_REMOVE',
  WORKER_CREATE: 'WORKER_CREATE',
  /** ===========================
  * MODALS
  =========================== */
  MODAL_VALIDATION: 'MODAL_VALIDATION',
  MODAL_WORKER_EDIT: 'MODAL_WORKER_EDIT',
  MODAL_WORKER_CREATE: 'MODAL_WORKER_CREATE',
  MODAL_PAYMENT_INVOICE: 'MODAL_PAYMENT_INVOICE',
  MODAL_CONSULTATION_CREATE: 'MODAL_CONSULTATION_CREATE',
  MODAL_CONSULTATION_DETAILS: 'MODAL_CONSULTATION_DETAILS',
  MODAL_CONSULTATION_CONCLUDE_ATTENDANCE: 'MODAL_CONSULTATION_CONCLUDE_ATTENDANCE',
  MODAL_PROFILE_CREATE: 'MODAL_PROFILE_CREATE',
  MODAL_PROFILE_EDIT: 'MODAL_PROFILE_EDIT',
  MODAL_FIRST_ACCESS: 'MODAL_FIRST_ACCESS',
  MODAL_SCHEDULING_MANAGE: 'MODAL_SCHEDULING_MANAGE',

  //
  //
  //
  //
  //
  MODAL_BR5_CHOOSE_PLAN: 'MODAL_BR5_CHOOSE_PLAN',
  MODAL_BR5_LICENCAS_CHOOSE_PLAN: 'MODAL_BR5_LICENCAS_CHOOSE_PLAN',
  MODAL_EXPERTISE_QUOTATION: 'MODAL_EXPERTISE_QUOTATION',
  MODAL_TOPSEGUROS_QUOTATION: 'MODAL_TOPSEGUROS_QUOTATION',
  MODAL_TOPSEGUROS_LIST_QUOTATIONS: 'MODAL_TOPSEGUROS_LIST_QUOTATIONS',
  MODAL_EXPERTISE_LIST_QUOTATIONS: 'MODAL_EXPERTISE_LIST_QUOTATIONS',
  MODAL_BR5_LIST_QUOTATIONS: 'MODAL_BR5_LIST_QUOTATIONS',
  MODAL_BR5_LICENCAS_LIST_QUOTATIONS: 'MODAL_BR5_LICENCAS_LIST_QUOTATIONS',
  MODAL_REQUEST_WITHDRAW: 'MODAL_REQUEST_WITHDRAW',
  MODAL_SEND_EMAIL_REQUEST_WITHDRAW: 'MODAL_SEND_EMAIL_REQUEST_WITHDRAW',
  MODAL_USER_PROFILE: 'MODAL_USER_PROFILE',
  MODAL_SHARE_SOCIAL: 'MODAL_SHARE_SOCIAL',
  /** ===========================
  * ERRORS
  =========================== */
  ERROR_WORKER_CREATE: 'ERROR_WORKER_CREATE',

  /** ===========================
  * SHOW/HIDDEN
  =========================== */
  CREDIT_CARD_SHOW_LIST: 'CREDIT_CARD_SHOW_LIST',
  CREDIT_CARD_SHOW_CREATE: 'CREDIT_CARD_SHOW_CREATE',
  CREDIT_CARD_SHOW_ACTIONS: 'CREDIT_CARD_SHOW_ACTIONS',

  /** ===========================
  * MEDIC
  =========================== */
  MEDIC_GET: 'MEDIC_GET',
  MEDIC_GET_BY_ID: 'MEDIC_GET_BY_ID',

  /** ===========================
  * PEOPLE
  =========================== */
  PEOPLE_PHYSICAL_GET_ALL: 'PEOPLE_PHYSICAL_GET_ALL',
  PEOPLE_PHYSICAL_GET_BY_ID: 'PEOPLE_PHYSICAL_GET_BY_ID',

  /** ===========================
  * ATTACHMENTS
  =========================== */
  FORM_ATTACHMENTS: 'FORM_ATTACHMENTS',
  /** ===========================
  * SCHEDULING
  =========================== */
  SCHEDULING_GET_ALL: 'SCHEDULING_GET_ALL',
  SCHEDULING_CREATE: 'SCHEDULING_CREATE',
  SCHEDULING_FORM_MANAGER: 'SCHEDULING_FORM_MANAGER',
  SCHEDULING_GET_TIMES: 'SCHEDULING_GET_TIMES',
  SCHEDULING_SET_MEDIC_CURRENT: 'SCHEDULING_SET_MEDIC_CURRENT',
  /** ===========================
  * DASHBOARD
  =========================== */
  TOTAL_LIFES_PLAN: 'TOTAL_LIFES_PLAN',
  LATEST_INVOICE: 'LATEST_INVOICE',
  WORKER_INFO: 'WORKER_INFO',

  /** ===========================
  * SELLERS
  =========================== */
  SELLER_GET_ALL: 'SELLER_GET_ALL',
  SELLER_GET_ALL_PLANS: 'SELLER_GET_ALL_PLANS',
  SELLER_GET_BY_ID: 'SELLER_GET_BY_ID',
  GET_CURRENT_SELLER: 'GET_CURRENT_SELLER',
  SET_CURRENT_SELLER: 'SET_CURRENT_SELLER',

  /** ===========================
  * PROFILE SETTINGS
  =========================== */
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  UPDATE_USER: 'UPDATE_USER',
  GET_ADDRESS_BY_ZIP_CODE: 'GET_ADDRESS_BY_ZIP_CODE',

  /** ===========================
  * COMISSIONS REPORT
  =========================== */
  GET_COMISSIONS: 'GET_COMISSIONS',
  GET_COMISSIONS_SPREADSHEET: 'GET_COMISSIONS_SPREADSHEET',
  //

  //

  //

  //

  //
  PRODUCTS_AND_TRAINING_GET_ALL: 'PRODUCTS_AND_TRAINING_GET_ALL',
  MARKETING_GET_ALL: 'MARKETING_GET_ALL',
  FINANCIAL_DASHBOARD_DETAILS: 'FINANCIAL_DASHBOARD_DETAILS',
  QUOTATION_TOPSEGUROS_LIST: 'QUOTATION_TOPSEGUROS_LIST',
  QUOTATION_EXPERTISE_LIST: 'QUOTATION_EXPERTISE_LIST',
  QUOTATION_BR5_LIST: 'QUOTATION_BR5_LIST',
  QUOTATION_BR5_LICENCAS_LIST: 'QUOTATION_BR5_LICENCAS_LIST',
  QUOTATION_DATA_SHARE: 'QUOTATION_DATA_SHARE',
  DOWNLOAD_SHEET_BR5: 'DOWNLOAD_SHEET_BR5',

  WITHDRAW_GET_ALL: 'WITHDRAW_GET_ALL',
  WITHDRAW_GET_BALANCE: 'WITHDRAW_GET_BALANCE',
  WITHDRAW_REQUEST: 'WITHDRAW_REQUEST',
};

export default actions;
