import actionsTypes from '../actions/actionsTypes';
import helpers from './helpers';

const INITIAL_STATE = {
  all: {},
  item: {},
  request: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.WITHDRAW_GET_ALL:
      return helpers.mapAll(action.payload, state, INITIAL_STATE);

    case actionsTypes.WITHDRAW_GET_BALANCE:
      return helpers.mapItem(action.payload, state, INITIAL_STATE);

    case actionsTypes.WITHDRAW_REQUEST:
      return { ...state, request: action.payload?.data };

    default:
      return state;
  }
};

export default reducer;
