import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {
  createWorker: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.ERROR_WORKER_CREATE:
      return { ...state, createWorker: action.payload };

    default:
      return state;
  }
};

export default reducer;
