import {
  Modal,
  message,
} from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import actionModal from '../../../actions/actionModal';

moment.locale('pt-br');

function ModalShareSocial() {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.reducerModal.modalShareSocial);
  const dataShare = useSelector((state) => state.reducerQuotation.dataShare);

  // const baseWhatsapp = 'https://wa.me/55XXXXXXXXXXX?text=';
  const baseWhatsapp = 'https://wa.me/';
  const baseEmail = 'mailto:';

  useEffect(() => {
    // populate();
  }, []);

  // useEffect(() => {
  //   if (!all || !all.items || !all.items.length) {
  //     return;
  //   }

  //   setLoading(false);
  // }, [all.items]);

  const encodedUrl = encodeURIComponent(dataShare.url);
  return (
    <Modal
      // width="auto"
      visible={modal}
      footer={null}
      onCancel={() => dispatch(actionModal.modalShareSocial(false))}
      zIndex={1050}
    >
      <div className="p-md-4 p-0">
        <div className="col-md-12 col-sm-12">
          <div className="box-title text-left mb-4">
            <i className="mr-2 fa fa-plus color-br5-1" />
            <span className="title-2 color-2">Compartilhar</span>
            {/* <span className="text-2 color-4 d-block">
              Lista das cotações de produtos.
              {' '}
              <b>
                Total de registros
                {' '}
              </b>
            </span> */}
          </div>

          <div className="inline-block text-center">
            <a
              href={`${baseWhatsapp}?text=${dataShare.text}. ${encodedUrl}`}
              target="_blank"
              className="btn btn-success mr-2"
              rel="noreferrer"
            >
              <i className="fab fa-whatsapp mr-2" />
              Whatsapp
            </a>
            <a
              href={`${baseEmail}?&body=${encodedUrl}`}
              className="btn btn-primary mr-2"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-envelope mr-2" />
              Email
            </a>
            <CopyToClipboard
              text={dataShare.url}
              onCopy={() => message.success({ content: 'Copiado com sucesso!' })}
            >
              <button type="button" className="btn btn-danger yr">
                <i className="fas fa-copy mr-2" />
                Copiar Link
              </button>
            </CopyToClipboard>
          </div>

          {/*  */}
        </div>

      </div>
    </Modal>
  );
}

export default ModalShareSocial;
