/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { getToken, login, logout } from './auth';
import { base } from '../config';

const api = axios.create({
  baseURL: base,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token.access_token}`;
  }
  return config;
});

function getArrayStringsValidation(validation) {
  const result = [];

  validation.forEach((err) => {
    result.push(err.message);
  });

  return result;
}
function getResultError(error) {
  const { response = {} } = error;
  const { data } = response;

  const result = {};

  if (data && data.validation) {
    result.validation = data.validation;
    result.list = getArrayStringsValidation(data.validation);
  }

  result.message = error.response
    && error.response.data ? error.response.data.error : 'Erro ao enviar request';

  result.error = error;

  return result;
}

const createAxiosResponseInterceptor = () => {
  const interceptor = api.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log('error interceptor', error);
      // Reject promise if usual error
      if (error.response.status !== 401) {
        const result = getResultError(error);
        return Promise.reject(result);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      api.interceptors.response.eject(interceptor);

      const token = getToken();
      if (!token) {
        throw new Error('Token not found.');
      }
      return api.post('/token/refresh', {
        refresh_token: token.refresh_token,
        grant_type: 'refresh_token',
      }).then((response) => {
        login(response.data.data);
        error.response.config.headers.Authorization = `Bearer ${response.data.data.access_token}`;
        return api(error.response.config);
      }).catch((err) => {
        logout();
        window.location.href = '/';
        return Promise.reject(err);
      }).finally(createAxiosResponseInterceptor);
    },
  );
};

createAxiosResponseInterceptor();

export default api;
