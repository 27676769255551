import actionsTypes from './actionsTypes';
import api from '../services/api';

const actions = {
  getAll: (params) => ({
    type: actionsTypes.SELLER_GET_ALL,
    payload: api.get('/api/v1/vendedor', { params }),
  }),
  getAllPlans: (params) => ({
    type: actionsTypes.SELLER_GET_ALL_PLANS,
    payload: api.get('/api/v1/vendedor/planos', { params }),
  }),
  getById: (id) => ({
    type: actionsTypes.SELLER_GET_BY_ID,
    payload: api.get(`api/v1/vendedor/br5-infos/${id}`),
  }),
  getCurrent: () => ({
    type: actionsTypes.GET_CURRENT_SELLER,
    payload: {},
  }),
  setCurrent: (id) => ({
    type: actionsTypes.SET_CURRENT_SELLER,
    payload: id,
  }),
};

export default actions;
