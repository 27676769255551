import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import actions from '../../../actions/actionFirstAccess';

import helpers from '../../../helpers/all';

function Step2ListContacts() {
  const dispatch = useDispatch();

  const form = useSelector((state) => state.reducerFirstAccess.form);
  const item = useSelector((state) => state.reducerFirstAccess.item);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (item.contacts) setLoading(false);
  }, [item]);

  const onChange = (prop, value) => {
    console.log('onchange', value);
    form[prop] = value;
    dispatch(actions.form(form));
  };

  return (
    <Spin spinning={loading}>
      <form className="row">
        <div className="form-group col-12">
          {
            item.contacts && item.contacts.map((cont) => (
              <div className="">
                <input
                  onClick={() => {
                    onChange('contactId', cont.id);
                    onChange('loginContact', { value: cont.value, type: cont.type });
                  }}
                  value={form.contactId}
                  className="mr-2 mt-2"
                  type="radio"
                  name="contact"
                  id="contact"
                />
                <label className="label" htmlFor="contact">
                  <span className="label">{cont.type === 'cellPhone' ? helpers.formatPhone(cont.value) : cont.value}</span>
                </label>
              </div>
            ))
            }
        </div>
      </form>
    </Spin>
  );
}

export default Step2ListContacts;
