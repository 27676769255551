import {
  Modal,
  Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import actionErrors from '../../../actions/actionErrors';
import actionModal from '../../../actions/actionModal';
import actionQuotation from '../../../actions/actionQuotation';
import actionSellers from '../../../actions/actionSellers';
import { empresas } from '../../../config';
import { formatReal } from '../../../helpers/all';

function ModalBr5LicencasQuotation() {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.reducerModal.modalBr5LicencasChoosePlan);
  const plan = useSelector((state) => state.reducerSellers.plan);
  const userData = useSelector((state) => state.reducerUserLogged);
  const [loading, setLoading] = useState(false);
  const [urls, setUrls] = useState({ checkout: '', site: '' });
  const [defaultValue, setDefaultValue] = useState();
  const [all, setAll] = useState([]);

  const { br5Licencas } = empresas;
  const mountOptionsSelect = () => {
    if (!all || !all.length) {
      return [{ value: 0, label: 'Nada encontrado', identificador: '' }];
    }

    return all.map((item) => ({
      value: item.id,
      label: item.nome,
      identificador: item.identificador,
    }));
  };

  const populate = async () => {
    try {
      setLoading(true);
      await dispatch(actionSellers.getAllPlans());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  };

  useEffect(() => {
    populate();
  }, []);

  useEffect(() => {
    if (!plan?.items) return;

    const idLoja = '631658ca986e7f4dec1da010';
    const updatedAll = plan.items.filter((item) => item.id !== idLoja);

    setLoading(false);
    setAll(updatedAll); // Atualiza o estado 'all'
    setUrls({
      checkout: `${br5Licencas.baseUrlIndication}/?indication=${userData.pessoa}?plan=${updatedAll[0].identificador}`,
    });
    setDefaultValue({
      identificador: updatedAll[0].identificador,
      value: updatedAll[0].id,
      label: updatedAll[0].nome,
    });
  }, [plan?.items]);

  const onChange = (item) => {
    setDefaultValue({
      identificador: item.identificador,
      value: item.value,
      label: item.label,
    });
    setUrls({
      checkout: `${br5Licencas.baseUrlIndication}/?indication=${userData.pessoa}&plan=${item.identificador}`,
    });
  };

  return (
    <Modal
      width={window.innerWidth > 960 ? 800 : 'auto'}
      visible={modal}
      footer={null}
      onCancel={() => dispatch(actionModal.modalBr5LicencasChoosePlan(false))}
    >
      <Spin spinning={loading}>
        <div className="p-md-4 p-0">
          <div className="col-md-12 col-sm-12">
            <div className="box-title text-left mb-4">
              <i className="mr-2 fa fa-plus color-1" />
              <span className="title-2 color-2">Qual licença deseja indicar?</span>
              <span className="text-2 color-4 d-block" />
            </div>
            <div className="row">
              <div className="col-md-12 col-12 mb-5 text-center">
                <div className="form-group col-12">
                  <span className="font-weight-bold">Tipo de Licença</span>
                  <Select
                    options={mountOptionsSelect()}
                    onChange={onChange}
                    value={defaultValue}
                  />
                </div>
                <span className="title-3 text-bold mb-4 d-block">Compra Direta</span>
                <div className="btn-group pb-3" role="group" aria-label="Basic example">
                  <a href={urls.checkout} target="_blank" className="btn bg-color-1 text-white" rel="noreferrer">
                    <i className="fas fa-external-link-alt mr-2" />
                    Calcule Aqui!
                  </a>
                  <a
                    href="!#"
                    className="btn  bg-color-1  text-white"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(actionQuotation.dataShare({
                        url: urls.checkout,
                        text: br5Licencas.messageWhatsapp1,
                      }));
                      dispatch(actionModal.modalShareSocial(true));
                    }}
                  >
                    <i className="fas fa-share mr-2" />
                    Compartilhar
                  </a>
                </div>
              </div>
            </div>
            {/* <form className="row">
              <div className="form-group col-12">
                <span className="font-weight-bold">Tipo de Licença</span>
                <Select
                  options={mountOptionsSelect()}
                  onChange={onChange}
                  value={defaultValue}
                />
              </div>

              <div className="col-md-12 col-xs-12 mt-2 mb-3 text-center">
                <CopyToClipboard
                  text={urls.checkout}
                  onCopy={() => message.success({ content: 'Copiado com sucesso!' })}
                >
                  <span className="text-bold btn-sm
                  bg-color-1 title-shadow-one link-one link-hover-1 mt-3">
                    {/* <span>{ urls.checkout }</span>
                    <i className="fa fa-copy mr-2 ml-2" />
                    <b>Link Compra Direta </b>
                  </span>
                </CopyToClipboard>
              </div>
            </form> */}
          </div>
          <div className="col-12">
            <h2 className="title-3 color-2 mb-3 d-block mt-1">Lista de Licenças Disponíveis</h2>
            <div className="row">

              {
                all && all.length
                  ? all.map((item) => (
                    <div key={item.id} className="col-md-6 col-xs-12 mb-3">
                      <div className="card">
                        <h5 className="text-3 text-bold card-header bg-color-1 text-white">{item.nome}</h5>
                        <div className="card-body">
                          <h5 className="card-title box-title">
                            <span className="text-2">Valor da Adesão:</span>
                            {' '}
                            <b>{formatReal(item.valorAdesao)}</b>
                          </h5>
                          <h5 className="card-title box-title">
                            <span className="text-2">Valor Mensal:</span>
                            {' '}
                            <b>{formatReal(item.valorMensal)}</b>
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))
                  : <div className="col-12"><div className="alert alert-dark">Sem dados</div></div>
              }

            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default ModalBr5LicencasQuotation;
