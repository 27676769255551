/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import actionMarketing from '../../actions/actionMarketing';

function MarketingIndex() {
  const dispatch = useDispatch();

  const allItems = useSelector((state) => state.reducerMarketing.all);

  useEffect(() => {
    dispatch(actionMarketing.getAll());
  }, []);

  const styles = {
    height: '60px',
  };

  return (
    <div className="shadow-one p-md-5 p-2 mb-3">
      <div className="col-md-12 col-sm-12">
        <div className="box-title text-left mb-4">
          <div className="w-100 float-left">
            <span className="title-5 color-2">Marketing</span>
          </div>
        </div>
        <div className="row mt-2">
          {
            allItems && allItems.map((item) => (
              <div className="col-md-6 col-12 p-3">
                <div className=" mb-3 text-center card-custom shadow-one pt-5 pb-5">
                  <div style={styles} className="text-center d-block ">
                    <img alt="" loading="lazy" src={item.icon} className="size-icon-2" />
                  </div>
                  <h4 className="title-2 mt-3">Mídias sociais</h4>
                  <div className="mb-3 mt-4 container-img">
                    <img className="img-fluid" alt="" loading="lazy" src={item.image} />
                  </div>
                  <Link
                    to={`/marketing/${item.id}`}
                    className="btn btn-link bg-white text-dark text-center mt-3"
                  >
                    Ver opções
                    <i className="fa fa-arrow-right ml-2" />

                  </Link>

                </div>
              </div>
            ))
          }

        </div>
      </div>
    </div>
  );
}

// MarketingIndex.propTypes = {
//   match: PropTypes.objectOf({}).isRequired,
// };

export default MarketingIndex;
