import React, { useState, useEffect } from 'react';
import {
  Avatar, Modal, Spin, Table, Tabs,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import actionModal from '../../../actions/actionModal';

function ModalUserProfile() {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const modal = useSelector((state) => state.reducerModal.modalBr5UseProfile);
  const user = useSelector((state) => state.reducerUserLogged.user);
  const [loading, setLoading] = useState(false);

  const populate = async () => {
    try {
      setLoading(true);
    } finally {
      setLoading(false);
    }
  };

  const mount = () => {
    if (user?.pendenciasPlano?.length) {
      const columns = [
        {
          title: 'Data de cadastro',
          key: 'createdAt',
          render: (text, record) => {
            const item = record?.createdAt || '--';
            return <div>{moment(item).format('DD/MM/YYYY')}</div>;
          },
        },
        {
          title: 'Prazo de resolução',
          key: 'prazoResolução',
          render: (text, record) => {
            const item = record?.prazoResolucao || '--';
            return <div>{moment(item).format('DD/MM/YYYY')}</div>;
          },
        },
        {
          title: 'Status',
          key: 'mensagem',
          dataIndex: 'mensagem',
        },
      ];

      setItems([...items, {
        key: 'pendencias-plano',
        label: 'Pendências Plano',
        children: (
          <Table
            columns={columns}
            dataSource={user?.pendenciasPlano}
            pagination={false}
            className="col-12 table-responsive p-0"
          />
        ),
      }]);
    }
    if (user?.statusGateway) {
      const columns = [
        {
          title: 'Nome',
          key: 'nome',
          dataIndex: 'nome',
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
        },
        {
          title: 'Descrição',
          key: 'descricao',
          dataIndex: 'descricao',
        },
      ];

      setItems([...items,
        {
          key: 'status-gateway',
          label: 'Status Gateway',
          children: (
            <Table
              columns={columns}
              dataSource={[user?.statusGateway]}
              pagination={false}
              className="col-12 table-responsive p-0"
            />
          ),
        },
      ]);
    }
  };

  useEffect(() => {
    console.log('user', user);
    mount();
  }, [user]);

  useEffect(() => {
    populate();
  }, []);

  return (
    <Modal
      width="80vw"
      visible={modal}
      footer={null}
      onCancel={() => dispatch(actionModal.modalUserProfile(false))}
    >
      <Spin spinning={loading}>
        <div className="row p-md-4 p-0">
          <div className="box-title text-left mb-4">
            <i className="mr-2 fa fa-plus color-br5-1" />
            <span className="title-2 color-2">Detalhes do cliente</span>
            <span className="text-2 color-4 d-block">
              Confira todos os dados deste cliente abaixo.
              {' '}
              <b>
                a baixo
              </b>
            </span>
          </div>
          <div className="col-sm-12 col-md-4 text-center">

            <Avatar
              className="bg-color-1"
              style={{ verticalAlign: 'middle', borderRadius: 10 }}
              size={120}
              shape="square"
            >
              <span>{user?.nome ? user.nome.slice(0, 1) : ''}</span>
              <span>{user?.nomeCliente ? user.nomeCliente.slice(0, 1) : ''}</span>
            </Avatar>

            <div className="mt-3 mb-3">
              <p className="lead mb-0">{user?.nome || user?.nomeCliente}</p>

              <span className="badge badge-warning">{user?.situacao}</span>
              <span className="badge badge-warning">{user?.status}</span>
            </div>

            <p className="h5 text-left mt-0 mb-0">Detalhes</p>

            <hr />
            <div>
              {
                user?.doc && (
                <div className="d-flex">
                  <span className="mr-2">
                    <b>Documento: </b>
                  </span>
                  <span>{user?.doc}</span>
                </div>
                )
              }
              {
                user?.telefones?.length && (
                <div className="d-flex">
                  <span className="mr-2">
                    <b>Telefone: </b>
                  </span>
                  <span>
                    {user.telefones.map(({ numero }) => numero)}
                  </span>
                </div>
                )
              }
              {
                user?.telefoneCliente && (
                <div className="d-flex">
                  <span className="mr-2">
                    <b>Telefone: </b>
                  </span>
                  <span>
                    {user.telefoneCliente}
                  </span>
                </div>
                )
              }
              {user?.emails?.length && (
                <div className="d-flex">
                  <span className="mr-2">
                    <b>E-mail: </b>
                  </span>
                  <span>
                    {user.emails.map(({ endereco }) => endereco)}
                  </span>
                </div>
              )}
              {user?.emailCliente && (
                <div className="d-flex">
                  <span className="mr-2">
                    <b>E-mail: </b>
                  </span>
                  <span>
                    {user.emailCliente}
                  </span>
                </div>
              )}
              {user?.plano && (
                <div className="d-flex">
                  <span className="mr-2">
                    <b>Plano: </b>
                  </span>
                  <span>{user.plano.situacao}</span>
                </div>
              )}
              {user?.nomeEmpresa && (
                <div className="d-flex">
                  <span className="mr-2">
                    <b>Empresa Produto: </b>
                  </span>
                  <span>
                    {user.nomeEmpresa}
                  </span>
                </div>
              )}
              {user?.produto && (
                <div className="d-flex">
                  <span className="mr-2">
                    <b>Produto: </b>
                  </span>
                  <span>
                    {user.produto}
                  </span>
                </div>
              )}
              {user?.empresa && (
                <div className="d-flex">
                  <span className="mr-2">
                    <b>Empresa: </b>
                  </span>
                  <span>{user.empresa}</span>
                </div>
              )}
              {user?.gateway && (
                <div className="d-flex">
                  <span className="mr-2">
                    <b>Gateway: </b>
                  </span>
                  <span>{user.gateway}</span>
                </div>
              )}
              {user?.createdAt && (
                <div className="d-flex">
                  <span className="mr-2">
                    <b>Usuário desde: </b>
                  </span>
                  <span>{moment(user?.createdAt).format('DD/MM/YYYY')}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-8">
            <Tabs type="card" defaultActiveKey="1" items={items} />
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default ModalUserProfile;
