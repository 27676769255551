import actionsTypes from '../actions/actionsTypes';
import helpers from './helpers';

const INITIAL_STATE = {
  all: {},
  item: {},
  current: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.INVOICE_GET:
      return helpers.mapAll(action.payload, state, INITIAL_STATE);

    case actionsTypes.INVOICE_GET_BY_ID:
      return helpers.mapItem(action.payload, state, INITIAL_STATE);

    case actionsTypes.INVOICE_SET_CURRENT:
      return { ...state, current: action.payload };

    case actionsTypes.INVOICE_GET_CURRENT:
      return state.current;

    default:
      return state;
  }
};

export default reducer;
