import actionsTypes from './actionsTypes';
import api from '../services/api';

const actions = {
  getDetailsDashboard: (params) => ({
    type: actionsTypes.FINANCIAL_DASHBOARD_DETAILS,
    payload: api.get('/api/v1/vendedor/revendas/faturamento/detalhes', { params }),
  }),
  // getSheetBr5: (params, type) => ({
  //   type: actionsTypes.DOWNLOAD_SHEET_BR5,
  //   payload: api.get(`/api/v1/vendedor/dashboard/
  // download-planilha/${type}`, { params, responseType: 'blob' }),

  // }),
};

export default actions;
