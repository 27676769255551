import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {
  step: 1,
  item: {
    url: '',
    documentKey: '',
  },
  form: {
    id: '',
    doc: '',
    code: '',
    typeDoc: 'cnpj',
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.FIRST_ACCESS_FORM:
      return { ...state, form: action.payload };

    case actionsTypes.FIRST_ACCESS_SET_STEP:
      return { ...state, step: action.payload };

    case actionsTypes.SET_CONTRACT_URL:
      return {
        ...state,
        item: {
          ...state.item,
          url: action.payload.data.data.url,
          documentKey: action.payload.data.data.documentKey
          ,
        },
      };
    case actionsTypes.FIRST_ACCESS_STEP_1:
    case actionsTypes.FIRST_ACCESS_STEP_2:
    case actionsTypes.FIRST_ACCESS_STEP_3:
    case actionsTypes.FIRST_ACCESS_STEP_4:
      return {
        ...state,
        item: {
          ...state.item,
          ...(action.payload
            && action.payload.data
            ? action.payload.data
            : ''),
        },
      };

    default:
      return state;
  }
};

export default reducer;
