import React, { useState, useEffect } from 'react';
import {
  Table,
  Pagination,
  Modal,
  Spin,
  Tooltip,
  ConfigProvider,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import locale from 'antd/lib/locale/pt_BR';
import moment from 'moment';

import actionModal from '../../../actions/actionModal';
import actionErrors from '../../../actions/actionErrors';
import actions from '../../../actions/actionQuotation';
import helpersAll from '../../../helpers/all';
import { empresas, pagination } from '../../../config';

moment.locale('pt-br');

function ModalTopSegurosListQuotations() {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.reducerModal.modalTopSegurosListQuotations);
  const all = useSelector((state) => state.reducerQuotation.allTopSeguros);
  const [loading, setLoading] = useState(false);

  const populate = async (page) => {
    try {
      setLoading(true);
      await dispatch(actions.getAllTopSeguros({ ...pagination, page }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  };

  useEffect(() => {
    populate();
  }, []);

  // useEffect(() => {
  //   if (!all || !all.items || !all.items.length) {
  //     return;
  //   }

  //   setLoading(false);
  // }, [all.items]);

  const handleShow = () => {
    // dispatch(actionSellers.getAll({ page: pageClicked }));
  };

  const status = {
    emAndamento: 'Em Andamento',
    clicado: 'Clicado',
  };
  const columns = [
    {
      title: 'Cliente',
      key: 'nomeCliente',
      dataIndex: 'nomeCliente',
    },
    {
      title: 'Empresa',
      key: 'empresa',
      render: (text, record) => {
        const type = record.empresa || '';
        const item = type ? empresas[type].nomeFantasia : '--';
        return (
          <div>{ item }</div>
        );
      },
    },
    {
      title: 'Produto',
      key: 'produto',
      dataIndex: 'produto',
    },
    {
      title: 'Status',
      key: 'status',
      render: (text, record) => {
        const type = record.status || '';
        const item = type ? status[type] : '--';
        return (
          <div>{ item }</div>
        );
      },
    },
    {
      title: 'Telefone',
      key: 'telefoneCliente',
      dataIndex: 'telefoneCliente',
    },
    {
      title: 'Valor',
      key: 'valor',
      render: (text, record) => (<>{helpersAll.formatReal(record.valor)}</>),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (text, record) => (
        <div>
          <Tooltip title="Ver detalhes do vendedor" className="mr-2 text-dark">
            <a href="#!" onClick={(event) => handleShow(event, record.id)}>
              <i className="fa fa-search text-4" />
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Modal
      width="auto"
      visible={modal}
      footer={null}
      onCancel={() => dispatch(actionModal.modalTopSegurosListQuotations(false))}
    >
      <Spin spinning={loading}>
        <div className="p-md-4 p-0">
          <div className="col-md-12 col-sm-12">
            <div className="box-title text-left mb-4">
              <i className="mr-2 fa fa-plus color-br5-1" />
              <span className="title-2 color-2">Cotações realizadas na Top Seguros</span>
              <span className="text-2 color-4 d-block">
                Lista das cotações de produtos.
                {' '}
                <b>
                  Total de registros
                  {' '}
                  {all.totalCount}
                </b>
              </span>
            </div>

            <ConfigProvider locale={locale}>
              <Table columns={columns} dataSource={all.items} pagination={false} className="col-12 table-responsive" />
            </ConfigProvider>
            <div className="text-center w-100 mt-5">
              <Pagination
                defaultCurrent={all.page}
              // pageSize={filter.pageSize}
                total={all.totalCount}
                onChange={populate}
              />
            </div>
          </div>

        </div>
      </Spin>
    </Modal>
  );
}

export default ModalTopSegurosListQuotations;
