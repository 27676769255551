import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {
  dash: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.FINANCIAL_DASHBOARD_DETAILS:
      return {
        ...state,
        dash: action.payload?.data?.data || {},
      };

    default:
      return state;
  }
};

export default reducer;
