import React, { useState, useEffect } from 'react';
import {
  Modal,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import actionQuotation from '../../../actions/actionQuotation';
import actionModal from '../../../actions/actionModal';
import { empresas } from '../../../config';

function ModalExpertiseQuotation() {
  const dispatch = useDispatch();

  const company = 'expertise';
  const { expertise } = empresas;

  const modal = useSelector((state) => state.reducerModal.modalExpertiseQuotation);
  // const all = useSelector((state) => state.reducerPlan.all);
  const userData = useSelector((state) => state.reducerUserLogged);
  const [urls] = useState({
    cotacao: `${expertise.baseUrlIndication}/${userData.pessoa}/${company}`,
  });

  useEffect(() => {
    //
  }, []);

  // useEffect(() => {
  //   if (!all || !all.items || !all.items.length) {

  //   }
  //   // setUrls({
  //   checkout: `${urlIndicationCheckout}/${userData.pessoa}?plan=${all.items[0].identificador}`,
  //   site: `${urlIndicationSite}/${userData.pessoa}?plan=${all.items[0].identificador}`,
  //   // });
  // }, [all.items]);

  return (
    <Modal
      width={window.innerWidth > 960 ? 800 : 'auto'}
      visible={modal}
      footer={null}
      onCancel={() => dispatch(actionModal.modalExpertiseQuotation(false))}
    >
      <div className="p-md-4 p-0">
        <div className="col-md-12 col-sm-12">
          <div className="box-title text-left mb-4">
            <i className="mr-2 fa fa-plus color-exp-1" />
            <span className="title-2 color-2">Expertise Soluções Financeiras</span>
          </div>
          <div className="row">
            <div className="col-md-12 col-12 mb-5 text-center">
              <span className="title-3 text-bold mb-4 d-block">Cote sua solução financeira</span>
              <div className="btn-group pb-3" role="group" aria-label="Basic example">
                <a href={urls.cotacao} target="_blank" className="btn bg-color-exp-1 text-white" rel="noreferrer">
                  <i className="fas fa-external-link-alt mr-2" />
                  Calcule Aqui!
                </a>
                <a
                  href="!#"
                  className="btn  bg-color-exp-1  text-white"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(actionQuotation.dataShare({
                      url: urls.cotacao,
                      text: expertise.messageWhatsapp1,
                    }));
                    dispatch(actionModal.modalShareSocial(true));
                  }}
                >
                  <i className="fas fa-share mr-2" />
                  Compartilhar
                </a>
              </div>
            </div>

          </div>
        </div>
        <div className="col-12">
          <h2 className="title-3 color-2 mb-3 d-block mt-1">O que você pode cotar na Expertise?</h2>
          <div className="row">

            <div className="col-md-12 col-xs-12 mb-3">
              <div className="card">
                <h5 className="card-header text-4 text-bold bg-secondary text-white">Produtos:</h5>
                <div className="card-body">
                  <h5 className="card-title box-title text-3 text-bold">
                    Empréstimos
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Financiamentos
                  </h5>
                  <h5 className="card-title box-title text-3 text-bold">
                    Desconto inteligente na sua Conta de Energia
                  </h5>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalExpertiseQuotation;
