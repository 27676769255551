/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */

import React, { useState } from 'react';
import {
  Select, DatePicker, message,
} from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../../actions/actionComission';
import helpers from '../../../helpers/all';

const companyOptions = [
  { value: 'adesaoPF', label: 'Adesão BR5 Benefícios' },
  { value: 'mensalidadePF', label: 'Mensalidade BR5 Benefícios' },
  { value: 'adesaoRevendaAutorizada', label: 'BR5 Turbo' },
  { value: 'br5FinanceiraComissao', label: 'Expertise' },
  { value: 'topSegurosComissao', label: 'Top Seguros' },
];

const itemsPerPageOptions = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

const dateFormat = 'DD/MM/YYYY';

function Form({ setIsLoading, form, setForm }) {
  const [isShowFilters, setIsShowFilters] = useState(false);

  const data = useSelector((state) => state.reducerComissions.all);
  const dispatch = useDispatch();

  const onChangeForm = (prop, value) => {
    form[prop] = value;
    setForm({ ...form });
  };

  const handleShowFilters = () => {
    setIsShowFilters(true);
    setForm({
      categoriaEmpresa: null,
      inicio: '',
      fim: '',
      itemsPerPage: 10,
    });
  };

  const handleHideFilters = () => {
    setIsShowFilters(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!form.fim || !form.inicio) {
        throw new Error('Preencha os campos data inicial e final!');
      }

      const inicio = helpers.formatToUniversalDate(form.inicio._d);
      const fim = helpers.formatToUniversalDate(form.fim._d);
      await dispatch(actions.getComissions({
        ...form, inicio, fim, page: 1,
      }));
    } catch (err) {
      message.error(err.message || 'Não foi possível filtrar!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="w-100 p-2 shadow-three mt-4" onSubmit={(e) => onSubmit(e)}>
      <div className="d-flex flex-column">
        <header className="bg-color-7 p-2 color-black mb-2">Relatório de Comissão</header>

        <div className="d-flex flex-column flex-md-row mb-2 justify-content-between">
          <div className="d-flex align-items-center">
            <Select
              style={{ width: 72 }}
              onChange={(e) => onChangeForm('itemsPerPage', e)}
              options={itemsPerPageOptions}
              value={form.itemsPerPage}
              size="middle"
            />
            <p className="p-2 mb-0 text-bold">
              Itens por Página- Total de Registros:
              {' '}
              {data.totalCount || 0}
            </p>
          </div>
          {isShowFilters
            ? (<button type="button" onClick={handleHideFilters} className="border-0 p-2 pointer btn-clear-filters align-self-end">Remover filtros</button>)
            : (<button type="button" onClick={handleShowFilters} className="border-0 p-2 pointer btn-add-filters align-self-end bg-color-danger color-white rounded">Adicionar filtros</button>)}
        </div>
        {isShowFilters && (
        <>
          <Select
            onChange={(e) => onChangeForm('categoriaEmpresa', e)}
            options={companyOptions}
            className="mb-2"
            placeholder="Buscar empresa"
            value={form.categoriaEmpresa}
            size="large"
            disabled
          />
          <div className="d-flex mb-2">
            <div className="d-flex flex-column w-100 mr-2 mr-md-4">
              <label htmlFor="startDate">Inicial</label>
              <DatePicker
                id="startDate"
                onChange={(e) => onChangeForm('inicio', e)}
                style={{ width: '100%' }}
                locale={ptBR}
                value={form.inicio}
                format={dateFormat}
                showToday={false}
                size="large"
              />
            </div>
            <div className="d-flex flex-column w-100">
              <label htmlFor="endDate">Final</label>
              <DatePicker
                id="endDate"
                onChange={(e) => onChangeForm('fim', e)}
                style={{ width: '100%' }}
                locale={ptBR}
                value={form.fim}
                format={dateFormat}
                showToday={false}
                size="large"
              />
            </div>
          </div>
          <button
            type="submit"
            className="bg-color-black color-white border-0 p-2 align-self-end pointer"
          >
            <i className="fa fa-search mr-2 text-3" />
            Pesquisar
          </button>
        </>
        )}
      </div>

    </form>
  );
}

export default Form;
