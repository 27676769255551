import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
// import { Line } from 'react-chartjs-2';

import helpersFormat from '../../../helpers/format';
import actionDashboard from '../../../actions/actionDashboard';
import actionErrors from '../../../actions/actionErrors';
import actionModal from '../../../actions/actionModal';

function DashboardWorker() {
  const dispatch = useDispatch();

  const workerInfo = useSelector((state) => state.reducerDashboard.workerInfo);
  const userData = useSelector((state) => state.reducerUserLogged);
  const firstName = userData.name.split(' ')[0] || userData.name;
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    try {
      setLoading(true);
      await dispatch(actionDashboard.dashboardWorkerInfo());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  }, []);
  // const data = {
  //   labels:
  // ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro'],
  //   datasets: [
  //     {
  //       label: 'Plano assinatura 1',
  //       data: [10, 20, 22, 29, 45, 44, 54, 52, 53],
  //       fill: false,
  //       backgroundColor: 'rgb(76, 72, 73)',
  //       borderColor: 'rgba(76, 72, 73, 0.2)',
  //     },
  //     {
  //       label: 'Plano assinatura 2',
  //       data: [5, 5, 8, 12, 22, 25, 37, 44, 49],
  //       fill: false,
  //       backgroundColor: 'rgb(254, 106, 136)',
  //       borderColor: 'rgba(254, 106, 136, 0.2)',
  //     },
  //   ],
  // };

  // const options = {
  //   scales: {
  //     // parsing: {
  //     //   xAxisKey: 'key',
  //     //   yAxisKey: 'value',
  //     // },
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  // };

  return (
    <>
      <Spin spinning={loading}>
        <div className="shadow-one p-xl-5 p-3 mb-5">
          <div className="row">
            <div className="box-title text-left mb-3">
              <i className="mr-2 fa fa-tachometer-alt color-1" />
              <span className="title-2 color-2">Dashboard</span>
              <span className="text-2 color-4 d-block">
                Olá,
                {' '}
                {firstName}
                {' '}
                Seja bem vindo ao sistema Enex Saúde
              </span>
            </div>

            <h4 className="title-3 mt-2 mb-4 col-12">
              <i className="fa fa-angle-double-right mr-2" />
              Informações do seu plano
            </h4>

            <div className="col-md-6 col-sm-12">
              <div className="card-one shadow-one">
                <div className="card-header-one bg-color-1">
                  Empresa
                </div>
                <div className="card-body-one">
                  <span className="text-3 color-4">{workerInfo?.holderName}</span>
                </div>
                <div className="card-footer-one">
                  Essa é a empresa que te cadastrou aqui!
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="card-one shadow-one">
                <div className="card-header-one bg-color-1">
                  Cadastro
                </div>
                <div className="card-body-one">
                  {helpersFormat.formatDate(workerInfo?.employeeCreatedAt)}
                </div>
                <div className="card-footer-one">
                  Sua data de cadastro
                </div>
              </div>
            </div>

          </div>
        </div>
      </Spin>
    </>
  );
  // return (
  //   <>
  //     <div className="shadow-one p-xl-5 p-3 mb-5">
  //       <div className="col-md-12 col-sm-12">
  //         <div className="box-title text-left mb-3">
  //           <i className="mr-2 fa fa-tachometer-alt color-1" />
  //           <span className="title-2 color-2">Dashboard</span>
  //           <span className="text-2 color-4 d-block">\
  // Seja bem vindo ao seu painel de controle</span>
  //         </div>

  //         <h4 className="title-3 mt-2">
  //           <i className="fa fa-angle-double-right mr-2" />
  //           Informações de seus planos
  //         </h4>
  //         <span className="text-1 color-4 d-block">Quantidade de funcionários por mês</span>
  //         <div className="text-center mb-3 mt-3">
  //           <Line data={data} options={options} />
  //           {/* <canvas id="dashboard-plan-client" width="400" height="400"></canvas> */}
  //           {/* <img src="/images/dash.png" className="img-fluid" alt="" /> */}
  //         </div>
  //       </div>
  //     </div>

  //   </>
  // );
}

export default DashboardWorker;
