/* eslint-disable react/prop-types */
import React from 'react';

function SellerGroup(props) {
  const { form } = props;

  return (
    <>
      <p className="bg-color-8 p-1 text-bold text-4 mb-0">Informações do vendedor</p>
      <div className="group mb-4">
        <div className="input-container pt-4">
          <div className="mr-3">
            <label htmlFor="sellerType" className="label">
              Tipo de vendedor
            </label>
            <input
              type="text"
              className="form-control input input-disabled "
              placeholder="Digite o tipo do vendedor"
              id="sellerType"
              value={form.sellerType}
              disabled
            />
          </div>
          <div>
            <label htmlFor="sellerParent" className="label">
              Vendedor pai
            </label>
            <input
              type="text"
              className="form-control input input-disabled"
              id="sellerParent"
              value={form.sellerParent}
              disabled
            />
          </div>
        </div>

        <div className="input-container mt-4">
          <div className="mr-3">
            <label htmlFor="membershipCommission" className="label">
              Comissão adesão
            </label>
            <input
              type="text"
              className="form-control input input-disabled "
              id="membershipCommission"
              value={form.membershipCommission}
              disabled
            />
          </div>
          <div>
            <label htmlFor="monthlyCommission" className="label">
              Comissão mensalidade - BR5 benefícios
            </label>
            <input
              type="text"
              className="form-control input input-disabled"
              id="monthlyCommission"
              value={form.monthlyCommission}
              disabled
            />
          </div>
        </div>
      </div>

    </>
  );
}

export default SellerGroup;
