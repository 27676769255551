/* eslint-disable react/prop-types */
import React from 'react';

function LicenseGroup(props) {
  const { form } = props;

  return (
    <>
      <p className="bg-color-8 p-1 text-bold text-4 mb-0">Informações da licença</p>
      <div className="group mb-4">
        <div className="input-container pt-4">
          <div className="mr-3">
            <label htmlFor="licenseType" className="label">
              Tipo de licença
            </label>
            <input
              type="text"
              className="form-control input input-disabled "
              id="licenseType"
              value={form.licenseType}
              disabled
            />
          </div>
          <div className="mr-3">
            <label htmlFor="licenseMembershipCommission" className="label">
              Comissão adesão
            </label>
            <input
              type="text"
              className="form-control input input-disabled "
              id="licenseMembershipCommission"
              value={form.licenseMembershipCommission}
              disabled
            />
          </div>
          <div>
            <label htmlFor="isMonthlyCommission" className="label">
              Gerar mensalidade na adesão
            </label>
            <input
              type="text"
              className="form-control input input-disabled"
              id="isMonthlyCommission"
              value={form.isMonthlyCommission}
              disabled
            />
          </div>
        </div>
      </div>

    </>
  );
}

export default LicenseGroup;
