/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import helpers from '../../../helpers/all';

import actions from '../../../actions/actionFirstAccess';

function Step4Store() {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [contacts, setContacts] = useState({ emails: [], cellPhones: [] });

  const dispatch = useDispatch();

  const form = useSelector((state) => state.reducerFirstAccess.form);
  const item = useSelector((state) => state.reducerFirstAccess.item);

  const onChange = (prop, value) => {
    form[prop] = value;
    dispatch(actions.form(form));
  };

  const getContacts = () => {
    const result = { emails: [], cellPhones: [] };
    if (!item.contacts) return result;

    item.contacts.forEach((cont) => {
      if (cont.type === 'cellPhone') {
        result.cellPhones.push(cont);
      } else {
        result.emails.push(cont);
      }
    });

    return result;
  };

  useEffect(() => {
    setContacts(getContacts());
  }, []);

  const onHidePassword = (setFunction) => {
    setFunction(false);
  };

  const onShowPassword = (setFunction) => {
    setFunction(true);
  };
  return (
    <form className="row">
      {
          form.loginContact?.type === 'cellPhone' ? (
            <div className="form-group col-12">
              <span className="font-weight-bold">*Selecione seu celular</span>
              {
                contacts.cellPhones.map((cont) => (
                  <div>
                    <input
                      onClick={() => onChange('cellPhoneId', cont.id)}
                      value={form.contactId}
                      className="mr-2 mt-2"
                      type="radio"
                      name="cellPhone"
                      id="cellPhone"
                    />
                    <label className="label" htmlFor="cellPhone">
                      <span className="label">{helpers.formatPhone(cont.value)}</span>
                    </label>
                  </div>
                ))
              }
            </div>
          ) : <></>
        }
      {
          form.loginContact?.type === 'email' ? (
            <div className="form-group col-12">
              <span className="font-weight-bold">*Selecione seu email</span>
              {
                contacts.emails.map((cont) => (
                  <div>
                    <input
                      onClick={() => onChange('emailId', cont.id)}
                      value={form.contactId}
                      className="mr-2 mt-2"
                      type="radio"
                      name="email"
                      id="email"
                    />
                    <label className="label" htmlFor="email">
                      <span className="label">{cont.value}</span>
                    </label>
                  </div>
                ))
              }
            </div>
          ) : <></>
        }

      <div className="form-group w-100 mx-3 position-relative">
        <label htmlFor="password" className="label">
          Crie uma senha
        </label>
        <input
          type={isPasswordVisible ? 'text' : 'password'}
          className="form-control input"
          placeholder="Crie sua senha de login"
          id="password"
          value={form.password}
          onChange={(event) => onChange('password', event.target.value)}
        />
        {isPasswordVisible
          ? <button type="button" className="button-password" onClick={() => onHidePassword(setIsPasswordVisible)}><i className="fa fa-eye-slash " /></button>
          : <button type="button" className="button-password" onClick={() => onShowPassword(setIsPasswordVisible)}><i className="fa fa-eye" /></button>}
        <p className="text-1 mt-1">A senha deve conter pelo menos 6 dígitos, sendo 1 letra, 1 número e 1 caractere especial.</p>
      </div>

      <div className="form-group w-100 mx-3 position-relative mb-0">
        <label htmlFor="passwordConfirm" className="label">
          Confirme sua senha
        </label>
        <input
          type={isConfirmPasswordVisible ? 'text' : 'password'}
          className="form-control input"
          placeholder="Digite novamente a senha"
          id="passwordConfirm"
          value={form.passwordConfirm}
          onChange={(event) => onChange('passwordConfirm', event.target.value)}
        />
        {isConfirmPasswordVisible
          ? <button type="button" className="button-password" onClick={() => onHidePassword(setIsConfirmPasswordVisible)}><i className="fa fa-eye-slash " /></button>
          : <button type="button" className="button-password" onClick={() => onShowPassword(setIsConfirmPasswordVisible)}><i className="fa fa-eye" /></button>}
      </div>
      <p className="text-2 mt-3 mx-3">
        <strong>Atenção: </strong>
        Para salvar os seus dados, você deverá realizar a assinatura do contrato.
      </p>
    </form>
  );
}

export default Step4Store;
