import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, message } from 'antd';

import PersonalGroup from './FormGroups/PersonalGroup';
import AddressGroup from './FormGroups/AddressGroup';
import LicenseGroup from './FormGroups/LicenseGroup';
import SellerGroup from './FormGroups/SellerGroup';
import ContactGroup from './FormGroups/ContactGroup';

import actionSellers from '../../../actions/actionSellers';
import actionProfileSettings from '../../../actions/actionProfileSettings';

import helpers from '../../../helpers/all';
import format from '../../../helpers/format';
import { typesSellers } from '../../../config';

function EditLicenseIndex() {
  const [form, setForm] = useState({
    nome: '----',
    nascimento: '----',
    cnpj: '----',
    businessName: '----',
    cep: '-----',
    rua: '----',
    numbero: '----',
    complemento: '----',
    bairro: '----',
    cidade: '-----',
    estado: '----',
    licenseType: '----',
    licenseMembershipCommission: '----',
    isMonthlyCommission: '---',
    sellerType: '----',
    sellerParent: '----',
    membershipCommission: '---',
    monthlyCommission: '----',
    email: '----',
    telefone: '----',
  });
  const [isLoading, setIsLoading] = useState(true);

  const currentId = useSelector((state) => state.reducerSellers.current);
  const seller = useSelector((state) => state.reducerSellers.item);
  const dispatch = useDispatch();
  const history = useHistory();

  const getSellerData = async () => {
    if (currentId) {
      try {
        setIsLoading(true);
        await dispatch(actionSellers.getById(currentId));
        setIsLoading(false);
      } catch {
        message.error('Houve um problema ao obter os dados do vendedor');
      } finally { setIsLoading(false); }
    }
  };

  const setSeller = () => {
    setForm({
      nome: helpers.formatName(seller?.nome_razao_social),
      nascimento: format.formatDate(seller?.data_nascimento),
      cnpj: seller?.cpf_cnpj,
      businessName: helpers.formatName(seller?.nome_razao_social),

      endereco: {
        cep: helpers.formatZipcode(seller?.cep),
        rua: seller?.rua,
        numero: seller?.numero || '',
        complemento: seller?.complemento || '',
        bairro: seller?.bairro,
        cidade: seller?.cidade,
        estado: seller?.estado,
      },

      licenseType: typesSellers[seller.tipo_vendedor_licenca],
      licenseMembershipCommission: `${seller?.percentual_adesao} %`,
      isMonthlyCommission: '--',

      sellerType: typesSellers[seller.tipo_vendedor_licenca],
      sellerParent: seller?.vendedor_pai ? helpers.formatName(seller?.vendedor_pai) : 'Sem vendedor pai',
      membershipCommission: `${seller?.percentual_adesao} %`,
      monthlyCommission: `${seller?.percentual_mensalidade} %`,

      email: seller?.email,
      telefone: seller?.telefone && helpers.formatPhone(seller?.telefone[0]),
    });
  };
  useEffect(getSellerData, [currentId]);

  useEffect(setSeller, [seller]);

  const getAddressByZipCode = async (param = '') => {
    const zipCode = param.replace(/-/, '');

    if (!zipCode || !/^[0-9]{8}$/.test(zipCode)) return;

    try {
      setIsLoading(true);
      const { payload } = await dispatch(actionProfileSettings.getAddressByZipCode(zipCode));
      const { data } = payload.data;

      setForm((prevState) => ({
        ...prevState,
        endereco: {
          rua: data?.street,
          bairro: data?.neighborhood,
          cep: data?.zipCode,
          cidade: data?.city,
          estado: data?.state,
          numero: '',
          complemento: '',
        },
      }));
    } catch {
      message.error('Houve um problema ao obter os dados do endereço');
    } finally { setIsLoading(false); }
  };

  const onChangeForm = (prop, value) => {
    form[prop] = value;
    setForm({ ...form });
  };

  const onChangeAdress = (prop, value) => {
    form.endereco[prop] = value;
    setForm({ ...form });

    if (prop === 'cep') {
      getAddressByZipCode(value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    delete form.cnpj;
    delete form.businessName;
    delete form.licenseType;
    delete form.licenseMembershipCommission;
    delete form.monthlyCommission;
    delete form.sellerType;
    delete form.sellerParent;
    delete form.membershipCommission;
    delete form.monthlyCommission;
    delete form.isMonthlyCommission;

    try {
      await dispatch(actionProfileSettings.updateUser(currentId, form));
      message.success('Dados salvos com sucesso!');
      history.push('/licenca/informacoes');
    } catch (err) {
      message.error(err.message || 'Não foi possivel salvar as alterações!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <form className="bg-white mb-5 pt-5 page-container" onSubmit={(e) => onSubmit(e)}>
        <h1 className="text-4 mt-2 mb-4">Informações licença</h1>

        <PersonalGroup form={form} onChangeForm={onChangeForm} />
        <AddressGroup form={form} onChangeForm={onChangeAdress} />
        <LicenseGroup form={form} />
        <SellerGroup form={form} />
        <ContactGroup form={form} onChangeForm={onChangeForm} />

        <div className="d-flex align-items-center justify-content-center">
          <button type="submit" className="bg-color-black color-white border-0 font-weight-bold p-2 align-self-end pointer edit-license-button">Salvar</button>
          <Link to="/licenca/informacoes" className="button edit-license-button d-flex align-items-center justify-content-center">Cancelar</Link>
        </div>
      </form>
    </Spin>
  );
}

export default EditLicenseIndex;
