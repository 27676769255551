/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { message, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import helpers from '../../../helpers/all';
import actionProfileSettings from '../../../actions/actionProfileSettings';

function UpdatePasswordForm() {
  const [form, setForm] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.reducerUserLogged);

  const onHidePassword = (setFunction) => {
    setFunction(false);
  };

  const onShowPassword = (setFunction) => {
    setFunction(true);
  };

  const onChangeForm = (prop, value) => {
    form[prop] = value;
    setForm({ ...form });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!form.newPassword || !form.confirmPassword || !form.oldPassword) {
        throw new Error('Preencha todos os campos!');
      }

      if (!helpers.validatePassword(form.newPassword)) {
        throw new Error('A senha deve conter pelo menos 6 dígitos,sendo 1 letra, 1 número e 1 caractere especial.');
      }

      if (form.newPassword !== form.confirmPassword) {
        throw new Error('Os campos não conferem!');
      }

      await dispatch(actionProfileSettings.updatePassword(user.userId, form));
      setForm({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      message.success('Senha alterada com sucesso!');
    } catch (err) {
      message.error(err.message || 'Não foi possivel salvar as alterações!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className="mt-5 ">
        <h2 className="title">Alterar Senha</h2>
        <form className="login-form mt-3 d-flex flex-column" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group position-relative">
            <label htmlFor="oldPassword" className="label">
              Senha atual
            </label>
            <input
              type={isOldPasswordVisible ? 'text' : 'password'}
              className="form-control input"
              placeholder="Digite sua senha atual"
              onChange={(e) => onChangeForm('oldPassword', e.target.value)}
              value={form.oldPassword}
              id="oldPassword"
            />
            {isOldPasswordVisible
              ? <button type="button" className="button-password" onClick={() => onHidePassword(setIsOldPasswordVisible)}><i className="fa fa-eye-slash " /></button>
              : <button type="button" className="button-password" onClick={() => onShowPassword(setIsOldPasswordVisible)}><i className="fa fa-eye" /></button>}
          </div>
          <div className="form-group position-relative">
            <label htmlFor="newPassword" className="label">
              Nova senha
            </label>
            <input
              type={isNewPasswordVisible ? 'text' : 'password'}
              className="form-control input"
              placeholder="Digite uma senha nova"
              onChange={(event) => onChangeForm('newPassword', event.target.value)}
              value={form.newPassword}
              id="newPassword"
            />
            {isNewPasswordVisible
              ? <button type="button" className="button-password" onClick={() => onHidePassword(setIsNewPasswordVisible)}><i className="fa fa-eye-slash " /></button>
              : <button type="button" className="button-password" onClick={() => onShowPassword(setIsNewPasswordVisible)}><i className="fa fa-eye" /></button>}
            <p className="text-1 text-bold mt-2">A senha deve ter pelo menos 6 dígitos, sendo 1 letra, 1 número e 1 caractere especial.</p>
          </div>
          <div className="form-group position-relative">
            <label htmlFor="confirmPassword" className="label">
              Confirme a nova senha
            </label>
            <input
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              className="form-control input"
              placeholder="Digite novamente a senha"
              onChange={(event) => onChangeForm('confirmPassword', event.target.value)}
              value={form.confirmPassword}
              id="confirmPassword"
            />
            {isConfirmPasswordVisible
              ? <button type="button" className="button-password" onClick={() => onHidePassword(setIsConfirmPasswordVisible)}><i className="fa fa-eye-slash " /></button>
              : <button type="button" className="button-password" onClick={() => onShowPassword(setIsConfirmPasswordVisible)}><i className="fa fa-eye" /></button>}
          </div>
          <button
            type="submit"
            className="bg-color-black color-white border-0 font-weight-bold p-2 align-self-end pointer"
          >
            Salvar
          </button>
        </form>
      </div>
    </Spin>
  );
}

export default UpdatePasswordForm;
