/* eslint-disable no-underscore-dangle */
import { message, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ModalFirstAccess from './first-access/ModalFirstAccess';

import actionModal from '../../actions/actionModal';
import { isCellPhone, isEmail } from '../../helpers/all';
// eslint-disable-next-line no-unused-vars
import { login, setDataUser, logout } from '../../services/auth';
import api from '../../services/api';
import { identifiersProfile } from '../../config';

function Login() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    username: '',
    password: '',
  });

  const handleLogin = async () => {
    try {
      if (!form.password || !form.username) {
        throw new Error('Por favor, preencha o login e senha corretamente');
      }

      if (!isCellPhone(form.username) && !isEmail(form.username)) {
        throw new Error('Por favor, informe um celular ou email válido');
      }
      setLoading(true);

      const { data } = await api.post('/oauth/token', { ...form, grant_type: 'password' });
      login(data);
      console.log('login data', data);

      const { data: user } = await api.get('/api/v1/usuario/basic');
      console.log('basic', user.data);
      const profile = { perfil: '', num: '' };
      user.data.perfis.forEach((element) => {
        identifiersProfile.find((num) => {
          if (num === element.identificador && num === 12) {
            profile.perfil = element._id;
            profile.num = num;
            return null;
          }
          if (num === element.identificador) {
            profile.perfil = element._id;
            profile.num = num;
          }
          return null;
        });
      });
      console.log('profile', profile);

      const { data: dataRefresh } = await api.post('/oauth/token', {
        refresh_token: data.refresh_token,
        grant_type: 'refresh_token',
        perfil: profile.perfil,
      });

      if (!dataRefresh) {
        throw new Error('Perfil não identificado');
      }

      user.data.identificador = profile.num;

      setDataUser(user.data);
      console.log('login dataRefresh', user.data);

      window.location.href = '/';
    } catch (error) {
      logout();
      if (error.message === 'Token not found.') {
        message.error('Usuário ou senha incorretos!');
      } else {
        message.error('Houve um erro ao realizar login.');
      }
    } finally {
      setLoading(false);
    }
  };

  const onChangeForm = (prop, value) => {
    form[prop] = value;
    setForm({ ...form });
  };

  const handleModalFirstAccess = (event) => {
    event.preventDefault();
    dispatch(actionModal.modalFirstAccess(true));
  };

  return (
    <Spin spinning={loading}>
      <section
        className="login-block"
        // style={{
        //   backgroundImage: 'url("/images/bg-login.png")',
        //   backgroundPosition: 'center top',
        //   backgroundSize: 'cover',
        // }}
      >
        <ModalFirstAccess />
        <div className="container container-login shadow-one p-md-5 p-2">
          <div href="/" className="logo-login">
            <img src="/images/logo-default.png" alt="" className="img-fluid" />
          </div>
          <div className="row">
            <div className="col login-sec">
              {/* <h2 className="text-center">Login Now</h2> */}
              <form className="login-form">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1" className=" color-white">Celular ou email</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(event) => onChangeForm('username', event.target.value)}
                    value={form.username}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1" className="color-white">Senha</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder=""
                    onChange={(event) => onChangeForm('password', event.target.value)}
                    value={form.password}
                  />
                </div>

                <a
                  className="link-one title-shadow-one link-hover-1 bg-color-1 mt-4 text-center mb-3"
                  href="asd"
                  onClick={(event) => {
                    event.preventDefault();
                    handleLogin();
                  }}
                >
                  <span className="line-link-one" />
                  <h5 className="title-3 text-white">
                    <i className="fa fa-lock mr-2" />
                    LOGIN
                  </h5>
                </a>
                <div className="col-12 mt-2 mb-3 text-center">
                  <a href="/" className="text-bold btn-sm color-1 link-one link-hover-1 mt-3" onClick={handleModalFirstAccess}>
                    <i className="fa fa-key mr-2" />
                    <b>Primeiro Acesso </b>
                  </a>
                </div>

              </form>
            </div>
          </div>
        </div>
      </section>
    </Spin>
  );
}

export default Login;
