import DashboardPj from './dashboard-pj/DashboardPj';
import DashboardAdmin from './dashboard-admin/DashboardAdmin';
import DashboardDefault from './dashboard-default/DashboardDefault';
import DashboardWorker from './dashboard-worker/DashboardWorker';
import DashboardResale from './dashboard-resale/DashboardResale';

// const profiles = {
//   company: 'company',
//   employee: 'employee',
//   medic: 'medic',
//   consultationAttendant: 'consultationAttendant',
//   admin: 'admin',
// }
const dashboards = {
  company: DashboardPj,
  admin: DashboardAdmin,
  employee: DashboardWorker,
  12: DashboardResale,
  13: DashboardResale,
  14: DashboardResale,
};

function getDashboard(profile) {
  const dash = dashboards[profile];
  if (!dash) return DashboardDefault;

  return dash;
}

export default {
  getDashboard,
};
