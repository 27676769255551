import React, { useEffect } from 'react';
import { Tooltip, message } from 'antd';
import { HashRouter, NavLink } from 'react-router-dom';

import { BsWhatsapp } from 'react-icons/bs';
import { logout } from '../../services/auth';
import api from '../../services/api';

import CardUserLogged from '../shared/CardUserLogged';
import LogoDefault from '../shared/LogoDefault';

// import mountNav from '../../helpers/mountNav';

function SideBar() {
  //
  // const [navs, setNavs] = useState([]);
  const phoneNumber = '556592263859'; // Número de telefone para o qual você deseja enviar uma mensagem

  useEffect(() => {
    // setNavs(mountNav.getNav());
  }, []);
  // console.log('navs', navs);

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      await api.post('/token/logout');
      logout();
      window.location.href = '/';
    } catch (error) {
      logout();
      window.location.href = '/';
      if (error.response) {
        message.error(error.response.data.error);
      } else {
        message.error(error.message || 'Houve um erro ao realizar logout.');
      }
    }
  };

  // const renderSubMenu = (subModules) => {
  //   const hasSubModules = subModules && subModules.length;
  //   if (!hasSubModules) return <i className="" />;

  //   return (
  //     <div className="sidebar-submenu">
  //       <ul>
  //         {
  //           hasSubModules
  //           && subModules.map((sub) => (
  //             <li>
  //               <NavLink className="lnk-side" to={sub.url}>
  //                 <span>{sub.name}</span>
  //               </NavLink>
  //             </li>
  //           ))
  //         }
  //       </ul>
  //     </div>
  //   );
  // };

  // const renderMenu = (item, hasSubModule) => {
  //   const icon = mountNav.getIcon(item.identifier);
  //   if (hasSubModule) {
  //     return (
  //       <a href="/">
  //         <i className={icon} />
  //         <span>{item.name}</span>
  //       </a>
  //     );
  //   }

  //   return (
  //     <NavLink to={item.url || ''}>
  //       <i className={icon} />
  //       <span>{item.name}</span>
  //     </NavLink>
  //   );
  // };

  return (
    <>
      <HashRouter>
        <a id="show-sidebar" className="btn btn-sm btn-dark" href="/toggle">
          <i className="fas fa-bars" />
        </a>
        <nav id="sidebar" className="sidebar-wrapper">
          <div className="sidebar-content">
            <LogoDefault />
            <CardUserLogged />

            {/* <div className="sidebar-search">
              <div>
                <div className="input-group">
                  <input type="text" className="form-control search-menu" placeholder="Search..." />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fa fa-search" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="sidebar-menu">
              <ul>
                <li className="header-menu">
                  <span>Navegação</span>
                </li>
                <li>
                  <NavLink className="lnk-side" to="/">
                    <i className="fa fa-home" />
                    <span>Inicial</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="cotacoes">
                    <i className="fa fa-globe" />
                    <span>Cotações</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/produtos-treinamentos">
                    <i className="fa fa-solid fa-list-ol" />
                    <span>Prod. e Treinamentos</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="marketing">
                    <i className="fa fa-lightbulb" />
                    <span>Marketing</span>
                  </NavLink>
                </li>
                <li className="header-menu">
                  <span>Área Restrita</span>
                </li>
                {/* <li>
                  <NavLink to="painel-financeiro">
                    <i className="fa fa-chart-line" />
                    <span>Painel Financeiro</span>
                  </NavLink>
                </li> */}

                <li className="sidebar-dropdown">
                  <a href="#!">
                    <i className="fa fa-solid fa-map" />
                    <span>Ecossistema</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <NavLink to="/licencas">Estrutura das Licenças</NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <NavLink to="saques">
                    <i className="fa fas fa-hand-holding-usd" />
                    <span>Meus Saques</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="relatorio-comissao">
                    <i className="fa fa-coins" />
                    <span>Relatório de Comissão</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="configuracao-perfil">
                    <i className="fa fa-cog" />
                    <span>Configuração/ Perfil</span>
                  </NavLink>
                </li>
                <li>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa">
                      <BsWhatsapp style={{ marginTop: 0, fontSize: 15 }} />
                    </i>
                    <span>Dúvidas? Contate-nos</span>
                  </a>
                </li>
                {/*  <li>
                  <NavLink to="saques">
                    <i className="fa fa-coins" />
                    <span>Minhas Faturas</span>
                  </NavLink>
                </li> */}
                {/* {
                  navs.length && navs.map((item) => {
                    const hasSubModules = item.subModules && item.subModules.length;

                    return (
                      <>
                        <li className={hasSubModules ? 'sidebar-dropdown' : ''}>

                          { renderMenu(item, hasSubModules) }
                          { renderSubMenu(item.subModules) }
                        </li>
                      </>
                    );
                  })
                } */}

                {/* <li className="header-menu">
                  <span>Ajuda</span>
                </li>
                <li>
                  <a href="asd">
                    <i className="fa fa-question-circle" />
                    <span>Dúvidas frequentes</span>
                  </a>
                </li>
                <li>
                  <a href="asd">
                    <i className="fa fa-envelope" />
                    <span>Fale conosco</span>
                  </a>
                </li>
                <li>
                  <a href="asd">
                    <i className="fa fa-ticket-alt" />
                    <span>Abrir um chamado</span>
                  </a>
                </li>

                <li className="sidebar-dropdown">
                  <a href="asd">
                    <i className="fa fa-clipboard-list" />
                    <span>Serviços</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="asd">Cadastrar serviço</a>
                      </li>
                      <li>
                        <a href="asd">Listar serviços</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="sidebar-dropdown">
                  <a href="asd">
                    <i className="fa fa-coins" />
                    <span>Financeiro</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li className="header-menu">
                        <i className="fa fa-wallet" />
                        <span>Contas</span>
                      </li>
                      <li>
                        <a href="asd">Contas a receber</a>
                      </li>
                      <li>
                        <a href="asd">Contas a pagar</a>
                      </li>
                      <li className="header-menu">
                        <i className="fa fa-chart-line" />
                        <span>Relatórios</span>
                      </li>
                      <li>
                        <a href="asd">Relatório Recebimento</a>
                      </li>
                      <li>
                        <a href="asd">Relatório Saídas</a>
                      </li>
                      <li className="header-menu">
                        <i className="fa fa-cog" />
                        <span>Configurações</span>
                      </li>
                      <li>
                        <a href="asd">Categorias</a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="sidebar-dropdown">
                  <a href="asd">
                    <i className="fa fa-file-alt" />
                    <span>Textos e informações</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="asd">Postagens</a>
                      </li>
                      <li>
                        <a href="asd">Categorias</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="asd">
                    <i className="fa fa-user-plus" />
                    <span>Leads</span>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="sidebar-footer">
            {/* <a href="asd">
              <i className="fa fa-bell" />
              <span className="badge badge-pill badge-warning notification">3</span>
            </a>
            <a href="asd">
              <i className="fa fa-envelope" />
              <span className="badge badge-pill badge-success notification">7</span>
            </a>
            <a href="asd">
              <i className="fa fa-cog" />
              <span className="badge-sonar" />
            </a> */}
            <Tooltip title="Deseja sair?">
              <a href="/" className="btn-logout" onClick={(event) => handleLogout(event)}>
                <i className="fa fa-power-off" />
              </a>
            </Tooltip>
          </div>
        </nav>
      </HashRouter>
    </>
  );
}

export default SideBar;
