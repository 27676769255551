import actionsTypes from '../actions/actionsTypes';
import helpers from './helpers';

const INITIAL_STATE = {
  all: {},
  plan: {},
  item: {},
  current: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.SELLER_GET_ALL:
      return helpers.mapAll(action.payload, state, INITIAL_STATE);

    case actionsTypes.SELLER_GET_ALL_PLANS:
      return helpers.mapAll(action.payload, state, INITIAL_STATE, 'plan');

    case actionsTypes.INVOICE_GET_BY_ID:
      return helpers.mapItem(action.payload, state, INITIAL_STATE);

    case actionsTypes.SET_CURRENT_SELLER:
      return { ...state, current: action.payload };

    case actionsTypes.GET_CURRENT_SELLER:
      return state.current;

    case actionsTypes.SELLER_GET_BY_ID:
      return { ...state, item: action.payload.data };

    default:
      return state;
  }
};

export default reducer;
