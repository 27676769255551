/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, HashRouter, Redirect } from 'react-router-dom';

import main from '../components/main';
import NotFound from '../components/404';
import err from '../components/err';
import quotes from '../components/quotes';
import products from '../components/products-and-training';
import productsDetails from '../components/products-and-training/details';
import marketing from '../components/marketing';
import marketingDetails from '../components/marketing/details/Details';
import sellers from '../components/sellers';
import licenseInformation from '../components/sellers/LicenceInformation';
import editLicense from '../components/sellers/EditLicense';
import financial from '../components/financial';
import withdraw from '../components/withdraw';
import comissionReport from '../components/comission-report';
import profileSettings from '../components/profile-settings';
// import consultation from '../components/consultation';
// import medics from '../components/medics';
// import profile from '../components/profile';
// import help from '../components/help';
import { isAuthenticated } from '../services/auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    ))}
  />
);

const routes = () => (
  <HashRouter basename="/">
    <PrivateRoute path="/app" exact component={main.Main} />
    <PrivateRoute path="/" exact component={main.Main} />
    <PrivateRoute path="/cotacoes" exact component={quotes.QuoteIndex} />
    <PrivateRoute path="/produtos-treinamentos" exact component={products.ProductsAndTraingIndex} />
    <PrivateRoute path="/produtos-treinamentos/:id" exact component={productsDetails.Details} />
    <PrivateRoute path="/marketing" exact component={marketing.MarketingIndex} />
    <PrivateRoute path="/marketing/:id" exact component={marketingDetails} />
    <PrivateRoute path="/licencas" exact component={sellers.SellersIndex} />
    <PrivateRoute path="/licenca/informacoes" exact component={(licenseInformation.LicenseInformationIndex)} />
    <PrivateRoute path="/licenca/editar" exact component={editLicense.EditLicenseIndex} />
    <PrivateRoute path="/painel-financeiro" exact component={financial.DashboardFinancial} />
    <PrivateRoute path="/saques" exact component={withdraw.WithdrawIndex} />
    <PrivateRoute path="/relatorio-comissao" exact component={comissionReport.ComissionReportIndex} />
    <PrivateRoute
      path="/configuracao-perfil"
      exact
      component={profileSettings.ProfileSettingsIndex}
    />
    {/* signature */}
    {/* <PrivateRoute path="/assinatura" exact component={signature.SignatureIndex} />
    <PrivateRoute path="/assinatura/:id/funcionarios" exact component={signature.WorkersIndex} />
    <PrivateRoute path="/assinatura/:id/mensalidades" exact component={signature.InvoicesIndex} />
    <PrivateRoute path="/assinatura/:id/cobertura" exact component={signature.AbrangenceIndex} />
    <PrivateRoute path="/consulta" exact component={consultation.ConsultationIndex} />
    <PrivateRoute path="/medics" exact component={medics.MedicIndex} />
    <PrivateRoute path="/acesso/perfis" exact component={profile.ProfileIndex} />
    <PrivateRoute path="/contato/duvidas-frequentes" exact component={help.HelpIndex} /> */}

    <PrivateRoute path="/404" exact component={NotFound.Error404} />
    <PrivateRoute path="/erro/:str?" exact component={err.ErrorDefault} />
  </HashRouter>
);

PrivateRoute.propTypes = {
  component: PropTypes.objectOf({}).isRequired,
};

export default routes;
