/* eslint-disable react/prop-types */
import React from 'react';
import InputMask from 'react-input-mask';

function ContactGroup(props) {
  const { form, onChangeForm } = props;

  return (
    <>
      <p className="bg-color-8 p-1 text-bold text-4 mb-0 ">Contato</p>
      <div className="group mb-4 pt-4 d-flex flex-column">
        <div className="form-group">
          <label htmlFor="email" className="label">
            Email
          </label>
          <input
            type="text"
            className="form-control input"
            placeholder="Digite o seu email"
            id="email"
            onChange={(e) => onChangeForm('email', e.target.value)}
            value={form.email}
          />
        </div>
        <div className="input-container">
          <div>
            <label htmlFor="phone" className="label">
              Telefone
            </label>
            <InputMask
              mask="(99) 99999-9999"
              type="text"
              className="form-control input"
              placeholder="Digite o seu telefone"
              id="phone"
              onChange={(e) => onChangeForm('telefone', e.target.value)}
              value={form.telefone}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactGroup;
