/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Spin,
  ConfigProvider,
  Tooltip,
  Table,
  Pagination,
} from 'antd';
import moment from 'moment';
import locale from 'antd/lib/locale/pt_BR';

// import api from '../../services/api';
import actionModal from '../../actions/actionModal';
import actionErrors from '../../actions/actionErrors';
import actions from '../../actions/actionWithdraw';

import helpersFormat from '../../helpers/format';
import { formatReal } from '../../helpers/all';
import { pagination } from '../../config';
import ModalSendEmailRequestWithdraw from './components/ModalSendEmailRequestWithdraw';

moment.locale('pt-br');

function WithdrawIndex() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const all = useSelector((state) => state.reducerWithdraw.all);
  const modalSendEmailRequestWithdraw = useSelector((state) => state.reducerModal.modalSendEmailRequestWithdraw);
  const dash = useSelector((state) => state.reducerFinancial.dash);
  const producao = dash?.producao?.adesao.totais.totalComission;
  const br5Licencas = dash?.br5Licencas?.adesao.totais.totalComission;
  const topSeguros = dash?.topSeguros?.pagamentos.totais.totalComission;
  const br5Financeira = dash?.br5Financeira?.pagamentos.totais.totalComission;
  const total = +producao + +br5Licencas + +topSeguros + +br5Financeira;
  const populate = async (page = 1) => {
    try {
      setLoading(true);
      await dispatch(actions.getAll({ ...pagination, page }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  };

  useEffect(() => {
    // populate();

    // return () => {
    //   setLoading(false);
    // };
  }, []);

  const handleShow = (event) => {
    event.preventDefault();
    dispatch(actionModal.modalSendEmailRequestWithdraw(true));
  };

  const columns = [
    {
      title: 'Vencimento',
      key: 'vencimento',
      dataIndex: 'vencimento',
    },
    {
      title: 'Valor',
      key: 'valor',
      render: (text, record) => (
        <div>{ helpersFormat.formatReal(record.valor) }</div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (text, record) => {
        const item = record?.plano?.situacao || '--';
        return (
          <div>{ item }</div>
        );
      },
    },
    {
      title: 'Solicitado em',
      key: 'createdAt',
      render: (text, record) => (<>{helpersFormat.formatDate(record.createdAt)}</>),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (text, record) => (
        <div>
          <Tooltip title="Ver detalhes do cliente" className="mr-2 text-dark">
            <a href="#!" onClick={(event) => handleShow(event, record.id)}>
              <i className="fa fa-search text-4" />
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleEmailLinkClick = () => {
    const email = 'financeiro@br5beneficios.com.br';
    const cc = 'gerenteoperacional@br5beneficios.com.br';
    const subject = 'Solicitação de Saque da NF:';
    const message = 'Olá, gostaria de solicitar um saque da NF anexa!';

    const mailtoLink = `mailto:${email}?cc=${cc}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Spin spinning={loading}>
      <div className="shadow-one p-md-5 p-2 mb-5">

        <div className="col-md-12 col-sm-12">
          <div className="box-title d-flex text-left mb-4">

            <div className="col-md-8 col-xs-12">
              <i className="mr-2 fa fa-file-signature color-1" />
              <span className="title-2 color-2">Suas solicitações de saque</span>
              <span className="text-2 color-4 d-block">
                Lista das solicitações.
                {' '}
                <b>
                  Total de registros
                  {' '}
                  {all.totalCount}
                </b>
              </span>

              <span className="text-2 color-4 d-block">
                Total disponivel:
                {' '}
                <b>
                  {' '}
                  {formatReal(total)}
                </b>
              </span>
            </div>

            <div className="col-md-4 col-xs-12 mt-2 mb-3 text-center">
              <a
                onClick={handleEmailLinkClick}
                href="!#"
              >
                <span className="text-bold btn-sm bg-color-success title-shadow-one link-one link-hover-success mt-3">
                  <i className="fa fa-copy mr-2" />
                  <b>Solicitar Saque</b>
                </span>
              </a>
              <h6>Obs: Anexe a nota fiscal ao solicitar!</h6>
            </div>

          </div>
          <div className="d-flex flex-column align-items-center text-center">
            <h2 className="d-flex text-center mb-3">Em construção</h2>
            <img alt="" src="images/construcao.png" className="size-icon-2" />
          </div>
          {/* <div className="row mt-2">
            <ConfigProvider locale={locale}>
              <Table columns={columns} dataSource={all.items} pagination={false} className="col-12 table-responsive" />
            </ConfigProvider>
            <div className="text-center w-100 mt-5">
              <Pagination
                defaultCurrent={all.page}
                pageSize={5}
                total={all.totalCount}
                onChange={populate}
              />
            </div>
          </div> */}
        </div>

      </div>
      { modalSendEmailRequestWithdraw ? <ModalSendEmailRequestWithdraw /> : null}

    </Spin>
  );
}

export default WithdrawIndex;
