import React, { useState, useEffect } from 'react';
import {
  Table,
  Pagination,
  Modal,
  Spin,
  Tooltip,
  ConfigProvider,
  Button,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import locale from 'antd/lib/locale/pt_BR';
import moment from 'moment';

import actionModal from '../../../actions/actionModal';
import actionUserLogged from '../../../actions/actionUserLogged';
import actionErrors from '../../../actions/actionErrors';
import actions from '../../../actions/actionQuotation';
import helpersFormat from '../../../helpers/format';
import { pagination, typesSellers } from '../../../config';

moment.locale('pt-br');

function ModalBr5LicencasListQuotations() {
  const dispatch = useDispatch();
  const [form, setForm] = useState({});

  const modal = useSelector((state) => state.reducerModal.modalBr5LicencasListQuotations);
  const all = useSelector((state) => state.reducerQuotation.allBr5Licencas);
  const [loading, setLoading] = useState(false);

  const onChange = (prop, value) => {
    form[prop] = value;
    setForm({ ...form });
  };

  const populate = async (page) => {
    try {
      setLoading(true);
      const data = { ...pagination, ...form, page };
      await dispatch(actions.getAllBr5Licencas(data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  };

  useEffect(() => {
    populate();
  }, []);

  const handleShow = (record) => {
    dispatch(actionModal.modalUserProfile(true));
    dispatch(actionUserLogged.setUserProfile({ user: record }));
    // dispatch(actionSellers.getAll({ page: pageClicked }));
  };

  // const status = {
  //   emAndamento: 'Em Andamento',
  //   clicado: 'Clicado',
  // };
  const columns = [
    {
      title: 'Licença',
      key: 'nome',
      dataIndex: 'nome',
    },
    {
      title: 'Tipo',
      key: 'vendedor',
      render: (text, record) => {
        const type = record?.vendedor?.tipo;
        const item = type ? typesSellers[type] : '--';
        return (
          <div>{ item }</div>
        );
      },
    },
    {
      title: 'Doc',
      key: 'doc',
      dataIndex: 'doc',
    },
    {
      title: 'Status Pagamento',
      key: 'status',
      render: (text, record) => {
        const item = record?.plano?.situacao || '--';
        return (
          <div>{ item }</div>
        );
      },
    },
    {
      title: 'Criado em',
      key: 'createdAt',
      render: (text, record) => (<>{helpersFormat.formatDate(record.createdAt)}</>),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (text, record) => (
        <div>
          <Tooltip title="Ver detalhes do cliente" className="mr-2 text-dark">
            <Button type="text" onClick={() => handleShow(record)}>
              <i className="fa fa-search text-4" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Modal
      width="auto"
      visible={modal}
      footer={null}
      onCancel={() => dispatch(actionModal.modalBr5LicencasListQuotations(false))}
    >
      <Spin spinning={loading}>
        <div className="p-md-4 p-0">
          <div className="col-md-12 col-sm-12">
            <div className="box-title text-left mb-4">
              <i className="mr-2 fa fa-plus color-br5-1" />
              <span className="title-2 color-2">Cotações realizadas na BR5 Licenças</span>
              <span className="text-2 color-4 d-block">
                Lista das cotações.
                {' '}
                <b>
                  Total de registros
                  {' '}
                  {all.totalCount}
                </b>
              </span>
            </div>

            <div className="row pb-3">
              <div className="form-group col-4">
                <span className="font-weight-bold mb-2">Nome:</span>
                <input
                  type="text"
                  className="form-control"
                  value={form?.nome || ''}
                  onChange={(event) => onChange('nome', event.target.value)}
                />
              </div>
              <div className="form-group col-4">
                <span className="font-weight-bold mb-2">Documento:</span>
                <input
                  type="text"
                  className="form-control"
                  value={form?.doc || ''}
                  onChange={(event) => onChange('doc', event.target.value)}
                />
              </div>
              <div className="col-12">
                <Button type="primary" onClick={() => populate(1)}>
                  Pesquisar
                  <i className="fa fa-search ml-2" />
                </Button>
              </div>
            </div>

            <ConfigProvider locale={locale}>
              <Table columns={columns} dataSource={all.items} pagination={false} className="col-12 table-responsive" />
            </ConfigProvider>
            <div className="text-center w-100 mt-5">
              <Pagination
                defaultCurrent={all.page}
                pageSize={5}
                total={all.totalCount}
                onChange={populate}
              />
            </div>
          </div>

        </div>
      </Spin>
    </Modal>
  );
}

export default ModalBr5LicencasListQuotations;
