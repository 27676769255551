import actionsTypes from './actionsTypes';
import products from '../data/productsAndTraining.json';

const actions = {
  getAll: () => ({
    type: actionsTypes.PRODUCTS_AND_TRAINING_GET_ALL,
    payload: products,
  }),
};

export default actions;
