import actionsTypes from './actionsTypes';
import api from '../services/api';

const actions = {
  getComissions: (payload) => ({
    type: actionsTypes.GET_COMISSIONS,
    payload: api.get('api/v1/relatorio/br5turbo-pendencia-status', {
      params: payload,
    }),
  }),
  getComissionsSpreadsheet: (payload) => ({
    type: actionsTypes.GET_COMISSIONS_SPREADSHEET,
    payload: api.get('api/v1/relatorio/relatorio-br5turbo-pendencia/planilha', {
      params: payload,
      responseType: 'blob',
    }),
  }),
};

export default actions;
