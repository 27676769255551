import actionsTypes from '../actions/actionsTypes';
import helpers from './helpers';

const INITIAL_STATE = {
  all: {},
  item: {},
  current: '',
  my: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.PLAN_GET:
      return helpers.mapAll(action.payload, state, INITIAL_STATE);

    case actionsTypes.PLAN_GET_MY_SUBSCRIPTIONS:
      return helpers.mapAll(action.payload, state, INITIAL_STATE, 'my');

    case actionsTypes.PLAN_GET_BY_ID:
      return helpers.mapItem(action.payload, state, INITIAL_STATE);

    case actionsTypes.PLAN_SET_CURRENT:
      return { ...state, current: action.payload };

      // case actionsTypes.PLAN_GET_MY_SUBSCRIPTIONS:
      //   return {
      //     ...state,
      //     my: action.payload
      //       && action.payload.data
      //       && action.payload.data
      //       ? action.payload.data
      //       : INITIAL_STATE.my,
      //   };

    default:
      return state;
  }
};

export default reducer;
