import React from 'react';

function LogoDefault() {
  return (
    <div className="sidebar-brand">
      <a href="/" className="logo-default">
        <img src="/images/logo-2.png" alt="" className="img-fluid" />
      </a>
      <div id="close-sidebar">
        <i className="fas fa-times" />
      </div>
    </div>
  );
}

export default LogoDefault;
