import React from 'react';

function DashboardDefault() {
  return (
    <>
      <div className="shadow-one p-md-5 p-2 mb-5">
        <div className="col-md-12 col-sm-12">
          <div className="box-title text-left mb-3">
            <i className="mr-2 fa fa-tachometer-alt color-1" />
            <span className="title-2 color-2">Dashboard Default</span>
            <span className="text-2 color-4 d-block">Seja bem vindo ao seu painel de controle</span>
          </div>
        </div>
      </div>

    </>
  );
}

export default DashboardDefault;
