/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from '../../../actions/actionFirstAccess';

import helpers from '../../../helpers/all';

const PIN_LENGHT = 6;

function Step3Code() {
  const [pinCode, setPinCode] = useState(
    new Array(PIN_LENGHT).fill(''),
  );

  const inputRef = useRef([]);
  const dispatch = useDispatch();

  const form = useSelector((state) => state.reducerFirstAccess.form);
  const item = useSelector((state) => state.reducerFirstAccess.item);

  const onChange = (prop, value) => {
    form[prop] = value;
    dispatch(actions.form(form));
  };

  const onPinChange = (pinEntry, index) => {
    const pinsCode = [...pinCode];
    pinsCode[index] = pinEntry ?? '';
    setPinCode(pinsCode);
  };

  function handleChangeInputFocus(pinCodeIndex) {
    const input = inputRef.current[pinCodeIndex];
    if (input) {
      input.focus();
    }
  }

  const handleOnKeyDown = (
    event,
    index,
  ) => {
    const keyboardKeyCode = event.nativeEvent.code;

    if (keyboardKeyCode !== 'Backspace') {
      return;
    } if (!pinCode[index]) {
      handleChangeInputFocus(index - 1);
      return;
    }
    onPinChange(undefined, index);
  };

  function handleOnChange(
    event,
    index,
  ) {
    const { value } = event.target;
    const pinCodeNumber = value.trim();

    if (!value.length) {
      return;
    }
    if (Number(pinCodeNumber) > 9) return;

    onPinChange(pinCodeNumber, index);

    if (index < PIN_LENGHT - 1) {
      handleChangeInputFocus(index + 1);
    }
  }

  useEffect(() => {
    if (pinCode[PIN_LENGHT - 1] !== '') {
      onChange('code', pinCode.join(''));
    }
  }, [pinCode]);

  return (
    <>
      <p className="title-2 text-center mt-3">
        Por favor, digite o código que enviamos agora para:
        {' '}
        {form.loginContact?.type === 'cellPhone' ? helpers.formatPhone(form.loginContact?.value) : form.loginContact?.value}
      </p>
      <p className="text-4 text-center mb-2">Digite o código aqui</p>
      <form>
        <div className="pin-code-container">
          {pinCode.map((pin, index) => (
            <input
              type="text"
              ref={(element) => {
                inputRef.current[index] = element || undefined;
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onChange={(event) => handleOnChange(event, index)}
              onKeyDown={(event) => handleOnKeyDown(event, index)}
              value={pinCode[index]}
              className="input-pin-code"
              placeholder="0"
            />
          ))}
        </div>
      </form>
    </>
  );
}

export default Step3Code;
