import actionsTypes from './actionsTypes';
import checkoutAPI from '../services/checkoutApi';

const actions = {
  get: () => ({
    type: actionsTypes.USER_DATA_GET,
    payload: {},
  }),
  set: (data) => ({
    type: actionsTypes.USER_DATA_SET,
    payload: data,
  }),
  setUserProfile: (data) => ({
    type: actionsTypes.USER_PROFILE,
    payload: data,
  }),
  getUserContracts: (data) => ({
    type: actionsTypes.GET_USER_CONTRACTS,
    payload: checkoutAPI.get(`api/contract/email/${data}`),
  }),
};

export default actions;
