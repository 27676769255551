/* eslint-disable radix */
const { getDataUser } = require('../services/auth');

const formatReal = (val = 0) => {
  const number = val || 0;
  return `R$ ${number.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const isNumeric = (str) => /^-?\d+$/.test(str);

const isCellPhone = (cel) => {
  if (!cel) { return null; }
  let ncel = cel.toString();
  ncel = ncel.replace(/\D/g, '');
  const r = ncel.match(/^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[6-9])\d{3})-?(\d{4}))$/);
  if (!r || !r.length || r.length < 1) { return null; }
  return r[0];
};

function isEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
// const isCellPhone = (cell) => cell.match(/\d/g).length === 10;

const isCNPJ = (c) => {
  if (!c) return false;

  const cnpj = c.toString().replace(/[^\d]+/g, '');

  if (!cnpj || cnpj.length !== 14) return false;
  if (
    cnpj === '00000000000000'
    || cnpj === '11111111111111'
    || cnpj === '22222222222222'
    || cnpj === '33333333333333'
    || cnpj === '44444444444444'
    || cnpj === '55555555555555'
    || cnpj === '66666666666666'
    || cnpj === '77777777777777'
    || cnpj === '88888888888888'
    || cnpj === '99999999999999'
  ) {
    return false;
  }

  let soma = 0;
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let pos = tamanho - 7;

  // eslint-disable-next-line
  for (let i = tamanho; i >= 1; i--) {
    // eslint-disable-next-line
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  // eslint-disable-next-line
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  // eslint-disable-next-line
  if (resultado != digitos.charAt(0)) return false;

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  // eslint-disable-next-line
  for (let i = tamanho; i >= 1; i--) {
    // eslint-disable-next-line
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  // eslint-disable-next-line
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  // eslint-disable-next-line
  if (resultado != digitos.charAt(1)) return false; // LINHA 49

  return true; // LINHA 51
};

const isCPF = (cpf) => {
  if (!cpf) {
    return null;
  }
  // eslint-disable-next-line no-param-reassign
  cpf = cpf.replace(/\D/g, '');
  let soma = 0;
  let resto;

  if (cpf.length !== 11) {
    return false;
  }

  if (
    cpf === '00000000000'
    || cpf === '11111111111'
    || cpf === '22222222222'
    || cpf === '33333333333'
    || cpf === '44444444444'
    || cpf === '55555555555'
    || cpf === '66666666666'
    || cpf === '77777777777'
    || cpf === '88888888888'
    || cpf === '99999999999'
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    soma += parseInt(cpf.substring(i - 1, i), 0) * (11 - i);
  }

  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10), 0)) return false;

  soma = 0;

  for (let i = 1; i <= 10; i += 1) {
    soma += parseInt(cpf.substring(i - 1, i), 0) * (12 - i);
  }

  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11), 0)) return false;

  return true;
};

const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

function inArray(needle, haystack) {
  if (!Array.isArray(haystack)) return false;
  for (let i = 0; i < haystack.length; i += 1) {
    if (haystack[i] === needle) return true;
  }
  return false;
}

function checkPermission(module, action) {
  const { activeProfile } = getDataUser();
  if (!activeProfile.permissions) return false;

  const { permissions } = activeProfile;

  const result = permissions.find((permission) => {
    if (permission.module.identifier !== module) return false;
    if (permission.action.identifier !== action) return false;
    return true;
  });

  return !!result;
}

const months = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
};

function formatDates(dates) {
  // Extrai as duas datas do array
  const date1 = new Date(dates[0]);
  const date2 = new Date(dates[1]);

  // Formata as datas para o padrão DD/MM/YYYY
  const dateFormat1 = `${date1.getDate().toString().padStart(2, '0')}/${(date1.getMonth() + 1).toString().padStart(2, '0')}/${date1.getFullYear()}`;
  const dateFormat2 = `${date2.getDate().toString().padStart(2, '0')}/${(date2.getMonth() + 1).toString().padStart(2, '0')}/${date2.getFullYear()}`;

  // Retorna a string com as datas formatadas separadas por "-"
  return `${dateFormat1} - ${dateFormat2}`;
}

function validatePassword(password) {
  if (!password) {
    return false;
  }

  const isContainsLetter = /^(?=.*[A-Za-z])/;
  if (!isContainsLetter.test(password)) {
    return false;
  }

  const isContainsNumber = /^(?=.*[0-9])/;
  if (!isContainsNumber.test(password)) {
    return false;
  }

  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹])/;
  if (!isContainsSymbol.test(password)) {
    return false;
  }

  const isValidLength = /^.{6,}$/;
  if (!isValidLength.test(password)) {
    return false;
  }

  return true;
}

function formatPhone(phoneNumber) {
  if (!phoneNumber) {
    return '--';
  }

  const preFormattedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
  const formatPhoneNumberLength = preFormattedPhoneNumber.length;
  const phoneNumberLength = phoneNumber.length;
  let formattedPhoneNumber = phoneNumber;

  if (formatPhoneNumberLength <= 3) {
    formattedPhoneNumber = preFormattedPhoneNumber.replace(
      /(\d{2})(\d{1})/g,
      '($1) $2',
    );
  } else if (formatPhoneNumberLength <= 7) {
    formattedPhoneNumber = preFormattedPhoneNumber.replace(
      /(\d{2})(\d{1})(\d{1,4})/g,
      '($1) $2$3',
    );
  } else {
    formattedPhoneNumber = preFormattedPhoneNumber.replace(
      /(\d{2})(\d{1})(\d{4})(\d{1,4})/g,
      '($1) $2$3-$4',
    );
  }

  if (phoneNumberLength > 15) {
    formattedPhoneNumber = phoneNumber.substring(0, phoneNumberLength - 1);
  }

  return formattedPhoneNumber;
}

function formatName(name) {
  if (!name) {
    return '';
  }
  const nameInLowerCase = name.toLowerCase();
  const nameSplitted = nameInLowerCase.split(' ');
  const formattedName = nameSplitted.map((item) => item.charAt(0).toUpperCase() + item.slice(1)).join(' ');

  return formattedName;
}

function formatZipcode(zipcode) {
  if (!zipcode) {
    return '--';
  }
  const formatedZipcode = zipcode.replace(/\D/g, '');

  if (formatedZipcode.length <= 5) {
    return formatedZipcode.replace(/(\d{5})(\d{3})/, '$1');
  }

  return formatedZipcode.substring(0, 8).replace(/^(\d{5})(\d+)/, '$1-$2');
}

// format date to YYYY/MM/DD
function formatToUniversalDate(date) {
  const parsedDate = new Date(date);

  const year = parsedDate.getFullYear();
  const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
  const day = parsedDate.getDate().toString().padStart(2, '0');

  const transformedDate = `${year}-${month}-${day}`;
  return transformedDate;
}

module.exports = {
  isCNPJ,
  isCPF,
  isNumeric,
  formatReal,
  isCellPhone,
  isEmail,
  toBase64,
  inArray,
  checkPermission,
  months,
  formatDates,
  validatePassword,
  formatPhone,
  formatName,
  formatZipcode,
  formatToUniversalDate,
};
