/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';

import actionProfileSettings from '../../../actions/actionProfileSettings';

function UpdateAddressForm() {
  const [form, setForm] = useState({
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    zipCode: '',
    city: '',
    state: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.reducerSellers.item);
  const userId = useSelector((state) => state.reducerUserLogged.pessoa);

  useEffect(() => {
    setForm({
      rua: user?.rua,
      numero: user?.numero || '',
      complemento: user?.complemento || '',
      bairro: user?.bairro,
      cep: user?.cep,
      cidade: user?.cidade,
      estado: user?.estado,
    });
  }, [user]);

  const getAddressByZipCode = async (param = '') => {
    const zipCode = param.replace(/-/, '');

    if (!zipCode || !/^[0-9]{8}$/.test(zipCode)) return;

    try {
      setIsLoading(true);
      const { payload } = await dispatch(actionProfileSettings.getAddressByZipCode(zipCode));
      const { data } = payload.data;

      setForm((prevState) => ({
        ...prevState,
        rua: data?.street,
        bairro: data?.neighborhood,
        cep: data?.zipCode,
        cidade: data?.city,
        estado: data?.state,
        numero: '',
        complemento: '',
      }));
    } catch {
      message.error('Houve um problema ao obter os dados do endereço');
    } finally { setIsLoading(false); }
  };

  const onChangeForm = (prop, value) => {
    form[prop] = value;
    setForm({ ...form });

    if (prop === 'cep') {
      getAddressByZipCode(value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!form.rua || !form.bairro || !form.cep || !form.cidade || !form.estado) {
        throw new Error('Os campos: rua, bairro, CEP, cidade e estado são obrigatórios!');
      }

      await dispatch(actionProfileSettings.updateUser(userId, { endereco: form }));
      message.success('Endereço salvo com sucesso!');
    } catch (err) {
      message.error(err.message || 'Não foi possivel salvar as alterações!');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Spin spinning={isLoading}>
      <div className="mt-3">
        <h2 className="title">Endereço</h2>
        <form className="login-form mt-3 d-flex flex-column" onSubmit={(e) => onSubmit(e)}>
          <div className="input-container">
            <div className="mr-3">
              <label htmlFor="zipCode" className="label">
                CEP
              </label>
              <InputMask
                mask="99999-999"
                type="text"
                className="form-control input"
                placeholder="Digite o CEP"
                id="zipCode"
                onChange={(e) => onChangeForm('cep', e.target.value)}
                value={form.cep}
              />
            </div>
            <div className="mr-3">
              <label htmlFor="street" className="label">
                Rua
              </label>
              <input
                type="text"
                className="form-control input"
                placeholder="Digite a rua"
                id="street"
                onChange={(e) => onChangeForm('rua', e.target.value)}
                value={form.rua}
              />
            </div>
          </div>
          <div className="input-container">
            <div className="mr-3">
              <label htmlFor="number" className="label">
                Número
              </label>
              <input
                type="text"
                className="form-control input"
                placeholder="Digite o número"
                id="number"
                onChange={(e) => onChangeForm('numero', e.target.value)}
                value={form.numero}
              />
            </div>
            <div className="mr-3">
              <label htmlFor="complement" className="label">
                Complemento
              </label>
              <input
                type="text"
                className="form-control input"
                placeholder="Digite o complemento"
                id="complement"
                onChange={(e) => onChangeForm('complemento', e.target.value)}
                value={form.complemento}
              />
            </div>
            <div className="mr-3">
              <label htmlFor="neighborhood" className="label">
                Bairro
              </label>
              <input
                type="text"
                className="form-control input"
                placeholder="Digite o bairro"
                id="neighborhood"
                onChange={(e) => onChangeForm('bairro', e.target.value)}
                value={form.bairro}
              />
            </div>

          </div>

          <div className="input-container">
            <div className="mr-3">
              <label htmlFor="city" className="label">
                Cidade
              </label>
              <input
                type="text"
                className="form-control input"
                placeholder="Digite a cidade"
                id="city"
                onChange={(e) => onChangeForm('cidade', e.target.value)}
                value={form.cidade}
              />
            </div>

            <div>
              <label htmlFor="state" className="label">
                Estado
              </label>
              <input
                type="text"
                className="form-control input"
                placeholder="Digite o estado"
                id="state"
                onChange={(e) => onChangeForm('estado', e.target.value)}
                value={form.estado}
              />
            </div>
          </div>

          <button
            type="submit"
            className="bg-color-black color-white border-0 font-weight-bold p-2 align-self-end pointer"
          >
            Salvar
          </button>
        </form>
      </div>
    </Spin>
  );
}

export default UpdateAddressForm;
