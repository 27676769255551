import { Spin, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import action from '../../../actions/actionUserLogged';

import firstAccessAction from '../../../actions/actionFirstAccess';

function Contract() {
  const [contractData, setContractData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const userEmail = useSelector((state) => state.reducerSellers.item.email);
  const dispatch = useDispatch();

  const getUserContracts = async () => {
    try {
      const { payload } = await dispatch(action.getUserContracts(userEmail));
      const contracts = payload.data.data;

      if (contracts && contractData.length < contracts.length) {
        contracts.map(async (contract) => {
        // eslint-disable-next-line operator-linebreak
          const { payload: data } =
          await dispatch(firstAccessAction.getContractByID(contract.documentKey));
          setContractData((prevState) => [...prevState,
            {
              download: data.data.data.download,
              url: data.data.data.url,
              id: contract.id,
              status: data.data.data.status,
            },
          ]);
        });
      }
    } catch (err) {
      message.error(err.message || 'Não foi possível obter dados do contrato ');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(getUserContracts, [userEmail]);
  return (
    <Spin spinning={isLoading}>
      <div className="d-flex flex-column mt-5">
        {contractData.length === 0 && (
          <p>Não há contratos para exibir</p>
        )}
        {contractData?.map((contract) => (
          <div className="bg-color-7 py-2 px-3 mb-4 d-flex flex-column">
            <a href={contract.status === 'running' ? contract.url : contract.download} target="_blank" className="color-black text-underline mb-1 contract-url" key={contract.id} rel="noreferrer">
              {contract.status === 'running' ? contract.url : contract.download}
            </a>
            <a href={contract.download} target="_blank" className="color-black  title-link" rel="noreferrer">
              <i className="fa fa-solid fa-file-contract mr-2" />
              Contrato.pdf
            </a>
          </div>

        )) }
      </div>

    </Spin>
  );
}

export default Contract;
