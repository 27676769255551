const TOKEN_KEY = 'br5licencas-web-permission';
const DATA_USER_KEY = 'br5licencas-web-user';

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY);
// export const isAuthenticated = () => true; // so pra teste

export const getToken = () => {
  const dataPermission = localStorage.getItem(TOKEN_KEY);
  return dataPermission ? JSON.parse(dataPermission) : null;
};

export const login = (dataPermission) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(dataPermission));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(DATA_USER_KEY);
};

export const setDataUser = (data) => {
  localStorage.setItem(DATA_USER_KEY, JSON.stringify(data));
};

export const getDataUser = () => {
  const data = localStorage.getItem(DATA_USER_KEY);
  return data ? JSON.parse(data) : null;
};
