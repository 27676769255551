import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.USER_DATA_GET:
      return state;

    case actionsTypes.USER_DATA_SET:
      return { ...state, ...action.payload };

    case actionsTypes.USER_PROFILE:
      return { ...state, ...action.payload };

    case actionsTypes.GET_USER_CONTRACTS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default reducer;
