import actionsTypes from './actionsTypes';
import api from '../services/api';

const actions = {
  totalLifesPlan: (subscriptionId = '') => ({
    type: actionsTypes.TOTAL_LIFES_PLAN,
    payload: api.get(`/api/subscription/dashboard/employee/total/${subscriptionId}`),
  }),
  latestInvoice: () => ({
    type: actionsTypes.LATEST_INVOICE,
    payload: api.get('/api/financial/invoice/dashboard/latest'),
  }),
  dashboardWorkerInfo: () => ({
    type: actionsTypes.WORKER_INFO,
    payload: api.get('/api/subscription/dashboard/employee/info'),
  }),
};

export default actions;
