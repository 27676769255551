/* eslint-disable react/self-closing-comp */
import React from 'react';
import './whatsappbutton.css';
import { BsWhatsapp } from 'react-icons/bs';

function WhatsappButton() {
  const phoneNumber = '5565992263859'; // Número de telefone para o qual você deseja enviar uma mensagem
  const message = 'Olá, estou entrando em contato para retirar dúvidas sobre a BR5 Turbo'; // Mensagem predefinida
  return (
    <>
      <a
        href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="wrapper-whatsapp"
      >
        <BsWhatsapp style={{ marginTop: 0, fontSize: 28 }} />
        <span className="span-whatsapp">Dúvidas? Contate-nos</span>
      </a>
    </>
  );
}

export default WhatsappButton;
