import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  Modal,
  Spin,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import actionModal from '../../../actions/actionModal';
import actionErrors from '../../../actions/actionErrors';
import actionPlan from '../../../actions/actionPlan';
import {
  urlIndicationCheckout, urlIndicationSite, envRedirectBr5, empresas,
} from '../../../config';
import { formatReal } from '../../../helpers/all';
import actionQuotation from '../../../actions/actionQuotation';

function ModalBr5Quotation() {
  const dispatch = useDispatch();

  const { br5 } = empresas;
  const modal = useSelector((state) => state.reducerModal.modalBr5ChoosePlan);
  const all = useSelector((state) => state.reducerPlan.all);
  const userData = useSelector((state) => state.reducerUserLogged);
  const [loading, setLoading] = useState(false);
  const [urls, setUrls] = useState({ checkout: '', site: '' });
  const [defaultValue, setDefaultValue] = useState();

  const mountOptionsSelect = () => {
    if (!all || !all.items || !all.items.length) {
      return [{ label: 'Nada encontrado' }];
    }

    return all.items.map((item) => ({
      value: item.id,
      label: item.nome,
      identificador: item.identificador,
    }));
  };

  const populate = async () => {
    try {
      setLoading(true);
      await dispatch(actionPlan.getAll());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  };

  useEffect(() => {
    populate();
  }, []);

  useEffect(() => {
    if (!all || !all.items || !all.items.length) {
      return;
    }
    setUrls({
      checkout: `${urlIndicationCheckout}/${userData.pessoa}?plan=${all.items[0].identificador}&env=${envRedirectBr5 || ''}`,
      site: `${urlIndicationSite}/${userData.pessoa}?plan=${all.items[0].identificador}`,
    });
    setDefaultValue({
      identificador: all.items[0].identificador,
      value: all.items[0].id,
      label: all.items[0].nome,
    });
  }, [all.items]);

  const onChange = (item) => {
    setDefaultValue({
      identificador: item.identificador,
      value: item.value,
      label: item.label,
    });
    setUrls({
      checkout: `${urlIndicationCheckout}/${userData.pessoa}?plan=${item.identificador}&env=${envRedirectBr5 || ''}`,
      site: `${urlIndicationSite}/${userData.pessoa}?plan=${item.identificador}`,
    });
  };

  return (
    <Modal
      width={window.innerWidth > 960 ? 800 : 'auto'}
      visible={modal}
      footer={null}
      onCancel={() => dispatch(actionModal.modalBr5ChoosePlan(false))}
    >
      <Spin spinning={loading}>
        <div className="p-md-4 p-0">
          <div className="col-md-12 col-sm-12">
            <div className="box-title text-left mb-4">
              <i className="mr-2 fa fa-plus color-br5-1" />
              <span className="title-2 color-2">Qual plano deseja indicar?</span>
              <span className="text-2 color-4 d-block">Escolha o plano a ser indicado.</span>
            </div>
            <div className="row">
              <div className="form-group col-12">
                <span className="font-weight-bold">Plano</span>
                <Select
                  options={mountOptionsSelect()}
                  onChange={onChange}
                  value={defaultValue}
                />
              </div>

              <div className="col-md-6 col-12 mb-5 yr text-center">
                <span className="title-3 text-bold mb-4 d-block">Site de Vendas</span>
                <div className="btn-group pb-3" role="group" aria-label="Basic example">
                  <a href={urls.site} target="_blank" className="btn btn-primary yr" rel="noreferrer">
                    <i className="fas fa-external-link-alt mr-2" />
                    Cotar Agora
                  </a>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(actionQuotation.dataShare({
                        url: urls.site,
                        text: br5.messageWhatsapp1,
                      }));
                      dispatch(actionModal.modalShareSocial(true));
                    }}
                  >
                    <i className="fas fa-share mr-2" />
                    Compartilhar
                  </button>
                </div>
              </div>
              <div className="col-md-6 col-12 mb-5 yr text-center">
                <span className="title-3 text-bold mb-4 d-block">Compra Direta</span>
                <div className="btn-group pb-3" role="group" aria-label="Basic example">
                  <a href={urls.checkout} target="_blank" className="btn btn-primary yr" rel="noreferrer">
                    <i className="fas fa-external-link-alt mr-2" />
                    Cotar Agora
                  </a>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(actionQuotation.dataShare({
                        url: urls.checkout,
                        text: br5.messageWhatsapp1,
                      }));
                      dispatch(actionModal.modalShareSocial(true));
                    }}
                  >
                    <i className="fas fa-share mr-2" />
                    Compartilhar
                  </button>
                </div>
              </div>
            </div>
            {/* <form className="row">
              <div className="form-group col-12">
                <span className="font-weight-bold">Plano</span>
                <Select
                  options={mountOptionsSelect()}
                  onChange={onChange}
                  value={defaultValue}
                />
              </div>

              <div className="col-md-6 col-xs-12 mb-3 text-center">
                <CopyToClipboard
                  text={urls.site}
                  onCopy={() => message.success({ content: 'Copiado com sucesso!' })}
                >
                  <span className="text-bold btn-sm bg-color-br5-1
                  title-shadow-one link-one link-hover-br5-1 mt-3">
                    {/* <span>{ urls.site }</span> *
                    <i className="fa fa-copy mr-2" />
                    <b>Link Site de Vendas </b>
                  </span>
                </CopyToClipboard>
              </div>
              <div className="col-md-6 col-xs-12 mb-3 text-center">
                <CopyToClipboard
                  text={urls.checkout}
                  onCopy={() => message.success({ content: 'Copiado com sucesso!' })}
                >
                  <span className="text-bold btn-sm bg-color-br5-1
                  title-shadow-one  link-one link-hover-br5-1 mt-3">
                    {/* <span>{ urls.checkout }</span> *
                    <i className="fa fa-copy mr-2 ml-2" />
                    <b>Link Compra Direta </b>
                  </span>
                </CopyToClipboard>
              </div>
            </form> */}
          </div>
          <div className="col-12">
            <h2 className="title-3 color-2 mb-3 d-block mt-1">Lista de Planos Disponíveis</h2>
            <div className="row">

              {
                all.items && all.items.length
                  ? all.items.map((item) => (
                    <div className="col-md-6 col-xs-12 mb-3">
                      <div className="card">
                        <h5 className="card-header text-3 text-bold bg-color-br5-1 text-white">{item.nome}</h5>
                        <div className="card-body">
                          <h5 className="card-title box-title">
                            <span className="text-2">Valor da Adesão:</span>
                            {' '}
                            <b>{formatReal(item.valorAdesao)}</b>
                          </h5>
                          <h5 className="card-title box-title">
                            <span className="text-2">Valor Mensal:</span>
                            {' '}
                            <b>{formatReal(item.valorMensal)}</b>
                          </h5>
                          <h5 className="card-title box-title">
                            <span className="text-2">Valor por agregado:</span>
                            {' '}
                            <b>{formatReal(item.valorAgregado)}</b>
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))
                  : <div className="col-12"><div className="alert alert-dark">Sem dados</div></div>
              }

            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default ModalBr5Quotation;
