import actionsTypes from './actionsTypes';
import marketing from '../data/marketing.json';

const actions = {
  getAll: () => ({
    type: actionsTypes.MARKETING_GET_ALL,
    payload: marketing,
  }),
};

export default actions;
