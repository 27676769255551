import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import actionProductsAndTraining from '../../actions/actionProductsAndTraining';

function ProductsAndTraingIndex() {
  const dispatch = useDispatch();

  const allItems = useSelector((state) => state.reducerProductsAndTraining.all);

  useEffect(() => {
    dispatch(actionProductsAndTraining.getAll());
  }, []);

  const styles = {
    height: '60px',
  };

  return (
    <div className="shadow-one p-md-5 p-2 mb-3">
      <div className="col-md-12 col-sm-12">
        <div className="box-title text-left mb-4">
          <div className="w-100 float-left">
            <span className="title-5 color-2">Produtos e Treinamentos</span>
          </div>
        </div>
        <div className="d-flex justify-content-center row mt-3">
          {
            allItems && allItems.map((item) => (
              <div className="col-md-6 col-xs-12 mb-5 text-center">
                <div className="p-4 w-100 h-100 card-custom shadow-one mr-4 ">
                  <div style={styles} className="text-center d-block mb-3 mt-4">
                    <img alt="" src={item.icon} className="size-icon-2 img-fluid" loading="lazy" />
                  </div>
                  <h4 className="title-2 mb-4">{ item.title }</h4>
                  <div className="mb-3 mt-4 container-img">
                    <img className="img-fluid" alt="" src={item.image} loading="lazy" />
                  </div>
                  <p className="text-2 text-justify">
                    { item.description }
                  </p>
                  <Link
                    to={`/produtos-treinamentos/${item.id}`}
                    className="btn btn-link bg-white text-dark mt-4 text-center"
                  >
                    Ver opções
                    <i className="fa fa-arrow-right ml-2" />

                  </Link>
                </div>
              </div>
            ))
          }

        </div>
      </div>
    </div>
  );
}

// ProductsAndTraingIndex.propTypes = {
//   match: PropTypes.objectOf({}).isRequired,
// };

export default ProductsAndTraingIndex;
