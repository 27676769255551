import actionsTypes from './actionsTypes';
import api from '../services/api';
import checkoutAPI from '../services/checkoutApi';

const actions = {
  step1Document: (params) => ({
    type: actionsTypes.FIRST_ACCESS_STEP_1,
    payload: api.post('confirmacao/br5-turbo/doc', params),
  }),
  step2Code: (params) => ({
    type: actionsTypes.FIRST_ACCESS_STEP_2,
    payload: api.post('confirmacao/br5-turbo/codigo', params),
  }),
  step3Confirm: (params) => ({
    type: actionsTypes.FIRST_ACCESS_STEP_3,
    payload: api.post('confirmacao/br5-turbo/confirmar-codigo', params),
  }),
  step4Store: (params) => ({
    type: actionsTypes.FIRST_ACCESS_STEP_4,
    payload: api.post('confirmacao/br5-turbo/salvar-usuario', params),
  }),
  setContractUrl: async (params) => ({
    type: actionsTypes.SET_CONTRACT_URL,
    payload: await checkoutAPI.post('api/contract', params),
  }),
  getContractByID: (params) => ({
    type: actionsTypes.GET_CONTRACT_BY_ID,
    payload: checkoutAPI.get(`api/contract/${params}`),
  }),
  getStep: () => ({
    type: actionsTypes.FIRST_ACCESS_GET_STEP,
    payload: {},
  }),
  setStep: (num) => ({
    type: actionsTypes.FIRST_ACCESS_SET_STEP,
    payload: num,
  }),
  form: (payload = {}) => ({
    type: actionsTypes.FIRST_ACCESS_FORM,
    payload: { ...payload },
  }),
};

export default actions;
