/* eslint-disable react/prop-types */
import React from 'react';
import InputMask from 'react-input-mask';

function AddressGroup(props) {
  const { form, onChangeForm } = props;

  return (
    <>
      <p className="bg-color-8 p-1 text-bold text-4 mb-0">Endereço</p>
      <div className="group mb-4 d-flex flex-column">
        <div className="input-container pt-4">
          <div className="mr-3">
            <label htmlFor="zipCode" className="label">
              CEP
            </label>
            <InputMask
              mask="99999-999"
              type="text"
              className="form-control input"
              placeholder="Digite o CEP"
              id="zipCode"
              onChange={(e) => onChangeForm('cep', e.target.value)}
              value={form.endereco?.cep}
            />
          </div>
          <div>
            <label htmlFor="street" className="label">
              Rua
            </label>
            <input
              type="text"
              className="form-control input"
              placeholder="Digite a rua"
              id="street"
              onChange={(e) => onChangeForm('rua', e.target.value)}
              value={form.endereco?.rua}
            />
          </div>
        </div>

        <div className="input-container mt-4">
          <div className="mr-3">
            <label htmlFor="number" className="label">
              Número
            </label>
            <input
              type="text"
              className="form-control input"
              placeholder="Digite o número"
              id="number"
              onChange={(e) => onChangeForm('numero', e.target.value)}
              value={form.endereco?.numero}
            />
          </div>
          <div className="mr-3">
            <label htmlFor="complement" className="label">
              Complemento
            </label>
            <input
              type="text"
              className="form-control input"
              placeholder="Digite o complemento"
              id="complement"
              onChange={(e) => onChangeForm('complemento', e.target.value)}
              value={form.endereco?.complemento}
            />
          </div>
          <div>
            <label htmlFor="neighborhood" className="label">
              Bairro
            </label>
            <input
              type="text"
              className="form-control input"
              placeholder="Digite o bairro"
              id="neighborhood"
              onChange={(e) => onChangeForm('bairro', e.target.value)}
              value={form.endereco?.bairro}
            />
          </div>
        </div>

        <div className="input-container mt-4">
          <div className="mr-3">
            <label htmlFor="city" className="label">
              Cidade
            </label>
            <input
              type="text"
              className="form-control input"
              placeholder="Digite a cidade"
              id="city"
              onChange={(e) => onChangeForm('cidade', e.target.value)}
              value={form.endereco?.cidade}
            />
          </div>
          <div>
            <label htmlFor="state" className="label">
              Estado
            </label>
            <input
              type="text"
              className="form-control input"
              placeholder="Digite o estado"
              id="state"
              onChange={(e) => onChangeForm('estado', e.target.value)}
              value={form.endereco?.estado}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddressGroup;
