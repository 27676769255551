import moment from 'moment';

const formatHelper = {
  formatDate: (date, format = 'DD/MM/YYYY') => {
    if (!date) return '';
    return moment(date).utcOffset(0).format(format);
  },
  formatReal: (number = 0) => `R$ ${number.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`,
};

export default formatHelper;
