import React, {
  useEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Pagination, Spin, Table, Tooltip,
} from 'antd';
import { useHistory } from 'react-router-dom';

import actionModal from '../../actions/actionModal';
import actionErrors from '../../actions/actionErrors';
import actionSellers from '../../actions/actionSellers';

import { typesSellers } from '../../config';
import helpersAll from '../../helpers/all';

import SearchFilter from './components/SearchFilter';
import { STATUS_FILTER_OPTIONS, TYPE_FILTER_OPTIONS } from './constants';
import columnFilterProps from './helpers';

function SellersIndex() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const searchInput = useRef(null);
  const sellers = useSelector((state) => state.reducerSellers.all);

  useEffect(async () => {
    try {
      setLoading(true);
      await dispatch(actionSellers.getAll());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  }, []);

  const onChangePage = (pageClicked) => {
    dispatch(actionSellers.getAll({ page: pageClicked }));
  };

  const onSelectSeller = (id) => {
    dispatch(actionSellers.setCurrent(id));
    history.push('/licenca/informacoes');
  };

  const handleSearch = async (selectedKeys, confirm) => {
    setLoading(true);
    confirm();
    await dispatch(actionSellers.getAll({ nome: selectedKeys[0] }));
    setLoading(false);
  };

  const handleReset = async (clearFilters) => {
    setLoading(true);
    clearFilters();
    await dispatch(actionSellers.getAll());
    setLoading(false);
  };

  const columnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <SearchFilter
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        inputRef={searchInput}
        handleSearch={handleSearch}
        handleReset={handleReset}
        dataIndex={dataIndex}

      />
    ),
    filterIcon: (filtered) => (
      <i className="fa fa-search text-1 px-1" style={{ color: filtered ? '#DEBD4E' : undefined }} />
    ),
    onFilter: (value, record) => record[dataIndex]
      .toString()
      .toLowerCase()
      .includes((value).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      render: (text, record) => helpersAll.formatName(record.nome),
      ...columnSearchProps('nome'),
    },
    {
      title: 'Tipo',
      key: 'tipo',
      render: (text, record) => {
        const type = record.vendedor?.tipo || '';
        const item = type ? typesSellers[type] : '--';
        return item;
      },
      ...columnFilterProps(TYPE_FILTER_OPTIONS, 'tipo', setLoading),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, record) => {
        const status = record.vendedor.ativo;
        if (status) {
          return 'Ativo';
        } return 'Inativo';
      },
      ...columnFilterProps(STATUS_FILTER_OPTIONS, 'ativo', setLoading),
    },
    {
      title: 'Telefone',
      key: 'telefones',
      render: (text, record) => {
        let item = '';

        if (record?.telefones?.length) {
          const data = record.telefones
            .find((phone) => helpersAll.isCellPhone(phone.numero))
            || record.telefones[0];
          item = data ? helpersAll.formatPhone(data.numero) : '--';
        }
        return item;
      },
    },
    {
      title: 'Email',
      key: 'emails',
      render: (text, record) => {
        const item = record.emails && record
          .emails.length ? record.emails.find((mail) => mail) : { endereco: '' };
        return item.endereco;
      },
    },
    {
      title: 'Ações',
      key: 'action',
      render: (text, record) => (
        <Tooltip title="Ver detalhes do vendedor" className="text-dark d-flex justify-content-center">
          <button type="button" className="button" onClick={() => onSelectSeller(record.id)}>
            <i className="fa fa-search text-3" />
          </button>
        </Tooltip>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <div className="shadow-one px-5 py-4 mb-5">
        <div className="col-md-12 col-sm-12">
          <div className="box-title text-left mb-5">
            <div className="w-75 float-left">
              <h1 className="title-5 color-black mb-0 ">Estrutura das Licenças</h1>
              <p className="title-2 color-9 mb-0 font-weight-normal">Aqui você lista todas as licenças em sua rede</p>
            </div>
          </div>
          <div className="row mt-2">

            <Table columns={columns} dataSource={sellers.items} pagination={false} className="col-12 table-responsive p-0" />
            <div className="text-center w-100 mt-5">
              <Pagination
                defaultCurrent={sellers.page}
                current={sellers.page}
                pageSize={10}
                total={sellers.totalCount}
                onChange={onChangePage}
              />
            </div>

          </div>
        </div>
      </div>
    </Spin>
  );
}

export default SellersIndex;
