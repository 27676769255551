/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actionModal from '../../actions/actionModal';
import ModalBr5Quotation from './components/ModalBr5Quotation';
import ModalBr5LicencasQuotation from './components/ModalBr5LicencasQuotation';
import ModalExpertiseQuotation from './components/ModalExpertiseQuotation';
import ModalTopSegurosQuotation from './components/ModalTopSegurosQuotation';
import ModalTopSegurosListQuotations from './components/ModalTopSegurosListQuotations';
import ModalExpertiseListQuotations from './components/ModalExpertiseListQuotations';
import ModalBr5ListQuotations from './components/ModalBr5ListQuotations';
import ModalBr5LicencasListQuotations from './components/ModalBr5LicencasListQuotations';
import ModalUserProfile from './components/ModalUserProfile';
import ModalShareSocial from './components/ModalShareSocial';

function QuoteIndex() {
  const dispatch = useDispatch();

  const {
    modalBr5LicencasChoosePlan,
    modalBr5ChoosePlan,
    modalExpertiseQuotation,
    modalTopSegurosQuotation,
    modalExpertiseListQuotations,
    modalTopSegurosListQuotations,
    modalBr5ListQuotations,
    modalBr5LicencasListQuotations,
    modalBr5UseProfile,
  } = useSelector((state) => state.reducerModal);

  const handleShowModal = (event, type, visible = true) => {
    event.preventDefault();
    const types = {
      br5: () => dispatch(actionModal.modalBr5ChoosePlan(visible)),
      listBr5: () => dispatch(actionModal.modalBr5ListQuotations(visible)),
      br5Licencas: () => dispatch(actionModal.modalBr5LicencasChoosePlan(visible)),
      listLicencas: () => dispatch(actionModal.modalBr5LicencasListQuotations(visible)),
      exp: () => dispatch(actionModal.modalExpertiseQuotation(visible)),
      top: () => dispatch(actionModal.modalTopSegurosQuotation(visible)),
      listTop: () => dispatch(actionModal.modalTopSegurosListQuotations(visible)),
      listExp: () => dispatch(actionModal.modalExpertiseListQuotations(visible)),
    };

    return types[type]();
  };

  return (
    <div className="shadow-one p-md-5 p-2 mb-5">
      <div className="col-md-12 col-sm-12">
        <div className="box-title text-left mb-4">
          <div className="w-75 float-left">
            <span className="title-5 color-2">Cotações</span>
            <span className="text-3 color-4 d-block">Aqui você pode fazer as cotações em cada empresa</span>

          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 col-12  yr text-center mb-5">
            <div className="logo-default mb-2">
              <img className="" alt="" src="images/logos/br5.png" />
            </div>
            <a href="#!" onClick={(event) => handleShowModal(event, 'br5')} className="btn btn btn-outline-dark mr-2 btn-quote">CALCULAR PLANOS</a>
            <a href="#!" onClick={(event) => handleShowModal(event, 'listBr5')} className="btn btn btn-outline-dark btn-quote">CÁLCULOS REALIZADOS</a>
          </div>
          <div className="col-md-6 col-12 text-center mb-5">
            <div className="logo-default mb-2">
              <img className="" alt="" src="images/logos/br5-licencas.png" />
            </div>
            <a href="#!" onClick={(event) => handleShowModal(event, 'br5Licencas')} className="btn btn btn-outline-dark mr-2 btn-quote">CALCULAR LICENÇAS</a>
            <a href="#!" onClick={(event) => handleShowModal(event, 'listLicencas')} className="btn btn btn-outline-dark btn-quote">CÁLCULOS REALIZADOS</a>
          </div>

          <div className="w-100 xb mb-5" />
          <div className="col-md-6 col-12  yr text-center">
            <div className="logo-default expertise-logo mb-2">
              <img className="" alt="" src="images/logos/expertise.png" />
            </div>
            <a href="#!" onClick={(event) => handleShowModal(event, 'exp')} className="btn btn btn-outline-dark mr-2 btn-quote">CALCULAR PRODUTOS</a>
            <a href="#!" onClick={(event) => handleShowModal(event, 'listExp')} className="btn btn btn-outline-dark btn-quote">CÁLCULOS REALIZADOS</a>
          </div>
          <div className="col-md-6 col-12  text-center">
            <div className="logo-default mb-2">
              <img className="" alt="" src="images/logos/top-seguros.png" />
            </div>
            <a href="#!" onClick={(event) => handleShowModal(event, 'top')} className="btn btn btn-outline-dark mr-2 btn-quote">CALCULAR SEGUROS</a>
            <a href="#!" onClick={(event) => handleShowModal(event, 'listTop')} className="btn btn btn-outline-dark btn-quote">CÁLCULOS REALIZADOS</a>
          </div>
          { modalBr5ChoosePlan ? <ModalBr5Quotation /> : <></> }
          { modalBr5LicencasChoosePlan ? <ModalBr5LicencasQuotation /> : <></> }
          { modalExpertiseQuotation ? <ModalExpertiseQuotation /> : <></> }
          { modalTopSegurosQuotation ? <ModalTopSegurosQuotation /> : <></> }
          { modalTopSegurosListQuotations ? <ModalTopSegurosListQuotations /> : <></> }
          { modalExpertiseListQuotations ? <ModalExpertiseListQuotations /> : <></> }
          { modalBr5ListQuotations ? <ModalBr5ListQuotations /> : <></> }
          { modalBr5LicencasListQuotations ? <ModalBr5LicencasListQuotations /> : <></> }
          { modalBr5UseProfile ? <ModalUserProfile /> : <></> }
          <ModalShareSocial />
        </div>
      </div>
    </div>
  );
}

export default QuoteIndex;
