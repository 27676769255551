import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'antd/dist/antd.css';
import './css/style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@vime/core/themes/default.css';
import '@vime/core/themes/light.css';

document.title = 'BR5 Licenças | Sistema Administrativo';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
