const helpers = {
  mapAll: (payload, state, INITIAL_STATE, keyName = 'all') => {
    let list = payload
    && payload.data
      ? payload.data.items
      : payload;
    if (list.error) {
      return { ...state, [keyName]: list };
    }
    if (!list) return { ...state, INITIAL_STATE };
    list = list.map((item) => ({ ...item, key: item.id }));
    return { ...state, [keyName]: { ...payload.data, items: list } };
  },
  mapItem: (payload, state, INITIAL_STATE, keyName = 'item') => {
    const result = payload
      && payload.data
      ? { ...state, [keyName]: payload.data }
      : { ...state, [keyName]: payload };

    return result;
  },
};

export default helpers;
