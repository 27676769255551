import React, { useState, useEffect } from 'react';
import {
  Modal, Spin, notification, message,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import actionModal from '../../../actions/actionModal';
import action from '../../../actions/actionFirstAccess';
import actionErrors from '../../../actions/actionErrors';

import ModalValidation from '../../validator/ModalValidation';
import Step1Document from './Step1Document';
import Step2ListContacts from './Step2ListContacts';
import Step3Code from './Step3Code';
import Step4Store from './Step4Store';

import helpers from '../../../helpers/all';

import FingerPrint from './icon/FingerPrint';

function ModalWorkerCreate() {
  const dispatch = useDispatch();

  const modalVisible = useSelector((state) => state.reducerModal.modalFirstAccess);
  const step = useSelector((state) => state.reducerFirstAccess.step);
  const form = useSelector((state) => state.reducerFirstAccess.form);
  const item = useSelector((state) => state.reducerFirstAccess.item);

  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [popupBlockedModalVisible, setPopupBlockedModalVisible] = useState(false);

  useEffect(() => {
    form.id = item.id;
  }, [item]);

  // const checkPopupBlocked = async () => new Promise((resolve) => {
  //   const newWindow = window.open('', '_blank');

  //   if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
  //     resolve(true); // Pop-up bloqueado
  //   } else {
  //     newWindow.close();
  //     resolve(false); // Pop-up permitido
  //   }
  // });

  const validateStep1 = async () => {
    if (step !== 1) return;

    if (!helpers.isCNPJ(form.doc) && !helpers.isCPF(form.doc)) {
      throw new Error('Por favor, informe um CNPJ ou CPF válido.');
    }

    await dispatch(action.step1Document({ doc: form.doc }));
  };

  const validateStep2 = async () => {
    if (step !== 2) return;

    if (!form.contactId) {
      throw new Error('Por favor, selecione um contato');
    }

    await dispatch(action.step2Code({ id: form.id, contactId: form.contactId }));
  };

  const validateStep3 = async () => {
    if (step !== 3) return;

    if (!form.code) {
      throw new Error('Por favor, informe o código recebido');
    }

    await dispatch(action.step3Confirm({ id: form.id, code: form.code }));
  };

  const validateStep4 = () => {
    if (step !== 4) return;

    if (!form.password) {
      throw new Error('Por favor, informe a senha');
    }

    if (!form.cellPhoneId && !form.emailId) {
      throw new Error('Por favor, selecione o seu contato para login');
    }

    if (!helpers.validatePassword(form.password)) {
      throw new Error('A senha deve conter pelo menos 6 dígitos, sendo 1 letra, 1 número e 1 caractere especial');
    }

    if (form.password !== form.passwordConfirm) {
      throw new Error('As senhas não são iguais');
    }
  };

  const saveUser = async () => {
    try {
      const { payload } = await dispatch(action.getContractByID(item.documentKey));
      const contractStatus = payload.data.data.status;

      if (contractStatus === 'running') {
        setIsButtonDisabled(true);
        throw new Error('Por favor, assine o contrato antes de prosseguir');
      }

      setLoading(true);

      const data = {
        id: form.id,
        contactsIds: [],
        password: form.password,
      };

      if (form.emailId) data.contactsIds.push(form.emailId);
      if (form.cellPhoneId) data.contactsIds.push(form.cellPhoneId);

      if (!data.contactsIds.length || !data.password || !form.id) {
        throw new Error('Por favor, preencha todos os campos corretamente');
      }

      await dispatch(action.step4Store(data));

      setLoading(false);
      dispatch(actionModal.modalFirstAccess(false));
      // reset
      dispatch(action.setStep(1));
      dispatch(action.form({}));
      notification.success({
        message: 'Seu primeiro acesso foi concluído com sucesso!',
        description: 'Agora você pode realizar o login com os celular ou email e senha informados',
        duration: 10,
      });
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  };

  const handleNextOrSubmit = async (event) => {
    event.preventDefault();
    try {
      await validateStep1();
      await validateStep2();
      await validateStep3();
      validateStep4();

      if (step < 4) {
        dispatch(action.setStep(step + 1));
        return;
      }

      await saveUser();
    } catch (error) {
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  };

  const handleBackOrClose = (event) => {
    event.preventDefault();
    if (step === 1) {
      dispatch(actionModal.modalFirstAccess(false));
      return;
    }

    dispatch(action.setStep(step - 1));
  };

  const BackButton = () => (
    <button
      type="button"
      className="button-fist-access-back title-link color-black"
      onClick={handleBackOrClose}
    >
      {step === 1 ? 'Fechar' : 'Voltar'}
    </button>
  );

  const formatContractType = () => {
    if (item.tipoVendedor === 'bigDealer') {
      return 'EMPRESARIO';
    } if (item.tipoVendedor === 'pdv') {
      return 'LOJA';
    }
    if (item.tipoVendedor === 'vendedorPdv') {
      return 'VENDEDOR';
    }
    return '';
  };

  const handleSignContract = async () => {
    setLoading(true);
    try {
      await dispatch(action.setContractUrl({
        contract_type: formatContractType(),
        name: item.vendedorNome,
        email: item.vendedorEmail,
      }));
    } catch (err) {
      message.error(err.message || 'Erro ao gerar contrato! Verifique os pop-ups');
    } finally {
      setLoading(false);
    }
  };
  const handlePopupBlockedModalOk = () => {
    setPopupBlockedModalVisible(false);
  };

  useEffect(() => {
    if (item.url) {
      window.open(item.url);
      setIsButtonDisabled(false);
    }
  }, [item.url]);

  const NextButton = () =>
  // TODO validate form here

  // if (!form.initialTime && step === 3) return <></>;

    // eslint-disable-next-line implicit-arrow-linebreak
    (
      <>
        {step < 4 ? (
          <button
            type="button"
            className="button-first-access color-black title-link mb-2"
            onClick={handleNextOrSubmit}
          >
            {step < 4 && 'Avançar'}
          </button>
        ) : (
          <>
            <button
              type="button"
              className={` color-white border-0 font-weight-bold button-width 
            ${!isButtonDisabled ? 'bg-color-black' : 'button-disabled'}`}
              disabled={isButtonDisabled}
              onClick={handleNextOrSubmit}
            >
              Salvar

            </button>
            <button type="button" className="button-sign title-link mb-2" onClick={handleSignContract}>
              <FingerPrint />
              Assinar contrato
            </button>
            <Modal
              visible={popupBlockedModalVisible}
              onCancel={handlePopupBlockedModalOk}
              onOk={handlePopupBlockedModalOk}
            >
              <p style={{ color: 'red', textAlign: 'center' }}>
                O pop-up foi bloqueado. Permita pop-ups para este site nas configurações
                do seu navegador ou aparelho.
              </p>
              <p style={{ color: 'black', textAlign: 'center' }}>
                {'Caso esteja utilizando um IPhone, abra o aplicativo Ajustes -> procure por Safari -> e desative Bloquear "Pop-ups"'}
              </p>
            </Modal>

          </>
        )}

      </>
    );

  const Footer = () => (
    <div className="d-flex flex-column align-items-center">
      <NextButton />
      <BackButton />
    </div>
  );

  return (
    <Modal
      width={window.innerWidth > 960 ? 670 : 'auto'}
      visible={modalVisible}
      footer={<Footer />}
      onCancel={() => {
        dispatch(actionModal.modalFirstAccess(false));
        dispatch(action.setStep(1));
        dispatch(action.form({}));
      }}
    >
      <ModalValidation />
      <Spin spinning={loading}>
        <div className="px-2 py-4">
          <div className="col-md-12 col-sm-12">
            <div className="box-title text-left mb-4">
              <span className="title-2 color-black">Primeiro Acesso</span>
              <span className="text-2 color-black d-block">
                { step === 1 && 'Por favor, digite o cnpj da empresa para continuar'}
                { step === 2 && 'Por favor, selecione o celular ou email para receber o código'}
                { step === 3 && ''}
                { step === 4 && 'Por favor, selecione o celular e email, e crie uma senha para continuar '}
              </span>
              {
                  step === 4
                  && (
                  <span className="mb-1 py-3 text-dark d-block mt-3 bg-color-success text-center">
                    <b>IMPORTANTE: Você irá usar esse celular ou e-mail para realizar o login</b>
                  </span>
                  )
                }
            </div>
            {/* code */}
            {step === 1 && <Step1Document /> }
            {step === 2 && <Step2ListContacts /> }
            {step === 3 && <Step3Code /> }
            {step === 4 && <Step4Store /> }
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default ModalWorkerCreate;
