import React from 'react';
import { useSelector } from 'react-redux';
import { typesSellers } from '../../config';

function CardUserLogged() {
  const userData = useSelector((state) => state.reducerUserLogged);
  return (
    <div className="sidebar-header text-center row">
      <div className="user-q col-3">
        <div className="user-initial">{ userData.nome.charAt() }</div>
        {/* <img
          className="img-responsive img-rounded"
          src="/images/icons/default-user.png"
          alt="User"
        /> */}
      </div>
      <div className="user-info col-8 pt-1">
        <span className="user-name">
          { userData.nome }
        </span>
        <span className="user-role">{ typesSellers[userData.tipoVendedor] }</span>
        {/* <span className="user-status">
          <i className="fa fa-circle" />
          <span>Adimplente</span>
        </span> */}
      </div>
    </div>
  );
}

export default CardUserLogged;
