/* eslint-disable react/prop-types */
import React from 'react';

function PersonalGroup(props) {
  const { form, onChangeForm } = props;

  return (
    <>
      <p className="bg-color-8 p-1 text-bold text-4 mb-0">Vendedor</p>
      <div className="group mb-4">
        <div className="input-container pt-4">
          <div className="mr-3">
            <label htmlFor="name" className="label">
              Nome
            </label>
            <input
              type="text"
              className="form-control input"
              placeholder="Digite o seu nome"
              id="name"
              onChange={(e) => onChangeForm('nome', e.target.value)}
              value={form?.nome}
            />
          </div>
          <div>
            <label htmlFor="birthDate" className="label">
              Data de nascimento
            </label>
            <input
              type="text"
              className="form-control input"
              placeholder="Digite a data"
              id="birthDate"
              onChange={(e) => onChangeForm('nascimento', e.target.value)}
              value={form?.nascimento}
            />
          </div>
        </div>
        <div className="input-container mt-4">
          <div className="mr-3">
            <label htmlFor="cnpj" className="label">
              CNPJ
            </label>
            <input
              type="text"
              className="form-control input input-disabled "
              id="cnpj"
              value={form?.cnpj}
              disabled
            />
          </div>
          <div>
            <label htmlFor="businessName" className="label">
              Razão social
            </label>
            <input
              type="text"
              className="form-control input input-disabled"
              id="businessName"
              value={form?.businessName}
              disabled
            />
          </div>
        </div>
      </div>

    </>
  );
}

export default PersonalGroup;
