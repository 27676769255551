/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Spin, DatePicker, ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/pt_BR';

import api from '../../services/api';
import actionModal from '../../actions/actionModal';
import actionErrors from '../../actions/actionErrors';
import actionFinancial from '../../actions/actionFinancial';

import { formatReal, formatDates } from '../../helpers/all';

moment.locale('pt-br');

function DashboardFinancial() {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dateString, setDateString] = useState('');
  const [defaultPeriod, setDefaultPeriod] = useState(moment().utcOffset(0));
  const [filter, setFilter] = useState({ initialDate: moment().startOf('month').format('YYYY-MM-DD'), finallDate: moment().endOf('month').format('YYYY-MM-DD') });

  const dash = useSelector((state) => state.reducerFinancial.dash);
  const getDate = (paramDate) => {
    const date0 = moment(paramDate).utcOffset(0);
    const startDate = date0.startOf('month').add(1, 'day').format('YYYY-MM-DD');
    const endDate = date0.endOf('month').add(1, 'day').format('YYYY-MM-DD');
    setFilter({
      initialDate: startDate,
      finallDate: endDate,
    });
    return {
      date: [startDate, endDate],
    };
  };

  const populate = async (date) => {
    try {
      const startDateFormated = new Date(date[0]);
      const endDateFormated = new Date(date[1]);
      const endDate = `${endDateFormated.getFullYear()}-${(endDateFormated.getMonth() + 1).toString().padStart(2, '0')}-${endDateFormated.getDate().toString().padStart(2, '0')}`;
      const startDate = `${startDateFormated.getFullYear()}-${(startDateFormated.getMonth() + 1).toString().padStart(2, '0')}-${startDateFormated.getDate().toString().padStart(2, '0')}`;
      setLoading(true);
      setDateString(formatDates(date));
      setDefaultPeriod(date);
      await dispatch(actionFinancial.getDetailsDashboard({
        initialDate: startDate, finallDate: endDate,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  };

  useEffect(() => {
    const { date } = getDate();
    // todo trocar ordem de links
    populate(date);

    return () => {
      setLoading(false);
    };
  }, []);

  const downloadFile = async (type = 'br5') => {
    try {
      setLoading(true);
      // const { month, year } = getDate(defaultPeriod);
      const { data } = await api.get(`/api/v1/vendedor/dashboard/download-planilha/${type}`, { params: filter, responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
    // document.location.href = '/';
  };

  const onChange = (dateMoment) => {
    getDate(dateMoment);
    populate(dateMoment);
    setFilter({
      initialDate: moment(dateMoment[0]).format('YYYY-MM-DD'),
      finallDate: moment(dateMoment[1]).format('YYYY-MM-DD'),
    });
  };

  return (
    <Spin spinning={loading}>
      <div className="shadow-one p-md-5 p-2 mb-5">
        <div className="col-md-12 col-sm-12">
          <div className="box-title text-left mb-4">
            <div className="w-75 float-left">
              {/* <i className="mr-2 fa fa-file-signature color-1" /> */}
              <span className="title-5 color-2">Painel Financeiro</span>

            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-4 mt-5">
              {/* <button className="btn btn-dark" type="button">
                <i className="fa fa-arrow-left" />
              </button> */}
              {/* <div className="btn btn-outline-dark col-md-4 col-xs-12 offset-md-4"> */}
              <ConfigProvider locale={locale}>
                <RangePicker defaultValue={defaultPeriod} format="DD-MM-YYYY" picker="date" onChange={onChange} className="offset-md-4 col-md-4 col-xs-12 mr-4 mb-2" />
              </ConfigProvider>
              <span className="text-2 yr-2">
                Período selecionado:
                {' '}
                <b>{ dateString }</b>
              </span>
              {/* </div> */}
              {/* <button className="btn btn-dark" type="button">
                <i className="fa fa-arrow-right" />
              </button> */}
            </div>
          </div>
          <div className="row mt-2">
            {/* Inicio Card 01 */}
            <div className="col-md-6 mb-5 col-xs-12">
              <div className="w-100 card-custom shadow-one">
                <div className="mr-4 ml-4">

                  <div style={{ height: '90px' }} className="col-12 text-center logo-default mb-3">
                    <img src="images/logos/br5.png" alt="" />
                  </div>
                  <div className="bg-dark p-2 yr rounded-top">
                    <span className="text-white text-2 text-bold">Ganhos Própios</span>
                  </div>

                  <div className="p-3 shadow-sm">
                    <div className="d-block pb-5">
                      <div className="float-left w-50">Adesão:</div>
                      <div className="float-right w-80 text-bold">
                        { formatReal(dash.producao?.adesao?.direto?.totalComission) }
                      </div>
                    </div>
                    <div className="d-block pb-3">
                      <div className="float-left w-50">Mensalidades:</div>
                      <div className="float-right w-80 text-bold">
                        { formatReal(dash.producao?.mensalidades?.direto?.totalComission) }
                      </div>
                    </div>
                  </div>

                  <div className="bg-dark p-2 yr">
                    <span className="text-white text-2 text-bold">Ganhos Ecossistema</span>
                  </div>

                  <div className="p-3 shadow-sm rounded-bottom">
                    <div className="d-block pb-5">
                      <div className="float-left w-50">Adesão:</div>
                      <div className="float-right w-80 text-bold">
                        { formatReal(dash.producao?.adesao?.indireto?.totalComission) }
                      </div>
                    </div>
                    <div className="d-block pb-3">
                      <div className="float-left w-50">Mensalidades:</div>
                      <div className="float-right w-80 text-bold">
                        { formatReal(dash.producao?.mensalidades?.indireto?.totalComission) }
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-link text-info btn-sm mt-3 mb-5 w-100"
                    onClick={() => downloadFile('br5')}
                  >
                    <i style={{ fontSize: '25px' }} className="fas fa-file-download mr-2" />
                    Download Planilha
                  </button>
                </div>
              </div>
            </div>
            {/* Fim Card 01 */}
            {/* Card 02 */}
            <div className="col-md-6 mb-5">
              <div className="w-100 card-custom shadow-one">
                <div className="mr-4 ml-4">

                  <div style={{ height: '90px' }} className="col-12 text-center logo-default mb-3">
                    <img src="images/logos/br5-licencas.png" alt="" />
                  </div>
                  <div className="bg-dark p-2 yr rounded-top">
                    <span className="text-white text-2 text-bold">Ganhos Própios</span>
                  </div>

                  <div className="p-3 shadow-sm">
                    <div className="d-block pb-5">
                      <div className="float-left w-50">Adesão:</div>
                      <div className="float-right w-80 text-bold">
                        { formatReal(dash.br5Licencas?.adesao?.direto?.totalComission) }
                      </div>
                    </div>
                    <div className="d-block pb-3">
                      <div className="float-left w-50">Mensalidades:</div>
                      <div className="float-right w-80 text-bold">
                        --
                        {/* { formatReal(dash.br5Licencas?.mensalidades?.direto?.totalComission) } */}
                      </div>
                    </div>
                  </div>

                  <div className="bg-dark p-2 yr">
                    <span className="text-white text-2 text-bold">Ganhos Ecossistema</span>
                  </div>

                  <div className="p-3 shadow-sm rounded-bottom">
                    <div className="d-block pb-5">
                      <div className="float-left w-50">Adesão:</div>
                      <div className="float-right w-80 text-bold">
                        { formatReal(dash.br5Licencas?.adesao?.indireto?.totalComission) }
                      </div>
                    </div>
                    <div className="d-block pb-3">
                      <div className="float-left w-50">Mensalidades:</div>
                      <div className="float-right w-80 text-bold">
                        { formatReal(dash.br5Licencas?.mensalidades?.indireto?.totalComission) }
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-link btn-sm mt-3 mb-5 bg-white text-warning w-100"
                    onClick={() => downloadFile('br5Licencas')}
                  >
                    <i style={{ fontSize: '25px' }} className="fas fa-file-download mr-2" />
                    Download Planilha
                  </button>
                </div>
              </div>
            </div>
            {/* Fim Card 02 */}
            {/* Card 03 */}
            <div className="col-md-6 mb-5">
              <div className="w-100 card-custom shadow-one">
                <div className="mr-4 ml-4">

                  <div style={{ height: '90px' }} className="col-12 text-center logo-default mb-3">
                    <img src="images/logos/expertise.png" alt="" />
                  </div>
                  <div className="bg-dark p-2 yr rounded-top">
                    <span className="text-white text-2 text-bold">Ganhos Própios</span>
                  </div>

                  <div className="p-3 shadow-sm">
                    <div className="d-block pb-5">
                      <div className="float-left w-50">Adesão:</div>
                      <div className="float-right w-80 text-bold">
                        --
                      </div>
                    </div>
                    <div className="d-block pb-3">
                      <div className="float-left w-50">Pagamentos:</div>
                      <div className="float-right w-80 text-bold">
                        { formatReal(dash.br5Financeira?.pagamentos?.direto?.totalComission) }
                      </div>
                    </div>
                  </div>

                  <div className="bg-dark p-2 yr">
                    <span className="text-white text-2 text-bold">Ganhos Ecossistema</span>
                  </div>

                  <div className="p-3 shadow-sm rounded-bottom">
                    <div className="d-block pb-5">
                      <div className="float-left w-50">Adesão:</div>
                      <div className="float-right w-80 text-bold">
                        --
                      </div>
                    </div>
                    <div className="d-block pb-3">
                      <div className="float-left w-50">Comissão:</div>
                      <div className="float-right w-80 text-bold">
                        { formatReal(dash.br5Financeira?.pagamentos?.indireto?.totalComission) }
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-link btn-sm mt-3 mb-5 w-100"
                    onClick={() => downloadFile('br5Financeira')}
                  >
                    <i style={{ fontSize: '25px' }} className="fas fa-file-download mr-2" />
                    Download Planilha
                  </button>
                </div>
              </div>
            </div>
            {/* Fim Card 03 */}
            {/* Card 04 */}
            <div className="col-md-6 mb-5">
              <div className="w-100 card-custom shadow-one">
                <div className="mr-4 ml-4">

                  <div style={{ height: '90px' }} className="col-12 text-center logo-default mb-3">
                    <img src="images/logos/top-seguros.png" alt="" />
                  </div>
                  <div className="bg-dark p-2 yr rounded-top">
                    <span className="text-white text-2 text-bold">Ganhos Própios</span>
                  </div>

                  <div className="p-3 shadow-sm">
                    <div className="d-block pb-5">
                      <div className="float-left w-50">Adesão:</div>
                      <div className="float-right w-80 text-bold">
                        --
                      </div>
                    </div>
                    <div className="d-block pb-3">
                      <div className="float-left w-50">Pagamentos:</div>
                      <div className="float-right w-80 text-bold">
                        { formatReal(dash.topSeguros?.pagamentos?.direto?.totalComission) }
                      </div>
                    </div>
                  </div>

                  <div className="bg-dark p-2 yr">
                    <span className="text-white text-2 text-bold">Ganhos Ecossistema</span>
                  </div>

                  <div className="p-3 shadow-sm rounded-bottom">
                    <div className="d-block pb-5">
                      <div className="float-left w-50">Adesão:</div>
                      <div className="float-right w-80 text-bold">
                        --
                      </div>
                    </div>
                    <div className="d-block pb-3">
                      <div className="float-left w-50">Comissão:</div>
                      <div className="float-right w-80 text-bold">
                        { formatReal(dash.topSeguros?.pagamentos?.indireto?.totalComission) }
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-link btn-sm text-danger mt-3 mb-5 w-100"
                    onClick={() => downloadFile('topSeguros')}
                  >
                    <i style={{ fontSize: '25px' }} className="fas fa-file-download mr-2" />
                    Download Planilha
                  </button>
                </div>
              </div>
            </div>
            {/* Fim Card 04 */}
          </div>
        </div>
      </div>
    </Spin>
  );
}

// DashboardFinancial.propTypes = {
//   match: PropTypes.objectOf({}).isRequired,
// };

export default DashboardFinancial;
