import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, message } from 'antd';

import actionSellers from '../../actions/actionSellers';

import UpdatePasswordForm from './components/UpdatePasswordForm';
import UpdateContactForm from './components/UpdateContactForm';
import UpdateAddressForm from './components/UpdateAddressForm';
import Contract from './components/Contract';

function ProfileSettingsIndex() {
  const [isUpdateData, setIsUpdateData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.reducerUserLogged.pessoa);

  const getUserData = async () => {
    try {
      await dispatch(actionSellers.getById(userId));
    } catch {
      message.error('Houve um problema ao obter os dados do usuário');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, [userId]);

  const showUpdateData = () => {
    setIsUpdateData(true);
  };

  const showContract = () => {
    setIsUpdateData(false);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="bg-white mb-5 page-container">
        <h1 className="title-settings py-4">Configuração/ Perfil</h1>
        <div className="d-flex w-100 mw-50 mt-5">
          <button type="button" onClick={showUpdateData} className={` p-2 w-20 mr-2 ${isUpdateData ? 'button-active color-white' : 'button text-dark'}`}>
            <i className={`fa fa-key mr-2  ${isUpdateData ? 'color-white' : 'color-dark'}`} />
            Alterar dados
          </button>
          <button type="button" onClick={showContract} className={` p-2 w-20 ${isUpdateData ? 'button text-dark' : 'button-active color-white'}`}>
            <i className={`fa fa-solid fa-file-contract mr-2  ${isUpdateData ? 'text-dark' : 'color-white'}`} />
            Visualizar contrato
          </button>
        </div>
        {isUpdateData ? (
          <>
            <UpdatePasswordForm />
            <UpdateContactForm />
            <UpdateAddressForm />
          </>
        ) : (
          <Contract />
        )}
      </div>
    </Spin>
  );
}

export default ProfileSettingsIndex;
