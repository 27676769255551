import React from 'react';

import CheckboxFilter from './components/CheckboxFilter';
import FilterIcon from './components/FilterIcon';

const columnFilterProps = (filterOptions, filterName, setLoading) => ({
  filters: filterOptions,
  filterIcon: (filtered) => (
    <FilterIcon color={filtered ? '#DEBD4E' : undefined} />
  ),
  filterDropdown: ({
    setSelectedKeys, selectedKeys, confirm, clearFilters,
  }) => (
    <CheckboxFilter
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      filterOptions={filterOptions}
      clearFilters={clearFilters}
      filterName={filterName}
      setLoading={setLoading}
    />
  ),

});

export default columnFilterProps;
