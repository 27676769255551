import actionsTypes from './actionsTypes';

const actions = {
  modalValidation: (visible) => ({
    type: actionsTypes.MODAL_VALIDATION,
    payload: visible,
  }),
  modalWorkerEdit: (visible) => ({
    type: actionsTypes.MODAL_WORKER_EDIT,
    payload: visible,
  }),
  modalWorkerCreate: (visible) => ({
    type: actionsTypes.MODAL_WORKER_CREATE,
    payload: visible,
  }),
  modalPaymentInvoice: (visible) => ({
    type: actionsTypes.MODAL_PAYMENT_INVOICE,
    payload: visible,
  }),
  modalConsultationCreate: (visible) => ({
    type: actionsTypes.MODAL_CONSULTATION_CREATE,
    payload: visible,
  }),
  modalConsultationDetails: (visible) => ({
    type: actionsTypes.MODAL_CONSULTATION_DETAILS,
    payload: visible,
  }),
  modalProfileCreate: (visible) => ({
    type: actionsTypes.MODAL_PROFILE_CREATE,
    payload: visible,
  }),
  modalProfileEdit: (visible) => ({
    type: actionsTypes.MODAL_PROFILE_EDIT,
    payload: visible,
  }),
  modalFirstAccess: (visible) => ({
    type: actionsTypes.MODAL_FIRST_ACCESS,
    payload: visible,
  }),
  modalConcludeAttendance: (visible) => ({
    type: actionsTypes.MODAL_CONSULTATION_CONCLUDE_ATTENDANCE,
    payload: visible,
  }),
  modalSchedulingManage: (visible) => ({
    type: actionsTypes.MODAL_SCHEDULING_MANAGE,
    payload: visible,
  }),

  //

  modalBr5ChoosePlan: (visible) => ({
    type: actionsTypes.MODAL_BR5_CHOOSE_PLAN,
    payload: visible,
  }),
  modalBr5LicencasChoosePlan: (visible) => ({
    type: actionsTypes.MODAL_BR5_LICENCAS_CHOOSE_PLAN,
    payload: visible,
  }),
  modalExpertiseQuotation: (visible) => ({
    type: actionsTypes.MODAL_EXPERTISE_QUOTATION,
    payload: visible,
  }),
  modalExpertiseListQuotations: (visible) => ({
    type: actionsTypes.MODAL_EXPERTISE_LIST_QUOTATIONS,
    payload: visible,
  }),
  modalTopSegurosQuotation: (visible) => ({
    type: actionsTypes.MODAL_TOPSEGUROS_QUOTATION,
    payload: visible,
  }),
  modalTopSegurosListQuotations: (visible) => ({
    type: actionsTypes.MODAL_TOPSEGUROS_LIST_QUOTATIONS,
    payload: visible,
  }),
  modalBr5ListQuotations: (visible) => ({
    type: actionsTypes.MODAL_BR5_LIST_QUOTATIONS,
    payload: visible,
  }),
  modalBr5LicencasListQuotations: (visible) => ({
    type: actionsTypes.MODAL_BR5_LICENCAS_LIST_QUOTATIONS,
    payload: visible,
  }),
  modalRequestWithdraw: (visible) => ({
    type: actionsTypes.MODAL_REQUEST_WITHDRAW,
    payload: visible,
  }),
  modalSendEmailRequestWithdraw: (visible) => ({
    type: actionsTypes.MODAL_SEND_EMAIL_REQUEST_WITHDRAW,
    payload: visible,
  }),
  modalUserProfile: (visible) => ({
    type: actionsTypes.MODAL_USER_PROFILE,
    payload: visible,
  }),
  modalShareSocial: (visible) => ({
    type: actionsTypes.MODAL_SHARE_SOCIAL,
    payload: visible,
  }),
};

export default actions;
