import actionsTypes from '../actions/actionsTypes';
import helpers from './helpers';

const INITIAL_STATE = {
  all: {},
  item: {},
  customerCreated: {},
  selected: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.CREDIT_CARD_GET:
      return helpers.mapAll(action.payload, state, INITIAL_STATE);

    case actionsTypes.CREDIT_CARD_CREATE_CUSTOMER:
      return { ...state, customerCreated: action.payload };

    case actionsTypes.CREDIT_CARD_SET_SELECTED:
      return { ...state, selected: action.payload };

    case actionsTypes.CREDIT_CARD_GET_SELECTED:
      return state.selected;

    default:
      return state;
  }
};

export default reducer;
