/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';

import actionProfileSettings from '../../../actions/actionProfileSettings';

function UpdateContactForm() {
  const [form, setForm] = useState({
    email: '',
    telefone: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.reducerSellers.item);
  const userId = useSelector((state) => state.reducerUserLogged.pessoa);

  useEffect(() => {
    setForm({
      email: user?.email,
      telefone: user?.telefone ? user.telefone[0] : '',
    });
  },
  [user]);

  const onChangeForm = (prop, value) => {
    form[prop] = value;
    setForm({ ...form });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const emailFormat = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$');

    try {
      if (!form.email || !form.telefone) {
        throw new Error('Preencha todos os campos!');
      }

      if (!emailFormat.test(form.email)) {
        throw new Error('Insira um email válido!');
      }

      await dispatch(actionProfileSettings.updateUser(userId, form));
      message.success('Contato salvo com sucesso!');
    } catch (err) {
      message.error(err.message || 'Não foi possivel salvar as alterações!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className="mt-3">
        <h2 className="title">Contato</h2>
        <form className="login-form mt-3 d-flex flex-column" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <label htmlFor="email" className="label">
              Email
            </label>
            <input
              type="text"
              className="form-control input"
              placeholder="Digite o seu email"
              id="email"
              onChange={(e) => onChangeForm('email', e.target.value)}
              value={form.email}
            />
          </div>
          <div className="input-container">
            <div>
              <label htmlFor="phone" className="label">
                Telefone
              </label>
              <InputMask
                mask="(99) 99999-9999"
                type="text"
                className="form-control input"
                placeholder="Digite o seu telefone"
                id="phone"
                onChange={(e) => onChangeForm('telefone', e.target.value)}
                value={form.telefone}
              />
            </div>
          </div>
          <button
            type="submit"
            className="bg-color-black color-white border-0 font-weight-bold p-2 align-self-end pointer"
          >
            Salvar
          </button>
        </form>
      </div>
    </Spin>
  );
}

export default UpdateContactForm;
