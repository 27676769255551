import React, { useState, useEffect } from 'react';
import {
  Table,
  Pagination,
  Modal,
  Spin,
  Tooltip,
  ConfigProvider,
  Button,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import locale from 'antd/lib/locale/pt_BR';
import moment from 'moment';

import actionModal from '../../../actions/actionModal';
import actionUserLogged from '../../../actions/actionUserLogged';
import actionErrors from '../../../actions/actionErrors';
import actions from '../../../actions/actionQuotation';
import helpersAll from '../../../helpers/all';
import { empresas, pagination } from '../../../config';

moment.locale('pt-br');

function ModalTopExpertiseListQuotations() {
  const dispatch = useDispatch();
  const [form, setForm] = useState({});

  const modal = useSelector((state) => state.reducerModal.modalExpertiseListQuotations);
  const all = useSelector((state) => state.reducerQuotation.allExpertise);
  const [loading, setLoading] = useState(false);

  const onChange = (prop, value) => {
    form[prop] = value;
    setForm({ ...form });
  };

  const populate = async (page) => {
    try {
      setLoading(true);
      const data = { ...pagination, ...form, page };
      await dispatch(actions.getAllExpertise(data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(actionErrors.errorCreateWorker(error));
      dispatch(actionModal.modalValidation(true));
    }
  };

  useEffect(() => {
    populate();
  }, []);

  const handleShow = (record) => {
    dispatch(actionModal.modalUserProfile(true));
    dispatch(actionUserLogged.setUserProfile({ user: record }));
    // dispatch(actionSellers.getAll({ page: pageClicked }));
  };

  const status = {
    emAndamento: 'Em Andamento',
    clicado: 'Clicado',
  };
  const columns = [
    {
      title: 'Cliente',
      key: 'nomeCliente',
      dataIndex: 'nomeCliente',
    },
    {
      title: 'Empresa',
      key: 'empresa',
      render: (text, record) => {
        const type = record.empresa || '';
        const item = type ? empresas[type].nomeFantasia : '--';
        return (
          <div>{ item }</div>
        );
      },
    },
    {
      title: 'Produto',
      key: 'produto',
      dataIndex: 'produto',
    },
    {
      title: 'Status',
      key: 'status',
      render: (text, record) => {
        const type = record.status || '';
        const item = type ? status[type] : '--';
        return (
          <div>{ item }</div>
        );
      },
    },
    {
      title: 'Telefone',
      key: 'telefoneCliente',
      dataIndex: 'telefoneCliente',
    },
    {
      title: 'Valor',
      key: 'valor',
      render: (text, record) => (<>{helpersAll.formatReal(record.valor)}</>),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (text, record) => (
        <div>
          <Tooltip title="Ver detalhes do vendedor" className="mr-2 text-dark">
            <Button type="text" onClick={() => handleShow(record)}>
              <i className="fa fa-search text-4" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  console.log(all.items);
  return (
    <Modal
      width="auto"
      visible={modal}
      footer={null}
      onCancel={() => dispatch(actionModal.modalExpertiseListQuotations(false))}
    >
      <Spin spinning={loading}>
        <div className="p-md-4 p-0">
          <div className="col-md-12 col-sm-12">
            <div className="box-title text-left mb-4">
              <i className="mr-2 fa fa-plus color-br5-1" />
              <span className="title-2 color-2">Cotações realizadas na Expertise</span>
              <span className="text-2 color-4 d-block">
                Lista das cotações de produtos.
                {' '}
                <b>
                  Total de registros
                  {' '}
                  {all.totalCount}
                </b>
              </span>
            </div>

            <div className="row pb-3">
              <div className="form-group col-4">
                <span className="font-weight-bold mb-2">Cliente:</span>
                <input
                  type="text"
                  className="form-control"
                  value={form?.nomeCliente || ''}
                  onChange={(event) => onChange('nomeCliente', event.target.value)}
                />
              </div>
              <div className="form-group col-4">
                <span className="font-weight-bold mb-2">Produto:</span>
                <input
                  type="text"
                  className="form-control"
                  value={form?.produto || ''}
                  onChange={(event) => onChange('produto', event.target.value)}
                />
              </div>
              <div className="form-group col-4">
                <span className="font-weight-bold mb-2">Telefone:</span>
                <input
                  type="text"
                  className="form-control"
                  value={form?.telefoneCliente || ''}
                  onChange={(event) => onChange('telefoneCliente', event.target.value)}
                />
              </div>
              <div className="col-12">
                <Button type="primary" onClick={() => populate(1)}>
                  Pesquisar
                  <i className="fa fa-search ml-2" />
                </Button>
              </div>
            </div>

            <ConfigProvider locale={locale}>
              <Table columns={columns} dataSource={all.items} pagination={false} className="col-12 table-responsive" />
            </ConfigProvider>
            <div className="text-center w-100 mt-5">
              <Pagination
                defaultCurrent={all.page}
              // pageSize={filter.pageSize}
                total={all.totalCount}
                onChange={populate}
              />
            </div>
          </div>

        </div>
      </Spin>
    </Modal>
  );
}

export default ModalTopExpertiseListQuotations;
