import actionsTypes from '../actions/actionsTypes';
import helpers from './helpers';

const INITIAL_STATE = {
  totalLifes: {
    limit: 0,
    total: 0,
  },
  latestInvoice: {},
  workerInfo: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.TOTAL_LIFES_PLAN:
      return helpers.mapItem(action.payload, state, INITIAL_STATE, 'totalLifes');

    case actionsTypes.LATEST_INVOICE:
      return helpers.mapItem(action.payload, state, INITIAL_STATE, 'latestInvoice');

    case actionsTypes.WORKER_INFO:
      return helpers.mapItem(action.payload, state, INITIAL_STATE, 'workerInfo');

    default:
      return state;
  }
};

export default reducer;
