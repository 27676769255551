import React from 'react';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';

import actions from '../../../actions/actionFirstAccess';

function Step1Document() {
  const dispatch = useDispatch();

  const form = useSelector((state) => state.reducerFirstAccess.form);

  const onChange = (prop, value) => {
    form[prop] = value;
    dispatch(actions.form(form));
  };

  const renderCnpj = () => (
    <div className="d-block mt-4">
      <label htmlFor="cnpj" className="label">
        CNPJ da empresa
      </label>
      <InputMask
        mask="99.999.999/9999-99"
        type="text"
        className="form-control input"
        placeholder="Digite o CNPJ da sua empresa"
        value={form.doc}
        onChange={(event) => onChange('doc', event.target.value)}
      />
    </div>

  );

  const renderCpf = () => (
    <div className="d-block mt-4">
      <label htmlFor="cnpj" className="label">
        Digite seu CPF
      </label>
      <InputMask
        mask="999.999.999-99"
        type="text"
        className="form-control input"
        placeholder="Digite seu CPF"
        value={form.doc}
        onChange={(event) => onChange('doc', event.target.value)}
      />
    </div>
  );

  return (
    <>
      <form className="row">
        <div className="form-group col-12 mb-0">

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="inlineRadio1"
              value={form.typeDoc}
              name="doc"
              onClick={() => onChange('typeDoc', 'cnpj')}
              checked={form.typeDoc === 'cnpj'}
            />
            <label className="label mb-0" htmlFor="inlineRadio1">CNPJ</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="inlineRadio2"
              name="doc"
              value={form.typeDoc}
              onClick={() => onChange('typeDoc', 'cpf')}
              checked={form.typeDoc === 'cpf'}
            />
            <label className="label mb-0" htmlFor="inlineRadio2">CPF</label>
          </div>

          { form.typeDoc === 'cnpj' ? renderCnpj() : renderCpf() }
        </div>
      </form>
    </>
  );
}

export default Step1Document;
