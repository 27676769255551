export const TYPE_FILTER_OPTIONS = [
  {
    label: 'Empresário',
    value: 'bigDealer',
  },
  {
    label: 'Loja',
    value: 'pdv',
  },
  {
    label: 'Vendedor',
    value: 'vendedorPdv',
  },
];

export const STATUS_FILTER_OPTIONS = [
  {
    label: 'Ativo',
    value: true,
  },
  {
    label: 'Inativo',
    value: false,
  },
];
